
import { useContext, useRef, useState } from 'react';
import styles from './PD_ParticipantComponent.module.css'
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";

import InitialsCircleComponent from '../../commons/InitialsCircleComponent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

const PD_ParticipantComponent = ({ participant }) => {
    const deleteRef = useRef()

    const { socketRef, selectedProject, setAlertMessage } = useContext(GlobalContext);

    const { setMoreClicked, setDeleteClicked } = useContext(ProjectDetailsContext);

    function deleteParticipant() {
        if (selectedProject.participants.length > 1) {
            socketRef.current.emit("DELETE_PARTICIPANT_FROM_PROJECT",
                { projectId: selectedProject._id, participantId: participant._id }, (result) => {
                    if (result.error) {
                        setAlertMessage({ type: "ERROR", message: result.message });
                    } else {
                        setMoreClicked({})
                        setDeleteClicked(false);
                    }
                    
                }
            );
        }

    }

    // function handleClick(){
    //     if(moreClicked.shown && participant._id === moreClicked.id){
    //         setMoreClicked({})
    //     } else {
    //         if(!moreMenuTimer){
    //             setMoreClicked({...moreClicked, 
    //                 shown: true, 
    //                 id: participant._id,
    //                 clickRemove: handleDelete,
    //                 x: moreButtonRef.current.getBoundingClientRect().x,
    //                 y: moreButtonRef.current.getBoundingClientRect().y
    //             })
    //         }
    //     }


    // }

    // const handleDelete = () => {
    //     const rect = moreButtonRef.current.getBoundingClientRect(); // Get element position dynamically
    //     setDeleteClicked({
    //         shown: true,
    //         funct: deleteParticipant,
    //         name: "Participant", // Provide any additional data needed
    //         x: rect.x,
    //         y: rect.y,
    //         onClose: () => setDeleteClicked(), // Provide a default close handler
    //     });
    // };

    const handleDelete = (e) => {
        // //console.log(deleteRef.current[fileIndexRef.current]?.current)
        e.stopPropagation();
        if (!deleteRef.current) return;
        const rect = deleteRef.current.getBoundingClientRect();
        setDeleteClicked({
            shown: true,
            funct: deleteParticipant,
            name: "Participant",
            x: rect.x,
            y: rect.y,
            onClose: () => setDeleteClicked(),
        });
    };

    return (
        <>
            <div key={participant._id} className={styles.element}>
                <div className={styles.info}>
                    <InitialsCircleComponent name={participant.name} />
                    <div className={styles.participantDetails}>
                        {participant.name}
                        <div className={styles.participantDescription} style={{ marginTop: 15 }}>
                            Role:
                            <div className={styles.participantRole}>{participant.role}</div>
                        </div>
                        {participant.department && <div className={styles.participantDescription}>
                            Department:
                            <div className={styles.participantRole} style={{ color: "#FFFFFF" }}>{participant.department.name}</div>
                        </div>}
                    </div>
                </div>
                {/* {selectedProject.participants.length > 1 && <MoreButtonComponent ref={moreButtonRef} clicked={moreClicked} setClicked={handleClick} id={participant._id}/>} */}

                {selectedProject.participants.length > 1 &&
                    <FontAwesomeIcon
                        ref={deleteRef}
                        className={styles.iconButton}
                        style={{ right: 50, top: '20.5%' }}
                        icon={byPrefixAndName.fal["trash"]}
                        onClick={(e) => { handleDelete(e) }}
                    />
                }

                {/* {selectedParticipant?._id === participant._id && 
                <ButtonComponent text={language.project_details.Delete} style={{position: "absolute", top:5, right:5, width:100, height:50, fontSize: 30}} onClick={() => deleteParticipant(selectedParticipant._id)}/>
            } */}
                {/* { isDelete && <DeleteNotificationComponent name={'Participant'} type={'participant'} funct={deleteParticipant} onClose={() => setIsDelete(false)} /> } */}
            </div>
        </>



    );

}

export default PD_ParticipantComponent;