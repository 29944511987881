import { useRef, useContext, useState, useEffect, createRef } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { AddConferenceContext } from '../../../contexts/addConferenceContext';
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import styles from './ConferenceAddNewTopicComponent.module.css'
import uuid from 'react-uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import New_KeyboardComponent from '../../keyboard/New_KeyboardComponent';
import New_InputTextComponent from '../../keyboard/New_InputTextComponent';


import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'

// import topicsAdd from '../../../graphics/Popups/Icon Folders Add.png'
import LightButtonComponent from '../../commons/LightButtonComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';
import RightBodyComponent from '../common/RightBodyComponent';
import FooterButtonsComponent from '../common/FooterButtonsComponent';

import topicsAdd from '../../../graphics/Popups/Icon Conferinta Add.png'
import iconEdit from '../../../graphics/Popups/Icon Edit Topic.png'


import AddModeratorPopup from './AddModeratorPopup';

const ConferenceAddNewTopicComponent = () => {

    const nameRef = useRef()
    const keyboardRef = useRef()
    const subtopicRef = useRef()

    const [topicIsOnFocus, setTopicIsOnFocus] = useState(false)
    const [indexSubtopic, setIndexSubtopic] = useState(-1)
    const [subtopicIsOnFocus, setSubtopicIsOnFocus] = useState(false)
    const [subtopicMapIsOnFocus, setSubtopicMapIsOnFocus] = useState(false)

    const { socketRef, currentUser, appWidth, appHeight } = useContext(GlobalContext);
    const { editConference, setEditConference } = useContext(ProjectDetailsContext)
    const { setAddNewTopic, selectedTopic, addModerator, setAddModerator, selectedModerator, setSelectedModerator, setUniqueModerators, setOpenFiles } = useContext(AddConferenceContext);

    // const [firstClick, setFirstClick] = useState(selectedTopic ? true : false)
    const [currentSubtopicList, setCurrentSubtopicList] = useState(selectedTopic && selectedTopic.subtopics ? selectedTopic.subtopics : [])
    const fileRefs = useRef(currentSubtopicList.map(() => createRef()));

    const [showAddSubtopic, setShowAddSubtopic] = useState(false)
    const [showAddButton, setShowAddButton] = useState(false)

    // useEffect(() => {
    //     if (topicIsOnFocus) {
    //         setFirstClick(true)
    //     }
    // }, [topicIsOnFocus])

    useEffect(() => {
        if (selectedTopic) {
            setSelectedModerator(selectedTopic.moderator)
            setShowAddSubtopic(true);
        }
        return () => {
            setSelectedModerator()
        }
    }, [])

    function handleTopicAdd() {
        //console.log("editConference", currentSubtopicList);
        let subtopicsList = [];
        //subtopicsList = currentSubtopicList.map(subtopic => {name: subtopic.name, id: })
        socketRef.current.emit('CONFERENCE_UPDATE_TOPIC', {
            conferenceId: editConference._id,
            topicId: selectedTopic ? selectedTopic._id : null,
            newData: {
                name: nameRef.current?.value ? nameRef.current?.value : "Default Title",
                moderator: selectedModerator._id,
                subtopics: currentSubtopicList
            }
        }, callback => {
            if (!callback.error) {
                if (selectedTopic) {
                    setEditConference((prev) => ({
                        ...prev,
                        moderators: callback.response.moderators,
                        topics: prev.topics.map((topic) =>
                            topic._id === selectedTopic._id ? callback.response.updatedTopic : topic
                        ),
                    }))
                    setOpenFiles(callback.response.updatedTopic)
                } else {
                    setEditConference((prev) => ({
                        ...prev,
                        moderators: callback.response.moderators,
                        topics: [...prev.topics, callback.response.updatedTopic],
                    }));
                }
                setAddNewTopic(false)
            }
        })
    }

    function handleSubtopicAdd() {
        let subtopicName = subtopicRef.current?.value ? subtopicRef.current?.value : "Default Title"
        setCurrentSubtopicList(currentSubtopicList => [...currentSubtopicList, { name: subtopicName, _id: uuid(), isNew: true }])
        fileRefs.current = [...fileRefs.current, createRef()]
        subtopicRef.current.value = ""
    }

    function handleDeleteSubtopic(subtopic) {
        if (selectedTopic && !subtopic.isNew) {
            socketRef.current.emit("CONFERENCE_DELETE_SUBTOPIC", subtopic._id, selectedTopic._id, callback => {
                if (!callback.error) {
                    setEditConference((prev) => ({
                        ...prev,
                        topics: prev.topics.map((topic) =>
                            topic._id === selectedTopic._id ? callback.response : topic
                        ),
                    }))
                    setOpenFiles(callback.response)
                }
            })
        }

        let subtopicsList = currentSubtopicList
        let filteredSubtopicsList = subtopicsList.filter(st => st._id !== subtopic._id);
        setCurrentSubtopicList(filteredSubtopicsList)
    }

    useEffect(() => {
        if (indexSubtopic !== -1 && !subtopicMapIsOnFocus) {
            let updatedSubtopics = currentSubtopicList.map((subtopic) => {
                if (subtopic._id === currentSubtopicList[indexSubtopic]._id) {
                    return {

                        ...subtopic, name: fileRefs.current[indexSubtopic].current?.value ? fileRefs.current[indexSubtopic].current?.value : "Default Title"
                    };
                }
                return subtopic;
            })
            setCurrentSubtopicList(updatedSubtopics)
        }
    }, [subtopicMapIsOnFocus]);

    function closePopup() {
        setAddNewTopic()
    }


    const topicNameSave_handler = () => {
        if (nameRef.current?.value) {
            setShowAddSubtopic(true);
        } else {
            setShowAddSubtopic(false);
            setShowAddButton(false);
        }
    }

    const subtopicNameSave_handler = () => {
        if (subtopicRef.current?.value) {
            setShowAddButton(true);
        } else {
            setShowAddButton(false);
        }
    }


    return (
        <PopupBaseComponent>
            <New_KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.66} width="2140px" height="610px" s="1" />
            <PopupBodyComponent style={{ width: 2455, height: 981 }}>
                <ClosePageButtonComponent setClose={closePopup} />
                <LeftBodyComponent image={selectedTopic ? iconEdit : topicsAdd} title={selectedTopic ? "Edit Topic" : "New Topic"} description={selectedTopic ? "Modify the topic name, add, edit, or delete subtopics, and update the moderator." : "Enter the topic name and assign a moderator (required). You can also add subtopics (optional)."} />
                <RightBodyComponent>
                    <div className={styles.body_header} >
                        Topic Name *
                    </div>

                    <New_InputTextComponent ref={nameRef} height="80px" keyboardRef={keyboardRef} value={selectedTopic ? selectedTopic.name : ""} placeHolder="Enter topic name here" styleType='POPUP' fontSize="40px" width="1300px"
                        iconPosition="right" icon={byPrefixAndName.fas["pen"]} withDelete={true} onChange={() => topicNameSave_handler()}
                    />

                    <div className={styles.subtopics}>
                        <div className={styles.subtopicsHeader}>
                            Subtopics
                            <div className={styles.subtopicsOptional}>(Optional)</div>
                        </div>

                        <div className={styles.subtopics_map}>

                            {currentSubtopicList.length > 0 && currentSubtopicList.map((subtopic, index) =>
                                <div key={subtopic._id} className={styles.subtopicsAdd}>
                                    <New_InputTextComponent ref={fileRefs.current[index]} height="80px" keyboardRef={keyboardRef}
                                        value={subtopic.name} placeHolder="Enter subtopic name here" styleType={'POPUP'} onChange={() => subtopic.name = fileRefs.current[index].current.value}
                                        fontSize="40px" width={indexSubtopic === index && subtopicMapIsOnFocus ? "921px" : "1260px"} />
                                    <LightButtonComponent onClick={() => handleDeleteSubtopic(subtopic)} style={{ width: 300 }}>
                                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['trash']} />
                                        Delete
                                    </LightButtonComponent>

                                </div>)}
                            <div className={styles.subtopicsAdd}>
                                {showAddSubtopic &&
                                    <>
                                        <New_InputTextComponent ref={subtopicRef} keyboardRef={keyboardRef} onChange={() => subtopicNameSave_handler()} height="80px" value="" placeHolder="Enter subtopic name here" styleType={'POPUP'} fontSize="40px" width={subtopicIsOnFocus ? "921px" : "1260px"}
                                            iconPosition="right" icon={byPrefixAndName.fas["pen"]} withDelete={true}
                                        />
                                        {showAddButton &&
                                            <LightButtonComponent onClick={handleSubtopicAdd} style={{ width: 300 }}>
                                                Add
                                            </LightButtonComponent>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.title}>Moderator *</div>
                    {!selectedModerator ? <LightButtonComponent style={{ marginLeft: 0, width: 430 }} onClick={() => {
                        if (currentUser?.role.toLowerCase() === "administrator")
                            setAddModerator(true)
                    }}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> Add moderator
                    </LightButtonComponent> :
                        <div className={styles.moderatorName} onClick={() => {
                            if (currentUser?.role.toLowerCase() === "administrator")
                                setAddModerator(true)
                        }}>
                            {selectedModerator.name}
                        </div>}
                    <div className={styles.explanation}>⚠️ <span style={{ opacity: 0.5 }}>Fields marked with * are mandatory</span></div>
                    <FooterButtonsComponent
                        darkClick={closePopup}
                        lightText={selectedTopic ? "Update" : "Create"}
                        lightClick={(nameRef.current?.value === "" || !nameRef.current?.value) && !topicIsOnFocus ? () => { } : handleTopicAdd}
                        isOpaque={(nameRef.current?.value === "" || !nameRef.current?.value) && !topicIsOnFocus}
                        style={{ bottom: 55 }}
                    />

                </RightBodyComponent>
            </PopupBodyComponent>
            {addModerator && <AddModeratorPopup />}
        </PopupBaseComponent>
    );

}

export default ConferenceAddNewTopicComponent;