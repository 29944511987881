import { useRef, useContext, useState, useEffect } from 'react';
import styles from './ConferenceAddVisitorComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import New_KeyboardComponent from '../../keyboard/New_KeyboardComponent';
import New_InputTextComponent from '../../keyboard/New_InputTextComponent';
import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/Icon Members Add.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import CountriesJson from '../../../graphics/country.json'
import New_DropDownComponent from '../../keyboard/New_DropDownComponent';


const ConferenceAddVisitorComponent = ({ setDisplayedParticipants, allParticipantsRef, participantsFilter, setParticipantsFilter, }) => {

    const inputRefName = useRef()
    const inputRefEntitle = useRef()
    const keyboardRef = useRef()
    const inputRefCountry = useRef()

    const { selectedProject, currentConference, socketRef, setAlertMessage } = useContext(GlobalContext);
    const { setAddVisitor } = useContext(AddConferenceContext);

    const [isOpaque, setIsOpaque] = useState(true)


    function handleEmailAdd() {
        var projectId = selectedProject?._id;
        if (!projectId) {
            projectId = currentConference.project;
        }

        if (inputRefName.current.value && inputRefEntitle.current.value) {
            socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", {
                projectId: projectId,
                userData: {
                    "name": inputRefName.current.value,
                    "entitle": inputRefEntitle.current.value,
                    "email": transformString(inputRefName.current.value),
                    "role": "VISITOR",
                    "country": inputRefCountry.current.currentChoice.value
                }
            }, (callback) => {
                if (!callback.error) {
                    // setDisplayedParticipants((prev) => [...prev, callback.response[0]])
                    allParticipantsRef.current = [...allParticipantsRef.current, callback.response[0]]
                    if (participantsFilter === "ALL") {
                        setDisplayedParticipants((prevParticipants) => [
                            ...prevParticipants,
                            callback.response[0]
                        ]);
                    }
                    setParticipantsFilter('ALL')
                    setAddVisitor(false)
                } else {
                    setAlertMessage({ type: "ERROR", message: callback.message })
                }

            })
        } else {
            setAlertMessage({ type: "ERROR", message: "Name and entitle are mandatory." })
        }
    }

    const transformString = (str) => {
        return str.toLowerCase().replace(/\s+/g, '.');
    };

    const checkField = () => {
        if (inputRefName.current.value && inputRefEntitle.current.value) {
            setIsOpaque(false);
        } else {
            setIsOpaque(true);
        }
    };

    return (
        <PopupBaseComponent>
            <PopupBodyComponent style={{ width: 2760, height: 860, top: "15%" }}>

                <ClosePageButtonComponent setClose={() => setAddVisitor(false)} />
                <LeftBodyComponent image={membersAdd} title="Add visitor" description="Enter the email addresses of the guest users you wish to add to the project. These guests will receive access to project resources and notifications." />
                <RightBodyComponent>
                    <div className={styles.title}>Name</div>
                    <New_InputTextComponent ref={inputRefName} height="80px" onChange={() => checkField()} keyboardRef={keyboardRef} value="" placeHolder="Enter name here" styleType='POPUP' width="1560px" fontSize="40px"
                        iconPosition="right" icon={byPrefixAndName.fal["user-tie"]} withDelete={true} />

                    <div className={styles.title} style={{ marginTop: 50 }}>Entitling</div>
                    <New_InputTextComponent ref={inputRefEntitle} height="80px" onChange={() => checkField()} keyboardRef={keyboardRef} value="" placeHolder="Enter entitling here" styleType='POPUP' width="1560px" fontSize="40px"
                        iconPosition="right" icon={byPrefixAndName.fal["pen"]} withDelete={true} />

                    <div className={styles.title} style={{ marginTop: 50 }}>Country</div>
                    <New_DropDownComponent options={CountriesJson} ref={inputRefCountry} keyboardRef={keyboardRef} value="" placeHolder="Choose country" iconPosition="right" withDelete={true}
                        styleType='POPUP' width="1560px" height="80px" fontSize="40px" />

                    <FooterButtonsComponent lightText={"Add"} lightClick={handleEmailAdd} isOpaque={isOpaque} darkClick={setAddVisitor} />

                </RightBodyComponent>
            </PopupBodyComponent>
            <New_KeyboardComponent visible ref={keyboardRef} width="2082px" height="651px" s="1" />

        </PopupBaseComponent>
    );
}

export default ConferenceAddVisitorComponent;