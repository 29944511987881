import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './AddMeetingComponent.module.css'

import DarkButtonComponent from '../commons/DarkButtonComponent';
import LightButtonComponent from '../commons/LightButtonComponent';

import video from '../../graphics/big wave fundal loop_6.mp4'

import PopupBaseComponent from './common/PopupBaseComponent';

import InputTextComponent from '../keyboard/InputTextComponent';

import DatePickerComponent from './conferenceAddComponents/DatePickerComponent';
import HourPickerComponent from './conferenceAddComponents/HourPickerComponent';
import TimePickerComponent from './conferenceAddComponents/TimePickerComponent';

import { translate, compose, toString, identity, fromString } from 'transformation-matrix';
import ConferenceSendMessagesComponent from './conferenceAddComponents/ConferenceSendMessagesComponent';
import ParticipantPickerComponent from './conferenceAddComponents/ParticipantPickerComponent';



const AddMeetingComponent = () => {
    const currentDate = new Date();
    const calendarRef = useRef();

    const initialHourEndRef = useRef()
    const initialHourStartRef = useRef()

    const selectedHourRef = useRef()

    const titleRef = useRef()
    const keyboardRef = useRef()

    const dataChanged = useRef(false)

    const hasIntersection = useRef()
    


    const { editConference, setEditConference, setCreateMeeting } = useContext(ProjectDetailsContext);
    const { language, selectedProject, socketRef, appHeight, appWidth } = useContext(GlobalContext);

    const [selectedDate, setSelectedDate] = useState(editConference ? new Date(editConference.scheduleDate) : currentDate)
    const [isConfNameOnFocus, setIsConfNameOnFocus] = useState(false)
    const [sendingInvitation, setSendingInvitation] = useState(false)
    const [participantsToSave, setParticipantsToSave] = useState([])    
    const [dayConferences, setDayConferences] = useState([])
    const [sent, setSent] = useState(false)
    const [unsaved, setUnsaved] = useState(false)
    const [save, setSave] = useState(false)
    const [dataChangedLocal, setDataChangedLocal] = useState(false)

    const [selectedHourStart, setSelectedHourStart] = useState(
        editConference ? new Date(editConference.scheduleDate).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }) : "12:00"
    )
    const [selectedHourEnd, setSelectedHourEnd] = useState(() => {
        if (editConference) {
            const hourStart = new Date(editConference.scheduleDate)
            const hourEnd = new Date(hourStart.getTime() + editConference.duration * 60000);

            return hourEnd.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            })
        }
        return '13:00'
    })
    const [duration, setDuration] = useState(() => {
        if (editConference) {
            const hours = Math.floor(editConference.duration / 60);
            const mins = editConference.duration % 60;

            return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
        }
        return '01:00'
    })

    const buttonStyling = { width: 358, height: 82, borderRadius: 16, font: 'normal normal 600 40px/33px Bio Sans', margin: 22 }

    useEffect(() => {
        initialHourStartRef.current = selectedHourStart.split(':')[0]
        initialHourEndRef.current = selectedHourEnd.split(':')[0]

        const selectedDateStartOfDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        const currentDateStartOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

        const diffInMilliseconds = Math.abs(selectedDateStartOfDay.getTime() - currentDateStartOfDay.getTime())
        calendarRef.current.scrollLeft = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) * 156

        dataChanged.current = false

        moveRectangle()
    }, [])

    useEffect(() => {
        socketRef.current.emit("GET_CONFERENCES_FOR_ONE_DAY_FOR_ALL_ROOMS", selectedDate, callback => {
            setDayConferences(callback)

            let [startHours, startMinutes] = selectedHourStart.split(':');
            let [endHours, endMinutes] = selectedHourEnd.split(':');

            let startDate = new Date();
            startDate.setHours(startHours, startMinutes, 0);

            let endDate = new Date();
            endDate.setHours(endHours, endMinutes, 0);

            hasIntersection.current = callback.some(conference => {
                if(editConference){
                    if(editConference._id === conference._id) return false
                }
                
                const conferenceStart = new Date(conference.scheduleDate);

                const conferenceStartToday = new Date();
                conferenceStartToday.setHours(conferenceStart.getHours(), conferenceStart.getMinutes(), 0, 0);

                const conferenceEnd = new Date(conferenceStartToday.getTime() + conference.duration * 60000);

                return (
                    (startDate < conferenceEnd && endDate > conferenceStartToday)
                );
            });

        })

        

    }, [selectedDate])

    useEffect(() => {
        let [startHours, startMinutes] = selectedHourStart.split(':');
        let [endHours, endMinutes] = selectedHourEnd.split(':');

        let startDate = new Date();
        startDate.setHours(startHours, startMinutes, 0);

        let endDate = new Date();
        endDate.setHours(endHours, endMinutes, 0);

        let diffMs = endDate - startDate;

        let diffMinutes = Math.floor(diffMs / 1000 / 60);
        let hours = Math.floor(diffMinutes / 60);
        let minutes = diffMinutes % 60;

        if (minutes < 10) {
            minutes = `0${minutes}`
        } else {
            minutes = `${minutes}`
        }

        if (hours < 10) {
            hours = `0${hours}`
        } else {
            hours = `${hours}`
        }

        let currentDuration = hours + ':' + minutes

        setDuration(currentDuration)


    }, [selectedHourStart, selectedHourEnd])

    useEffect(() => {
        if(dataChangedLocal) dataChanged.current = true
    },[dataChangedLocal])

    const moveRectangle = () => {
        var matRectangle = identity();

        if (selectedHourRef.current.style.transform !== '') {
            matRectangle = fromString(selectedHourRef.current.style.transform);
        }

        const translationDiff = parseInt(selectedHourStart.split(':')[1])
        var transRectangle = translate(translationDiff / 60 * 179, 0);

        if(editConference){
            const scheduleDate = new Date(editConference.scheduleDate);
            const duration = editConference.duration;
    
            const endTime = new Date(scheduleDate.getTime() + duration * 60000);
    
            const twoHourMark = new Date(scheduleDate.getTime());
            twoHourMark.setHours(scheduleDate.getHours() + 2, 0, 0, 0); 
            const threeHourMark = new Date(scheduleDate.getTime());
            threeHourMark.setHours(scheduleDate.getHours() + 3, 0, 0, 0); 
    
            if (endTime > twoHourMark) {
                if (endTime > threeHourMark) {
                    transRectangle = translate(translationDiff / 60 * 179 - 358, 0);
                } else {
                    transRectangle = translate(translationDiff / 60 * 179 - 179, 0);
                }
            }
        }
        

        var finalRectangle = compose(transRectangle, matRectangle);
        selectedHourRef.current.style.transform = toString(finalRectangle);
    }

    const addConference = () => {
        // const formattedDate = `${new Date(selectedDate).toISOString().split('T')[0]}T${selectedHourStart}:00.000`
        let formattedDate = new Date(selectedDate)
        formattedDate.setHours(selectedHourStart.split(":")[0])
        formattedDate.setMinutes(selectedHourStart.split(":")[1])

        const [hours, minutes] = duration.split(":");

        // //console.log(participantsToSave) // doar id participant [{participant: id, desk: id, isModerator: false}]

        let attachedParticipantsList = []

        // //console.log(participantsToSave)

        for(let i = 0; i < participantsToSave.length; i++){
            attachedParticipantsList.push({participant: participantsToSave[i]._id, desk: null, isModerator: false})
        }

        // //console.log(attachedParticipantsList)
        // //console.log(formattedDate)

        // de trimis toata lista de participanti (si noi si vechi)

        // //console.log({projectId: selectedProject._id,
        //     conferenceId: editConference ? editConference._id : null,
        //     newData: {
        //         name: titleRef.current?.value ? titleRef.current.value : "Default Title",
        //         scheduleDate: formattedDate,
        //         duration: parseInt(hours) * 60 + parseInt(minutes),
        //         participants: attachedParticipantsList,
        //         type: 'Meeting' // 'Meeting', 'Conference'
        //     }})

        socketRef.current.emit("UPDATE_CONFERENCE", {
            projectId: selectedProject._id,
            conferenceId: editConference ? editConference._id : null,
            newData: {
                name: titleRef.current?.value ? titleRef.current.value : "Default Title",
                scheduleDate: formattedDate,
                duration: parseInt(hours) * 60 + parseInt(minutes),
                participants: attachedParticipantsList,
                type: 'Meeting' // 'Meeting', 'Conference'
            }
        }, () => {
            if (sendingInvitation) {
                setSendingInvitation(false)
                setSent(true)
            } else if (save) {
                if (editConference)
                    setEditConference()
                else
                    setCreateMeeting()
            }

        });
    };

    const closePage = () => {
        if (!dataChanged.current) {
            if (editConference)
                setEditConference()
            else
                setCreateMeeting()
        } else {
            setUnsaved(true)
        }

    }


    function onFocusInput() {
        dataChanged.current = true;
        setIsConfNameOnFocus(true)
    }


    return (
        <PopupBaseComponent flexDirection='column'>
            <video id="background-video" className={styles.background} autoPlay loop playsInline muted style={{ height: appHeight, width: appWidth}}>
                <source src={video} type="video/mp4" />
            </video>

            <DatePickerComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} dataChanged={dataChanged}
                calendarRef={calendarRef} dayConferences={dayConferences}/>

            <HourPickerComponent initialHourEndRef={initialHourEndRef} initialHourStartRef={initialHourStartRef}
                selectedHourRef={selectedHourRef} dataChanged={dataChanged} selectedDate={selectedDate}
                selectedHourStart={selectedHourStart} setSelectedHourStart={setSelectedHourStart} hasIntersection={hasIntersection}
                setSelectedHourEnd={setSelectedHourEnd} duration={duration} dayConferences={dayConferences} selectedHourEnd={selectedHourEnd}/>

            <TimePickerComponent initialHourEndRef={initialHourEndRef} initialHourStartRef={initialHourStartRef}
                selectedHourRef={selectedHourRef} selectedHourStart={selectedHourStart}
                setSelectedHourStart={setSelectedHourStart} selectedHourEnd={selectedHourEnd}
                setSelectedHourEnd={setSelectedHourEnd} dataChanged={dataChanged} />

            <div className={styles.title}>
                Conference title
                <InputTextComponent ref={titleRef} onFocus={onFocusInput} keyboardRef={keyboardRef} value={editConference ? editConference.name : "New Conference"} fontSize="160px" placeHolder="New Conference" styleType='CONFERENCE' />
            </div>
            <div className={styles.duration}>
                Duration
                <div className={styles.durationInput}>
                    <span style={{ fontWeight: 'bold' }}>{duration.split(':')[0]}</span>:{duration.split(':')[1]}
                </div>
                <div className={styles.hoursMin}>
                    <div className={styles.hoursMinLeft}>hours</div>
                    min
                </div>
            </div>
            <ParticipantPickerComponent setDataChangedLocal={setDataChangedLocal} dataChanged={dataChanged} setParticipantsToSaveFunc={setParticipantsToSave} 
            participantsIds={editConference ? editConference.attachedParticipants : []}/>
            <div className={styles.buttonDiv}>
                {dataChanged.current && <>
                    <DarkButtonComponent
                        style={{
                            width: 353,
                            height: 82,
                            borderRadius: 16,
                            font: 'normal normal 600 40px/33px Bio Sans',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => setSave(true)}
                    >
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['floppy-disk']} /> Save
                    </DarkButtonComponent>

                    <LightButtonComponent
                        style={{
                            width: 759,
                            height: 82,
                            borderRadius: 16,
                            font: 'normal normal 600 40px/33px Bio Sans',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 30
                        }} onClick={() => setSendingInvitation(true)}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['paper-plane']} />    Create and send invitations
                    </LightButtonComponent>

                </>}

                <DarkButtonComponent
                    style={{
                        width: 353,
                        height: 82,
                        borderRadius: 16,
                        font: 'normal normal 600 40px/33px Bio Sans',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 30
                    }}
                    onClick={closePage}
                >
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['xmark']} />Close
                </DarkButtonComponent>
            </div>
            <div className={styles.leftShadow}></div>
            <div className={styles.rightShadow}></div>
            {sendingInvitation && <ConferenceSendMessagesComponent type="sending">
                <DarkButtonComponent style={buttonStyling} onClick={() => setSendingInvitation(false)}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['rotate-left']} /> Undo
                </DarkButtonComponent>
                <DarkButtonComponent style={buttonStyling} onClick={addConference}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['check']} /> Done
                </DarkButtonComponent>
            </ConferenceSendMessagesComponent>}
            {sent && <ConferenceSendMessagesComponent type="sent">
                <DarkButtonComponent style={buttonStyling} onClick={editConference ? setEditConference : setCreateMeeting}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['close']} /> Close
                </DarkButtonComponent>
            </ConferenceSendMessagesComponent>}
            {unsaved && <ConferenceSendMessagesComponent type="unsaved">
                <DarkButtonComponent style={buttonStyling} onClick={() => setUnsaved(false)}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['close']} /> Cancel
                </DarkButtonComponent>
                <DarkButtonComponent style={buttonStyling} onClick={editConference ? setEditConference : setCreateMeeting}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['check']} /> Proceed
                </DarkButtonComponent>
            </ConferenceSendMessagesComponent>}
            {save && <ConferenceSendMessagesComponent type="saved">
                <DarkButtonComponent style={buttonStyling} onClick={addConference}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['close']} /> Close
                </DarkButtonComponent>
            </ConferenceSendMessagesComponent>}
        </PopupBaseComponent>
    );

}

export default AddMeetingComponent;