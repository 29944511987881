import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { Device } from 'mediasoup-client'

import { GlobalContext } from "../../contexts/globalContext"
import { useContext } from 'react';

import VideoStreamComponent from "./VideoStreamComponent"
import AudioStreamComponent from "./AudioStreamComponent"

function MediasoupConferenceComponent() {


    const { currentConference, appHeight, currentParticipant, setCurrentModeratorStream, currentTopic, setCurrentParticipantStream, currentParticipantStream, videoStreams, setVideoStreams } = useContext(GlobalContext)
    // const [videoStreams, setVideoStreams] = useState([]);
    const videoStreamsRef = useRef()
    const [audioStreams, setAudioStreams] = useState([]);
    const audioStreamsRef = useRef()


    const [videoElementHeight, setVideoElementHeight] = useState([]);

    const socketRoomRef = useRef()
    // const localVideoRef = useRef()

    const remoteVideosContainerRef = useRef()
    const currentTopicRef = useRef()

    let params = {
        encodings: [
            {
                rid: 'r0',
                maxBitrate: 100000,
                scalabilityMode: 'S1T3',
            },
            {
                rid: 'r1',
                maxBitrate: 300000,
                scalabilityMode: 'S1T3',
            },
            {
                rid: 'r2',
                maxBitrate: 900000,
                scalabilityMode: 'S1T3',
            },
        ],
        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#ProducerCodecOptions
        codecOptions: {
            videoGoogleStartBitrate: 1000
        }
    }

    let audioParams;
    let videoParams = { params };
    let consumingTransports = [];

    let device;
    let rtpCapabilities;
    let producerTransport;
    let consumerTransports = [];
    let audioProducer;
    let videoProducer;
    let previous_socket_id = null;

    useEffect(() => {
        currentTopicRef.current = currentTopic;
        if (currentTopic && videoStreamsRef.current) {
            var currVideo = videoStreamsRef.current.find(element => element.participant._id == currentTopic?.moderator._id);
            if (currVideo) {
                setCurrentModeratorStream(currVideo);
            }
        } else {
            setCurrentModeratorStream(null);
        }
        
        if (currentTopic?.moderator._id == currentParticipant._id && currentParticipantStream) {
            setCurrentModeratorStream({ remoteProducerId: "local", participant: currentParticipant, stream: currentParticipantStream });
        }
        
    }, [currentTopic])

    useEffect(() => {
        videoStreamsRef.current = videoStreams;
        if (videoStreamsRef.current && currentTopic) {

            var currModerator = videoStreamsRef.current.find(mod=> mod.participant._id == currentTopic?.moderator._id);

            if(currModerator) {
                setCurrentModeratorStream(currModerator);
            } else {
                if (currentTopic?.moderator._id != currentParticipant._id) {
                    setCurrentModeratorStream(null);
                }
            }

            // if (videoStreamsRef.current.length > 0 && videoStreamsRef.current[videoStreamsRef.current.length - 1].participant._id == currentTopic?.moderator._id) {
            //     setCurrentModeratorStream(videoStreamsRef.current[videoStreamsRef.current.length - 1]);

            // } else {
            //     if (currentTopic?.moderator._id != currentParticipant._id) {
            //         setCurrentModeratorStream(null);
            //     }
            // }
        }
    }, [videoStreams])

    useEffect(() => {
        audioStreamsRef.current = audioStreams;
    }, [audioStreams])


    useEffect(() => {
        setVideoElementHeight(appHeight / 10);

        socketRoomRef.current = io.connect(process.env.REACT_APP_MEDIASOUP_URL, { secure: true, reconnect: true, rejectUnauthorized: false })
        socketRoomRef.current.pingTimeout = 1000;
        socketRoomRef.current.pingInterval = 3000;

        socketRoomRef.current.on('connection-success', ({ socketId }) => {
            // if (previous_socket_id) {
            //     SetWaitingForJoining(true);
            // }
            // if (previous_socket_id) {
            //     //console.log("inchide previous_socket_id", previous_socket_id);
            //     socketRoomRef.current.emit("remove-socketid", previous_socket_id, () => {
            //         // producerTransport.close();
            //         producerTransport = null;

            //         // for (let index = 0; index < consumerTransports.length; index++) {
            //         //     const element = consumerTransports[index];
            //         //     //console.log(element);
            //         //     element?.consumerTransport.close();

            //         // }
            //          consumerTransports = [];
            //         // audioProducer?.close();
            //         // videoProducer?.close();


            //         setVideoStreams([]);
            //         setAudioStreams([]);
            //         GetLocalStream()

            //     });




            // } else {
            //     GetLocalStream();
            // }
            previous_socket_id = socketRoomRef.current.id;
            GetLocalStream();
            ////console.log("connection succes", socketRoomRef.current.id);

        })

        socketRoomRef.current.on('new-producer', ({ producerId }) => signalNewConsumerTransport(producerId))

        socketRoomRef.current.on('producer-closed', ({ remoteProducerId }) => {
            // server notification is received when a producer is closed
            // we need to close the client-side consumer and associated transport
            const producerToClose = consumerTransports.find(transportData => transportData.producerId === remoteProducerId)
            if (producerToClose) {
                producerToClose.consumerTransport.close()
                producerToClose.consumer.close()
            }

            // remove the consumer transport from the list
            consumerTransports = consumerTransports.filter(transportData => transportData.producerId !== remoteProducerId)

            // remove the video div element
            //setVideoStreams(devices => [...devices,  new MediaStream([track])]);   
            ////console.log("remove id", remoteProducerId);
            ////console.log("transportData", consumerTransports);
            setVideoStreams(videoStreamsRef.current.filter(a => a.remoteProducerId !== remoteProducerId));
            setAudioStreams(audioStreamsRef.current.filter(a => a.remoteProducerId !== remoteProducerId));
            //setVideoStreams(drones => drones.filter(a => a.producerId !== remoteProducerId))
            //setAudioStreams(drones => drones.filter(a => a.producerId !== remoteProducerId))

            //setAudioStreams(audioStreams.filter(stream => stream.producerId !== remoteProducerId));
            //setVideoStreams(videoStreams.filter(stream => stream.producerId !== remoteProducerId));
            //remoteVideosContainerRef.current.removeChild(document.getElementById(`td-${remoteProducerId}`))
        })

        socketRoomRef.current.on("disconnect", () => {
            setVideoStreams([]);
            setAudioStreams([]);


            device = null;
            rtpCapabilities = null;

            producerTransport?.close();
            producerTransport = null;

            for (let index = 0; index < consumerTransports.length; index++) {
                const element = consumerTransports[index];
                element.consumerTransport.close();
            }

            audioParams = {};
            videoParams = { params };

            consumerTransports = [];
            consumingTransports = [];
            audioProducer?.close();
            videoProducer?.close();
            audioProducer = null;
            videoProducer = null;

            //console.log("disconnected"); // undefined
        });

        return () => {
            //console.log("disconnect");
            socketRoomRef.current.close();

        }
    }, [])

    const GetLocalStream = () => {
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {
                width: {
                    min: 640,
                    max: 960,
                    //max: 1920,
                },
                height: {
                    min: 400,
                    max: 540,
                    //max: 1080,
                }
            }
        })
            .then(StreamSuccess)
            .catch(error => {
                //console.log(error.message)
            })
    }



    const StreamSuccess = (stream) => {
        setCurrentParticipantStream(stream);

        if (currentTopicRef.current?.moderator._id == currentParticipant?._id) {
            setCurrentModeratorStream({ remoteProducerId: "local", participant: currentParticipant, stream });
        }

        audioParams = { track: stream.getAudioTracks()[0], ...audioParams };
        videoParams = { track: stream.getVideoTracks()[0], ...videoParams };

        JoinRoom();
    }

    const JoinRoom = () => {
        socketRoomRef.current.emit('joinRoom', { roomName: currentConference._id }, (data) => {
            ////console.log(`Router RTP Capabilities... ${data.rtpCapabilities}`)
            // we assign to local variable and will be used when
            // loading the client Device (see createDevice above)
            rtpCapabilities = data.rtpCapabilities

            // once we have rtpCapabilities from the Router, create Device
            CreateDevice()
        })
    }

    const CreateDevice = async () => {
        try {
            device = new Device()

            // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-load
            // Loads the device with RTP capabilities of the Router (server side)
            await device.load({
                // see getRtpCapabilities() below
                routerRtpCapabilities: rtpCapabilities
            })

            ////console.log('Device RTP Capabilities', device.rtpCapabilities)

            // once the device loads, create transport
            CreateSendTransport()

        } catch (error) {
            //console.log(error)
            if (error.name === 'UnsupportedError')
                console.warn('browser not supported')
        }
    }

    const CreateSendTransport = () => {
        // see server's socket.on('createWebRtcTransport', sender?, ...)
        // this is a call from Producer, so sender = true
        socketRoomRef.current.emit('createWebRtcTransport', { consumer: false }, ({ params }) => {
            // The server sends back params needed 
            // to create Send Transport on the client side
            if (params.error) {
                //console.log(params.error)
                return
            }

            ////console.log(params)

            // creates a new WebRTC Transport to send media
            // based on the server's producer transport params
            // https://mediasoup.org/documentation/v3/mediasoup-client/api/#TransportOptions
            producerTransport = device.createSendTransport(params)

            // https://mediasoup.org/documentation/v3/communication-between-client-and-server/#producing-media
            // this event is raised when a first call to transport.produce() is made
            // see connectSendTransport() below
            producerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                try {
                    // Signal local DTLS parameters to the server side transport
                    // see server's socket.on('transport-connect', ...)
                    await socketRoomRef.current.emit('transport-connect', {
                        dtlsParameters,
                    })

                    // Tell the transport that parameters were transmitted.
                    callback()

                } catch (error) {
                    errback(error)
                }
            })

            producerTransport.on('produce', async (parameters, callback, errback) => {
                // //console.log('produce')
                // //console.log(parameters)
                // //console.log(currentParticipant)


                try {
                    // tell the server to create a Producer
                    // with the following parameters and produce
                    // and expect back a server side producer id
                    // see server's socket.on('transport-produce', ...)
                    await socketRoomRef.current.emit('transport-produce', {
                        kind: parameters.kind,
                        rtpParameters: parameters.rtpParameters,
                        participant: currentParticipant,
                    }, ({ id, producersExist }) => {
                        // Tell the transport that parameters were transmitted and provide it with the
                        // server side producer's id.
                        callback({ id })

                        // if producers exist, then join room
                        if (producersExist) getProducers()
                    })
                } catch (error) {
                    errback(error)
                }
            })

            connectSendTransport()
        })
    }

    const connectSendTransport = async () => {
        // we now call produce() to instruct the producer transport
        // to send media to the Router
        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#transport-produce
        // this action will trigger the 'connect' and 'produce' events above

        audioProducer = await producerTransport.produce(audioParams);
        videoProducer = await producerTransport.produce(videoParams);

        audioProducer.on('trackended', () => {
            //console.log('audio track ended')

            // close audio track
        })

        audioProducer.on('transportclose', () => {
            //console.log('audio transport ended')

            // close audio track
        })

        videoProducer.on('trackended', () => {
            //console.log('video track ended')

            // close video track
        })

        videoProducer.on('transportclose', () => {
            //console.log('video transport ended')

            // close video track
        })
    }

    const signalNewConsumerTransport = async (remoteProducerId) => {
        //check if we are already consuming the remoteProducerId
        if (consumingTransports.includes(remoteProducerId)) return;
        consumingTransports.push(remoteProducerId);

        await socketRoomRef.current.emit('createWebRtcTransport', { consumer: true }, ({ params }) => {
            // The server sends back params needed 
            // to create Send Transport on the client side
            if (params.error) {
                //console.log(params.error)
                return
            }

            let consumerTransport
            try {
                consumerTransport = device.createRecvTransport(params)
            } catch (error) {
                // exceptions: 
                // {InvalidStateError} if not loaded
                // {TypeError} if wrong arguments.
                //console.log(error)
                return
            }

            consumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                try {
                    // Signal local DTLS parameters to the server side transport
                    // see server's socket.on('transport-recv-connect', ...)
                    await socketRoomRef.current.emit('transport-recv-connect', {
                        dtlsParameters,
                        serverConsumerTransportId: params.id,
                    })

                    // Tell the transport that parameters were transmitted.
                    callback()
                } catch (error) {
                    // Tell the transport that something was wrong
                    errback(error)
                }
            })

            connectRecvTransport(consumerTransport, remoteProducerId, params.id)
        })
    }

    // server informs the client of a new producer just joined


    const getProducers = () => {
        socketRoomRef.current.emit('getProducers', producerIds => {
            // for each of the producer create a consumer
            producerIds.forEach(signalNewConsumerTransport)
        })

        // socketRoomRef.current.emit('getProducers', producers_list => {
        //     //console.log("producers_list", producers_list)
        //     producers = producers_list;
        //     //setProducers(producers);
        //     // for each of the producer create a consumer
        //     // producerIds.forEach(id => signalNewConsumerTransport(id))
        //     producers.forEach(producer => {
        //         //console.log(producer.producerId);
        //         signalNewConsumerTransport(producer.producerId)
        //     }
        //     )
        // })
    }

    const connectRecvTransport = async (consumerTransport, remoteProducerId, serverConsumerTransportId) => {
        // for consumer, we need to tell the server first
        // to create a consumer based on the rtpCapabilities and consume
        // if the router can consume, it will send back a set of params as below
        await socketRoomRef.current.emit('consume', {
            rtpCapabilities: device.rtpCapabilities,
            remoteProducerId,
            serverConsumerTransportId,
        }, async ({ params }) => {
            if (params.error) {
                //console.log('Cannot Consume')
                return
            }

            ////console.log(`Consumer Params ${params}`)
            // then consume with the local consumer transport
            // which creates a consumer
            const consumer = await consumerTransport.consume({
                id: params.id,
                producerId: params.producerId,
                kind: params.kind,
                rtpParameters: params.rtpParameters
            })

            consumerTransports = [
                ...consumerTransports,
                {
                    consumerTransport,
                    serverConsumerTransportId: params.id,
                    producerId: remoteProducerId,
                    consumer,
                },
            ]

            // create a new div element for the new consumer media
            //const newElem = document.createElement('div')
            //newElem.setAttribute('id', `td-${remoteProducerId}`)

            const { track } = consumer

            ////console.log("add id", remoteProducerId);
            ////console.log("params.kind", params.kind);

            if (params.kind == 'audio') {
                //append to the audio container
                // newElem.innerHTML = '<audio id="' + remoteProducerId + '" autoplay muted></audio>'
                // remoteVideosContainerRef.current.appendChild(newElem)

                // const { track } = consumer
                // document.getElementById(remoteProducerId).srcObject = new MediaStream([track])
                //setAudioStreams([...audioStreams, new MediaStream([track])]);
                setAudioStreams(devices => devices.filter(a => a.participant._id !== params.participant._id));
                setAudioStreams(devices => [...devices, { remoteProducerId, participant: params.participant, stream: new MediaStream([track]) }]);

            } else {
                //append to the video container
                //newElem.setAttribute('class', 'remoteVideo')
                //newElem.innerHTML = '<video muted style="border-radius:50px; border:6px solid white;margin:5px" id="' + remoteProducerId + '" width="auto" height="' + videoElementHeight + '" autoplay></video>'
                //setVideoStreams([...videoStreams, new MediaStream([track])])

                setVideoStreams(devices => devices.filter(a => a.participant._id !== params.participant._id));
                setVideoStreams(devices => [...devices, { remoteProducerId, participant: params.participant, stream: new MediaStream([track]) }]);
                //setVideoStreams(devices => [...devices, new MediaStream([track])]);
            }

            //remoteVideosContainerRef.current.appendChild(newElem)

            // destructure and retrieve the video track from the producer




            //document.getElementById(remoteProducerId).srcObject = new MediaStream([track])
            socketRoomRef.current.emit('consumer-resume', { serverConsumerId: params.serverConsumerId })
        })
    }

    return (
        <div style={{ position: "relative", width: "100%", height: "100%", backgroundColor: "transparent" }}>
            {/* <video style={{ position: "absolute", bottom: "10px", left: "10px", borderRadius: "50px", border: "7px solid white" }} ref={localVideoRef} height={videoElementHeight} autoPlay muted></video> */}
            <div ref={remoteVideosContainerRef} style={{ width: "auto", padding: "10px", position: "absolute", height: "100%", right: "10px", display: "flex", flexDirection: "column", justifyContent: "center", flexWrap: "wrap-reverse" }}>
                {audioStreams.map((audioStream) => (
                    <AudioStreamComponent key={audioStream.remoteProducerId} audioStream={audioStream}></AudioStreamComponent>
                    // <video muted src={mediaStream} style={{borderRadius: "50px", border: "7px solid white"}} width="auto" height={videoElementHeight} autoplay></video>
                ))}
                {videoStreams.map((mediaStream) => (
                    <VideoStreamComponent key={mediaStream.remoteProducerId} videoStream={mediaStream} height={videoElementHeight}></VideoStreamComponent>
                    // <video muted src={mediaStream} style={{borderRadius: "50px", border: "7px solid white"}} width="auto" height={videoElementHeight} autoplay></video>
                ))}
            </div>
        </div>
    );
}

export default MediasoupConferenceComponent;
