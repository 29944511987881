import { useRef, useContext, useEffect } from 'react';
import styles from './DeleteNotificationComponent.module.css';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';
import DarkButtonComponent from '../../commons/DarkButtonComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import trashAnim from '../../../graphics/conference add/anim-trash.json';
import Lottie from 'react-lottie-player';
import { GlobalContext } from '../../../contexts/globalContext'

const DeleteNotificationComponent = ({ deleteClicked, setDeleteClicked }) => {
    const componentRef = useRef();

    const { funct, name, onClose, fisrtWord = 'Remove', x, y } = deleteClicked

    const { language, appWidth, appHeight } = useContext(GlobalContext);

    const oxDivider = window.innerWidth / appWidth;
    const oyDivider = window.innerHeight / appHeight;

    useEffect(() => {
        console.log("creat")
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className={styles.component} style={{ transform: `matrix(1, 0, 0, 1, ${x / oxDivider - 1024}, ${y / oyDivider})`, zIndex: GetMaxZIndex() + 1 }} tabIndex="0"
            onBlur={() => {
                setDeleteClicked({})
            }} ref={componentRef}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Lottie
                    loop={true}
                    play={true}
                    animationData={trashAnim}
                    className={styles.trash}
                />
            </div>
            <div className={styles.divColumn}>
                <label className={styles.boldText}>{fisrtWord} {name}</label>
                <label className={styles.question}>Are you sure you want to {fisrtWord.toLowerCase()} this {name.toLowerCase()}?</label>
                <DarkButtonComponent
                    style={{
                        position: "relative",
                        borderRadius: 16,
                        border: 'none',
                        marginTop: 22,
                        width: 270,
                        height: 71,
                        font: "normal normal 600 32px/70px Bio Sans"
                    }}
                    onClick={() => {console.log("click");funct()}}
                >
                    DELETE
                </DarkButtonComponent>
            </div>
            <FontAwesomeIcon
                style={{ color: '#05002080', width: 30, height: 40, position: 'absolute', top: 18, right: 22 }}
                icon={byPrefixAndName.fas['xmark']}
                onClick={onClose}
            />
        </div>
    );
};

export default DeleteNotificationComponent;
