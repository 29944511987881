import { useRef, useContext, useState, useEffect } from 'react';
import styles from './NotAttachedParticipantsComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import bigIcon from '../../../graphics/Popups/img-arrange-part.png'

import InitialsCircleComponent from '../../commons/InitialsCircleComponent';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';
import DarkButtonComponent from '../../commons/DarkButtonComponent';

import New_KeyboardComponent from '../../../components/keyboard/New_KeyboardComponent';
import New_InputTextComponent from '../../../components/keyboard/New_InputTextComponent';


import { getUpcomingConferences } from '../../../utils/UtilitaryFunctions'

const NotAttachedParticipantsComponent = ({ displayedRoom, isRemote, allDisplayedDesks, displayedParticipants, setDisplayedParticipants, participantsFilter, setParticipantsFilter,
    attachedParticipants, setAttachedParticipants, allParticipantsRef, attachedParticipantsRect }) => {

    const inputRef = useRef()
    const keyboardRef = useRef()
    const participantsFilterSearchRef = useRef()
    const currentTargetRef = useRef()

    const [isDragging, setIsDragging] = useState(false);

    const [selectedFilter, setSelectedFilter] = useState("All")

    const [selectedParticipant, setSelectedParticipant] = useState()

    const { language, appWidth, socketRef, appHeight, currentUser, setNotificationUpcomingConferences, currentConference, deskData, videoStreams, setAlertMessage } = useContext(GlobalContext);
    const { conferenceDetailsForParticipants, setAddVisitor, uniqueModerators } = useContext(AddConferenceContext);

    const oxDivider = window.innerWidth / appWidth;
    const oyDivider = window.innerHeight / appHeight;

    const filterOptions = [
        "All", "Members", "Guests", "Partners", "Visitors"
    ]

    const currentConferenceRef = useRef();

    useEffect(() => {
        if (conferenceDetailsForParticipants) {
            currentConferenceRef.current = conferenceDetailsForParticipants;
        } else {
            currentConferenceRef.current = currentConference;
        }       
    }, [])

    useEffect(() => {
        if (allParticipantsRef.current) {
            let result = []
            if (inputRef.current.value) {
                result = allParticipantsRef.current.filter(participant => participant.name?.toLowerCase().includes(inputRef.current.value.toLowerCase()));
            } else {
                result = allParticipantsRef.current
            }

            let value = selectedFilter
            value = value.toUpperCase().replace(/S$/, '');

            if (value !== "ALL") {
                if (value === "MEMBER")
                    result = result.filter(participant => participant.role.includes(value) || participant.role.includes('ADMINISTRATOR'));
                else
                    result = result.filter(participant => participant.role.includes(value));
            }
            setDisplayedParticipants(result)
        }
    }, [inputRef.current?.value])

    useEffect(() => {
        if (participantsFilter === 'ALL') {
            let result = []
            result = allParticipantsRef.current

            if (inputRef.current.value) {
                result = result.filter(participant => participant.name?.toLowerCase().includes(inputRef.current.value.toLowerCase()));
            } else {
                result = result
            }

            setSelectedFilter('All')
            setDisplayedParticipants(result);
        }
    }, [participantsFilter])

    const handleFilterChange = (e) => {

        let value = e.target.innerText
        value = value.toUpperCase().replace(/S$/, '');

        let result = []
        if (value !== "ALL") {
            if (value === "MEMBER")
                result = allParticipantsRef.current.filter(participant => participant.role.includes(value) || participant.role.includes('ADMINISTRATOR'));
            else
                result = allParticipantsRef.current.filter(participant => participant.role.includes(value));

        } else {
            result = allParticipantsRef.current
        }

        if (inputRef.current.value) {
            result = result.filter(participant => participant.name?.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } else {
            result = result
        }

        setSelectedFilter(e.target.innerText)
        setDisplayedParticipants(result);
        setParticipantsFilter(e.target.innerText.toUpperCase().replace(/S$/, ''));


    }

    function onTextChanged() {
        setDisplayedParticipants([])
    }

    const addParticipantToRoom = (boxNumber) => {

        const availableDesk = allDisplayedDesks.find(
            (desk) => desk.number === boxNumber
        );

        // cautam daca unul dintre participanti este online
        let switchParticipant = attachedParticipants.find(
            (attached) => attached.desk?._id === availableDesk._id || attached.desk === availableDesk._id
        );

        if(switchParticipant){
            switchParticipant.isOnline = switchParticipant?.desk._id === deskData?._id && currentConference?.status === "Active" ? true : false;

            const videos = videoStreams.find((stream) => stream.participant._id === switchParticipant?.participant._id)

            if(videos){
                switchParticipant.isOnline = true
            }

            if(switchParticipant.isOnline){
                setAlertMessage({ type: "ERROR", message: "The participant on the desk is in the conference." });
                currentTargetRef.current.style.transform = `translate(${0}px, ${0}px)`;
    
                currentTargetRef.current.style.position = 'relative';
                currentTargetRef.current.style.backgroundColor = "#2D2748"
                currentTargetRef.current.style.left = "0px";
                currentTargetRef.current.style.top = "0px";
                currentTargetRef.current.style.zIndex = 0
                return;
            }

            console.log(uniqueModerators)

            console.log(switchParticipant?.participant._id)

            if((uniqueModerators.some(moderator => moderator._id === switchParticipant?.participant._id))) {
                setAlertMessage({ type: "ERROR", message: "You can't remove a participant that is a moderator of the conference." })

                currentTargetRef.current.style.transform = `translate(${0}px, ${0}px)`;
    
                currentTargetRef.current.style.position = 'relative';
                currentTargetRef.current.style.backgroundColor = "#2D2748"
                currentTargetRef.current.style.left = "0px";
                currentTargetRef.current.style.top = "0px";
                currentTargetRef.current.style.zIndex = 0
                return;
            }


            // if(switchParticipant.isOnline){
            //     setAlertMessage({ type: "ERROR", message: "The participant on the desk is in the conference." });
            //     currentTargetRef.current.style.transform = `translate(${0}px, ${0}px)`;
    
            //     currentTargetRef.current.style.position = 'relative';
            //     currentTargetRef.current.style.backgroundColor = "#2D2748"
            //     currentTargetRef.current.style.left = "0px";
            //     currentTargetRef.current.style.top = "0px";
            //     currentTargetRef.current.style.zIndex = 0
            //     return;
            // }
        }
        
        

        socketRef.current.emit("CONFERENCE_ADD_PARTICIPANT_TO_DESK", {
            conferenceId: currentConferenceRef.current._id,
            participantId: selectedParticipant._id,
            deskId: availableDesk._id,
            roomId: displayedRoom._id
        }, callback => {

            if (!callback.error) {
                socketRef.current.emit("DESK_PARTICIPANT_CHANGED", availableDesk._id);

                setAttachedParticipants(callback.response.attachedParticipants)

                let updatedParticipants = allParticipantsRef.current.filter((participant) => participant._id !== selectedParticipant._id)

                if(callback.response.removedParticipant)
                    updatedParticipants.push(callback.response.removedParticipant)
                

                allParticipantsRef.current = updatedParticipants;

                if (participantsFilter === "ALL") {
                    setDisplayedParticipants(updatedParticipants);
                }
                setParticipantsFilter('ALL')

                if (socketRef.current && currentUser) {
                    getUpcomingConferences(socketRef, currentUser._id, setNotificationUpcomingConferences)
                }
            }

        })
    }

    const addRemoteParticipant = () => {
        socketRef.current.emit("CONFERENCE_ADD_REMOTE_PARTICIPANT", {
            conferenceId: currentConferenceRef.current._id,
            participantId: selectedParticipant._id,
        }, callback => {
            if (!callback.error) {
                setAttachedParticipants(callback.response)
                setDisplayedParticipants((prevParticipants) =>
                    prevParticipants.filter((participant) => participant._id !== selectedParticipant._id)
                );
                allParticipantsRef.current = allParticipantsRef.current.filter((participant) => participant._id !== selectedParticipant._id)
                if (socketRef.current && currentUser) {
                    getUpcomingConferences(socketRef, currentUser._id, setNotificationUpcomingConferences)
                }
            }
        })

    }

    useEffect(() => {
        if (isDragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
            window.addEventListener("touchmove", handleMouseMove);
            window.addEventListener("touchend", handleMouseUp);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("touchmove", handleMouseMove);
            window.removeEventListener("touchend", handleMouseUp);
        };
    }, [isDragging]);

    const handleMouseDown = (e) => {

        if (currentTargetRef.current !== e.currentTarget) {
            currentTargetRef.current = e.currentTarget.parentNode
        }

        setIsDragging(true);

        if (e.touches) {
            currentTargetRef.current.startX = e.touches[0].clientX;
            currentTargetRef.current.startY = e.touches[0].clientY;

            currentTargetRef.current.lastX = e.touches[0].clientX;
            currentTargetRef.current.lastY = e.touches[0].clientY;
        } else {
            currentTargetRef.current.startX = e.clientX;
            currentTargetRef.current.startY = e.clientY;

            currentTargetRef.current.lastX = e.clientX;
            currentTargetRef.current.lastY = e.clientY;
        }


        currentTargetRef.current.style.zIndex = GetMaxZIndex() + 1
        currentTargetRef.current.style.backgroundColor = "#1f1b31"

        let boundingBox = currentTargetRef.current.getBoundingClientRect();
        currentTargetRef.current.style.position = 'fixed';


        currentTargetRef.current.style.left = String(boundingBox.x / oxDivider) + "px";
        currentTargetRef.current.style.top = String(boundingBox.y / oyDivider) + "px";


    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        let newX = 0
        let newY = 0

        if (e.touches) {
            newX = (e.touches[0].clientX - currentTargetRef.current.startX) / oxDivider;
            newY = (e.touches[0].clientY - currentTargetRef.current.startY) / oyDivider;

            currentTargetRef.current.lastX = e.touches[0].clientX;
            currentTargetRef.current.lastY = e.touches[0].clientY;
        } else {
            newX = (e.clientX - currentTargetRef.current.startX) / oxDivider;
            newY = (e.clientY - currentTargetRef.current.startY) / oyDivider;

            currentTargetRef.current.lastX = e.clientX;
            currentTargetRef.current.lastY = e.clientY;
        }

        currentTargetRef.current.style.transform = `translate(${newX}px, ${newY}px)`;
    };

    const handleMouseUp = (e) => {
        setIsDragging(false);

        let mouseX = currentTargetRef.current.lastX;
        let mouseY = currentTargetRef.current.lastY;


        const targetRect = attachedParticipantsRect;

        if (isOverlapping(mouseX, mouseY, targetRect)) {
            if (isRemote) {
                addRemoteParticipant()
                return;
            }

            const boxHeight = targetRect.height / displayedRoom.totalDesks;

            const relativeY = mouseY - targetRect.top;

            const boxNumber = Math.floor(relativeY / boxHeight) + 1;

            if (!attachedParticipants.some((participant) => participant.desk?.number === boxNumber)) {
                addParticipantToRoom(boxNumber)
                return;
            }
        }

        currentTargetRef.current.style.transform = `translate(${0}px, ${0}px)`;
        currentTargetRef.current.style.backgroundColor = "#2D2748"

        currentTargetRef.current.style.position = 'relative';
        currentTargetRef.current.style.left = "0px";
        currentTargetRef.current.style.top = "0px";

        currentTargetRef.current.style.zIndex = 0

    };

    const isOverlapping = (mouseX, mouseY, rect) => {
        return (
            mouseX >= rect.left &&
            mouseX <= rect.right &&
            mouseY >= rect.top &&
            mouseY <= rect.bottom
        );
    };

    return (
        <div className={styles.leftBody}>
            <New_KeyboardComponent visible ref={keyboardRef} width="2140px" height="610px" s="1" />
            <div className={styles.info}>
                <img src={bigIcon} alt={"img"} className={styles.img} />
                <div className={styles.title}>Arrange Participants</div>
                <div className={styles.description}>
                    Arrange participants in the list according to their assigned spots in the rooms. <br></br><br></br>
                    You can also add remote users as needed.
                </div>
            </div>
            <div className={styles.participantsFilterSearch} ref={participantsFilterSearchRef}>
                <div className={styles.filter}>
                    {filterOptions.map((option, index) => <div key={index} className={selectedFilter === option ? styles.selectedTag : styles.tag} onClick={handleFilterChange}>
                        {option}
                    </div>)}
                </div>
                <New_InputTextComponent ref={inputRef} marginTop="40px" fontSize="30" width="90%" height="80px" keyboardRef={keyboardRef} value="" styleType='SEARCHPOPUP'
                    placeHolder={language.add_participants.Search} iconPosition="left" icon={byPrefixAndName.fal["magnifying-glass"]} withDelete={true} onChange={() => onTextChanged()} />

            </div>
            <div className={styles.participantsListAndAdd}>
                <div className={styles.participantsList}>
                    {displayedParticipants?.map((participant) => <div key={participant._id} className={styles.participantCard}>
                        <div onMouseDown={(e) => {
                            handleMouseDown(e)
                            setSelectedParticipant(participant)
                        }}
                            onTouchStart={(e) => {
                                handleMouseDown(e)
                                setSelectedParticipant(participant)
                            }}>
                            <InitialsCircleComponent name={participant.name} style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10 }} />
                        </div>

                        <div className={styles.participantDetails} >
                            <div style={{ letterSpacing: "2.2px" }}>{participant.role}</div>
                            <div className={styles.participantName}>{participant.name}</div>
                            {participant.function?.name} {participant.function ? "|" : ""} {participant.department?.name}
                        </div>
                    </div>)}

                </div>
                <div className={styles.participantListShadow} style={{ zIndex: GetMaxZIndex() + 1, pointerEvents: 'none' }} />
                <DarkButtonComponent style={{ backgroundColor: "#2D2748", position: "relative", marginBottom: 40, width: 456 }} onClick={() => setAddVisitor(true)}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} /> Add Visitor
                </DarkButtonComponent>
            </div>
        </div>

    );

}

export default NotAttachedParticipantsComponent;