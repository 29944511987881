import { useEffect, useContext, useState } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import ModeratorVideoComponent from "./ModeratorVideoComponent";
import ConfHeaderComponent from './ConfHeaderComponent';
import TopicComponent from './TopicComponent';
import { AddConferenceContext } from "../../contexts/addConferenceContext";

const TopicsComponent = () => {

    const { currentConference, socketRef, currentModeratorStream } = useContext(GlobalContext)
    const { fileList, openFiles } = useContext(AddConferenceContext)
    const [topics, setTopics] = useState([]);

    const [selectedTopic, setSelectedTopic] = useState([]);

    useEffect(() => {
        if (fileList && fileList.length > 0) {
            setTopics(objs =>
                objs.map(
                    (prev) => ({
                        ...prev,
                        files: prev._id === openFiles._id ? fileList : prev.files
                    })
                )
            );
        }
    }, [fileList])


    useEffect(() => {
        setTopics(currentConference.topics);

        // socketRef.current.on('DOCS_REFRESH_ACTIVE_CONFERENCE_TOPICS', (updatedTopic) => {
        //     setTopics(objs =>
        //         objs.map(item =>
        //             item._id === updatedTopic._id ? updatedTopic : item
        //         )
        //     );
        // })

        // return () => {
        //     socketRef.current?.off('DOCS_REFRESH_ACTIVE_CONFERENCE_TOPICS')
        // }
    }, [])

    return (
        <div style={{ position: "absolute", top: "20px", left: "165px" }}>
            <ConfHeaderComponent></ConfHeaderComponent>

            {currentModeratorStream && currentConference.type != "Presentation" && <ModeratorVideoComponent key={currentModeratorStream.remoteProducerId}></ModeratorVideoComponent>}

            {topics.map((topic, index) => (
                <TopicComponent key={topic._id} topic={topic} index={index + 1} selectedTopic={selectedTopic} setSelectedTopic={setSelectedTopic}></TopicComponent>
            ))}
        </div>
    );
}

export default TopicsComponent;