import { useContext } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import styles from './DetailsCountComponent.module.css'


const DetailCountComponent = ({ project, type, style }) => {
    const icon = (() => {
        switch (type) {
            case 'nrFiles':
                return 'files';
            case 'nrGuests':
                return 'id-card-clip';
            case 'nrParticipants':
                return 'users';
            case 'nrSubtopics':
                return 'bookmark';
            case 'nrAttachedParticipants':
                return 'users';
            case 'nrModerators':
                return 'microphone-stand';
            case 'nrSubfolders':
                return 'folder-tree';
            default:
                return 'book-bookmark';
        }
    })();

    const { language } = useContext(GlobalContext)


    return (
        <div className={styles.details_count_div} style={style}>
            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal[icon]} style={{ marginRight: icon === 'users' ? 2 : 10, marginLeft: icon === 'users' ? -5 : 0 }} />
            <div className={styles.details_type} style={style}>{language.projects_list_element[type]}:</div>
            <div className={styles.details_count} style={style}>
                <b>{(type !== 'nrAttachedParticipants'
                    ? project[type] ?? 0
                    : project.attachedParticipants?.length ?? 0).toLocaleString()}
                </b>
            </div>
        </div>
    );

}

export default DetailCountComponent;