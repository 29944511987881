import { useContext, useEffect } from 'react';

import { GlobalContext } from "../../../contexts/globalContext"
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { AddConferenceContext } from "../../../contexts/addConferenceContext"

import styles from './ConferenceDetailsComponent.module.css'

import video from '../../../graphics/big wave fundal loop_6.mp4'
import HeaderComponent from '../../commons/HeaderComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import DateTimeDetailsComponent from './DateTimeDetailsComponent';
import ParticipantsDetailsConferenceComponent from './ParticipantsDetailsConferenceComponent';
import TopicsDetailsConferenceComponents from './TopicsDetailsConferenceComponents';
import ConferenceAddNewTopicComponent from './ConferenceAddNewTopicComponent';
// import AddConferenceComponentNew from '../AddConferenceComponentNew';
import FilesDetailsConferenceDetailsComponent from './FilesDetailsConferenceDetailsComponent';
import ConferenceAddFilesComponent from './ConferenceAddFilesComponent';
import ManageParticipantsComponent from '../conferenceManageParticipants/ManageParticipantsComponent';
import DeleteNotificationComponent from '../common/DeleteNotificationComponent';
import AddModeratorPopup from './AddModeratorPopup';

import NewConferenceAddComponent from '../NewConferenceAddComponent';


const ConferenceDetailsComponent = () => {
    const { appHeight, appWidth } = useContext(GlobalContext)
    const { editConference } = useContext(ProjectDetailsContext)
    const { addNewTopic, addNewSubtopic, seeDateTime, openFiles, conferenceAddFiles, openParticipants, deleteClicked, setDeleteClicked, addModerator,
        moreClicked, setMoreClicked, setMoreMenuTimer, moreMenuTimer
    } = useContext(AddConferenceContext)

    useEffect(() => {
        if(moreMenuTimer){
            setTimeout(() => {
                setMoreMenuTimer(false)
            }, 200);
        }
    }, [moreMenuTimer]);

    return (
        <PopupBaseComponent style={{ height: `${appHeight}px`, width: `${appWidth}px`}} flexDirection='column'>
            <video id="background-video" autoPlay loop playsInline muted style={{ height: `${appHeight}px`, width: `${appWidth}px`}}>
                <source src={video} type="video/mp4"/>
            </video>
            <HeaderComponent/>
            <div className={styles.body}>
                <div className={styles.column} style={{width: (appWidth - 240) * 0.33}}>
                    <DateTimeDetailsComponent/>
                    <ParticipantsDetailsConferenceComponent/>
                </div>
                <div className={styles.column} style={{width: (appWidth - 240) * 0.405}}>
                    <TopicsDetailsConferenceComponents/>
                </div>
                <div className={styles.column} style={{width: (appWidth - 240) * 0.245}}>
                    {openFiles && editConference.topics.length > 0 && <FilesDetailsConferenceDetailsComponent/>}
                </div>
            </div>
            {addNewTopic && <ConferenceAddNewTopicComponent/>}
            

            {/* {seeDateTime && <AddConferenceComponentNew/>} */}
            {seeDateTime && <NewConferenceAddComponent/>}

            {conferenceAddFiles && <ConferenceAddFilesComponent/>}
            {openParticipants && <ManageParticipantsComponent/>}
            {deleteClicked?.shown == true && <DeleteNotificationComponent deleteClicked={deleteClicked} setDeleteClicked={setDeleteClicked} />}
            {addModerator && !addNewTopic && <AddModeratorPopup />}

            {/* {moreClicked?.shown == true && <MoreMenuComponent moreClicked={moreClicked} setMoreClicked={setMoreClicked} setMoreMenuTimer={setMoreMenuTimer} />} */}
        </PopupBaseComponent>
    );

}

export default ConferenceDetailsComponent;