import { useContext } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import CurrentParticipantVideoComponent from './CurrentParticipantVideoComponent';

const ConfHeaderComponent = () => {

    const { currentConference, currentTopic, currentParticipant, currentParticipantStream } = useContext(GlobalContext)

    return (
        <div style={{ display:"flex", flexDirection:"row", position: "relative", width: "900px", height: "190px", background: `${(currentParticipant._id == currentTopic?.moderator._id && currentConference.type != "Presentation")?"#EEEEEE":"#171132"}`, borderRadius: "10px", marginBottom: "10px" }}>
            {currentParticipantStream && <CurrentParticipantVideoComponent/>}
            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", padding:"20px 30px"}}>
                <div style={{font: "normal normal normal 28px/42px Bio Sans", color:`${currentParticipant._id == currentTopic?.moderator._id && currentConference.type != "Presentation"?"#050020":"#EEEEEE"}`}}>Welcome</div>
                <div style={{font: "normal normal 600 50px/73px Bio Sans", color:`${currentParticipant._id == currentTopic?.moderator._id && currentConference.type != "Presentation"?"#050020":"#EEEEEE"}`}}>{currentParticipant.name}</div>
                <div style={{font: "normal normal normal 28px/42px Bio Sans", color:"#726FA8"}}>{currentParticipant.entitle}</div>
            </div>
            { currentParticipant._id == currentTopic?.moderator._id && currentConference.type != "Presentation" && <div style={{position:"absolute", right:"10px", top:"10px", backgroundColor:"#FF3B00", color:"white", fontSize:"30px", padding:"5px 20px", display:"flex", flexDirection:"row", alignItems:"center", borderRadius:"10px"}}><i style={{font:"normal normal bold 30px/51px Bio Sans", marginRight:"10px"}} className="bi bi-circle-fill"></i> YOU ARE LIVE</div>}
        </div>
    );
}

export default ConfHeaderComponent;