import { useContext, useState, useRef } from 'react';


import styles from './HeaderConferenceDetailsComponent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { GlobalContext } from '../../../contexts/globalContext';


import New_KeyboardComponent from '../../keyboard/New_KeyboardComponent';
import New_InputTextComponent from '../../keyboard/New_InputTextComponent';


const HeaderConferenceDetailsComponent = ({  }) => {

    const { editConference, setEditConference } = useContext(ProjectDetailsContext);

    const { appWidth, appHeight, socketRef, currentUser, selectedProject, selectedTopicIdRef, setSelectedProject, setDetailsUpcomingConference, setTopicFiles } = useContext(GlobalContext);

    const editNameRef = useRef()
    const keyboardRef = useRef()
    const [clickEditName, setClickEditName] = useState()


    function handleEditName() {
        editNameRef.current.blur();
        if (editNameRef.current?.value !== editConference.name) {
            socketRef.current.emit("UPDATE_CONFERENCE", {projectId: selectedProject._id, conferenceId: editConference._id,  newData: { name: editNameRef.current?.value ? editNameRef.current?.value : "Default Title" } },
                (data) => {
                    let updatedName = { name: data.response.name ? data.response.name : editConference.name }
                    setEditConference(editConference => ({
                        ...editConference,
                        ...updatedName
                    }))
                }
            );
        }
        setClickEditName(false);
    }

    function handleCloseProject() {
        socketRef.current.emit("GET_PROJECT_BY_ID", {projectId: selectedProject._id, selectedTopicId: selectedTopicIdRef.current}, (project, conference, topicFiles) => {
            setSelectedProject(project)
            setDetailsUpcomingConference(conference)
            setTopicFiles(topicFiles)
            setEditConference()
            if (project.topics.length > 0 && !selectedTopicIdRef.current)
                selectedTopicIdRef.current = project.topics[0]._id;
            
        })
        
    }

    return (
        <div style={{ flexDirection: "row", display: "flex" }}>
            <New_KeyboardComponent onEnterClicked={()=> handleEditName() } ref={keyboardRef} x={(appWidth - 2140 - 60 - 240) / 2 - 180} y={appHeight * 0.68} width="2140px" height="610px" s="1" />
            <div style={{display: 'flex', flexDirection: 'row'}} onClick={handleCloseProject}>
                <FontAwesomeIcon className={styles.goBack} icon={byPrefixAndName.fal["arrow-left"]}/>
                <div className={styles.goBackText} >Back</div>
            </div>

            {!clickEditName && <div className={styles.editName} style={{width: 2800, justifyContent: 'center'}}>
                <div className={styles.titleCenterText} style={{position: 'relative'}} onClick={() => setClickEditName(true)}>{editConference.name}</div>
                {(currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") &&
                    <FontAwesomeIcon className={styles.editIconClicked} style={{ top: 7 }} icon={byPrefixAndName.fal['pen']} onClick={() => setClickEditName(true)} />}
            </div>}

            {clickEditName && <div className={styles.editName}>
                <div className={styles.editBackground} />
                <New_InputTextComponent fontSize={60} onBlur={()=> handleEditName() } width="1426px" ref={editNameRef} focused={true}  styleType='EDT' keyboardRef={keyboardRef} value={editConference.name}/>
            </div>}
        </div>
    );
}

export default HeaderConferenceDetailsComponent;