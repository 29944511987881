import { useRef, useContext, useState, useEffect } from 'react';
import styles from './ParticipantsToShareComponent.module.css'

import { GlobalContext } from "../../contexts/globalContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';
import DarkButtonComponent from '../commons/DarkButtonComponent';
import LightButtonComponent from '../commons/LightButtonComponent';


import AttachedParticipantsListComponent from './CS_AttachedParticipantsListComponent';
import DisplayRoomComponent from '../popup/conferenceManageParticipants/DisplayRoomComponent';

const ParticipantsToShareComponent = ({ setShowParticipantsToShare, ChangeModerator }) => {
    const firstRender = useRef(true)

    const [attachedParticipants, setAttachedParticipants] = useState([])
    const [allDisplayedDesks, setAllDisplayedDesks] = useState([])

    const [displayedRoom, setDisplayedRoom] = useState()
    const [deskHeight, setDeskHeight] = useState()

    const [allRooms, setAllRooms] = useState([])
    const [isRemote, setIsRemote] = useState(false)
    const [selectedParticipant, setSelectedParticipant] = useState(null)

    const { socketRef, currentConference } = useContext(GlobalContext);

    useEffect(() => {
        socketRef.current.emit('GET_CONFERENCE_ROOMS_BY_ID', currentConference.rooms, callback => {
            setAllRooms(callback)
            if (callback && callback.length > 0) {
                setDisplayedRoom(callback[0])

                var newDskHeight = 70;
                var mxx = Math.max(callback[0].desks_east?.length, callback[0].desks_west?.length);

                if (mxx > 0) {
                    var discac = Math.trunc(900 / (mxx + 1.5));

                    if (discac > 0) {
                        newDskHeight = discac;
                    }
                }

                setDeskHeight(newDskHeight);
            } else {
                setIsRemote(true)
            }
        })

        return () => {            
        }

    }, [])

    useEffect(() => {
        if (displayedRoom?._id && !isRemote && firstRender.current) {
            socketRef.current.emit("CONFERENCE_GET_ATTACHED_PARTICIPANTS_FROM_ROOM", {
                conferenceId: currentConference._id,
                roomId: displayedRoom._id
            },
                callback => {
                    if (!callback.error) {
                        setAttachedParticipants(callback.response)
                        setAllDisplayedDesks(getAllDesks(displayedRoom))
                    } 
                })
            firstRender.current = false
        }
    }, [displayedRoom])

    useEffect(() => {
        if (isRemote) {
            socketRef.current.emit("CONFERENCE_GET_REMOTE_PARTICIPANTS",
                currentConference._id,
                callback => {
                    if (!callback.error) {
                        setAttachedParticipants(callback.response)
                    }

                })
        }
    }, [isRemote])

    const getAllDesks = (room) => {
        const { desks_east, desks_north, desks_south, desks_west } = room;

        const allDesks = [
            ...desks_east.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_east',
            })),
            ...desks_north.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_north',
            })),
            ...desks_south.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_south',
            })),
            ...desks_west.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_west',
            })),
        ];

        allDesks.sort((a, b) => a.number - b.number);
        return allDesks;
    };

    const shareToParticipant = () => {
        if (selectedParticipant) {
            ChangeModerator(selectedParticipant);
        }
        
        setShowParticipantsToShare(false);
    }

    const closePage = () => {
        setShowParticipantsToShare(false);
    }

    const changeRooms = (room) => {
        setDisplayedRoom(room)
        setIsRemote(false)

        socketRef.current.emit("CONFERENCE_GET_ATTACHED_PARTICIPANTS_FROM_ROOM", {
            conferenceId: currentConference._id,
            roomId: room._id
        },
            callback => {
                if (!callback.error) {
                    setAttachedParticipants(callback.response)
                    setAllDisplayedDesks(getAllDesks(room))
                }
            })
    }

    return (
        <div className={styles.component} style={{ zIndex: GetMaxZIndex() + 1 }}>
            <div className={styles.rightBody}>
                <div className={styles.nrParticipants}>Participants </div>
                <AttachedParticipantsListComponent selectedParticipant={selectedParticipant} setSelectedParticipant={setSelectedParticipant} attachedParticipants={attachedParticipants} allDisplayedDesks={allDisplayedDesks} isRemote={isRemote} />

                <DisplayRoomComponent displayedRoom={displayedRoom} setDisplayedRoom={setDisplayedRoom} deskHeight={deskHeight} allRooms={allRooms}
                    isRemote={isRemote} setIsRemote={setIsRemote} changeRooms={changeRooms} />
            </div>

            <div style={{
                height: 80,
                borderRadius: 8,
                font: 'normal normal 600 40px/33px Bio Sans',
                position: "absolute",
                bottom: 30,
                left: "50%",
                transform: "translate(-50%,0)",
                display: 'flex',
                flexDirection: 'row'
            }}>

                {selectedParticipant && <LightButtonComponent
                    style={{
                        width: 432,
                        height: 82,
                        borderRadius: 8,
                        alignItems: 'center',
                        font: 'normal normal 600 40px/33px Bio Sans',
                        marginRight: 30                        
                    }} onClick={shareToParticipant}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['arrow-right']} />Save changes
                </LightButtonComponent>}

                <DarkButtonComponent
                    style={{
                        width: 353,
                    }}
                    onClick={closePage}
                >
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['xmark']} />Cancel
                </DarkButtonComponent>

            </div>
        </div>
    );

}

export default ParticipantsToShareComponent;