import { useState, useRef, createContext } from "react"

export const AddConferenceContext = createContext([{}, () => {}])

export const AddConferenceProvider = ( props ) => {
    const currentDate = new Date();
    const calendarRef = useRef();
    const initialHourEndRef = useRef()
    const initialHourStartRef = useRef()
    const selectedHourRef = useRef()
    const titleRef = useRef()
    const keyboardRef = useRef()
    const dataChanged = useRef(false)
    const hasIntersection = useRef()
   
    const [isConfNameOnFocus, setIsConfNameOnFocus] = useState(false)
    const [dayConferences, setDayConferences] = useState([])
    const [unsaved, setUnsaved] = useState(false)
    const [save, setSave] = useState(false)
    const [dataChangedLocal, setDataChangedLocal] = useState(false)
    const [selectedRooms, setSelectedRooms] = useState([])

    const [conferenceDetailsForParticipants, setConferenceDetailsForParticipants] = useState()

    const [addNewTopic, setAddNewTopic] = useState(false)
    const [addNewSubtopic, setAddNewSubtopic] = useState(false)
    const [selectedTopic, setSelectedTopic] = useState()
    const [selectedSubtopic, setSelectedSubtopic] = useState()

    const [seeDateTime, setSeeDateTime] = useState(false)
    const [openFiles, setOpenFiles] = useState(false)
    const [conferenceAddFiles, setConferenceAddFiles] = useState(false)
    const [openParticipants, setOpenParticipants] = useState(false)

    const [addModerator, setAddModerator] = useState(false)
    const [deleteClicked, setDeleteClicked] = useState(false)
    const [selectedModerator, setSelectedModerator] = useState()

    const [fileList, setFileList] = useState([])
    const [addVisitor, setAddVisitor] = useState(false)

    const [uniqueModerators, setUniqueModerators ] = useState([])
    const [moreMenuTimer, setMoreMenuTimer] = useState(false)
    const [moreClicked, setMoreClicked] = useState(false)

    return (
        <AddConferenceContext.Provider value={{
            currentDate,
            calendarRef,
            initialHourEndRef,
            initialHourStartRef,
            selectedHourRef,
            titleRef,
            keyboardRef,
            dataChanged,
            hasIntersection,
            isConfNameOnFocus, setIsConfNameOnFocus,
            dayConferences, setDayConferences,
            unsaved, setUnsaved,
            save, setSave,
            dataChangedLocal, setDataChangedLocal,
            selectedRooms, setSelectedRooms,
            conferenceDetailsForParticipants, setConferenceDetailsForParticipants, 
            addNewTopic, setAddNewTopic,
            addNewSubtopic, setAddNewSubtopic,
            selectedTopic, setSelectedTopic,
            seeDateTime, setSeeDateTime,
            openFiles, setOpenFiles,
            conferenceAddFiles, setConferenceAddFiles,
            openParticipants, setOpenParticipants,
            selectedSubtopic, setSelectedSubtopic,
            addModerator, setAddModerator,
            selectedModerator, setSelectedModerator,
            fileList, setFileList,
            addVisitor, setAddVisitor,
            uniqueModerators, setUniqueModerators,
            deleteClicked, setDeleteClicked,
            moreMenuTimer, setMoreMenuTimer,
            moreClicked, setMoreClicked
        }}>
            {props.children}
        </AddConferenceContext.Provider>
    )
}