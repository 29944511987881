import { useContext, useEffect, useRef, useState } from 'react';
import styles from './PD_TopicComponent.module.css';
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import DetailCountComponent from '../../projectList/DetailCountComponent';
import DeleteNotificationComponent from '../../popup/common/DeleteNotificationComponent';

const PD_TopicComponent = ({ topic }) => {
    const deleteRef = useRef();


    const [isDelete, setIsDelete] = useState(false);
    const [totalFiles, setTotalFiles] = useState(false);

    const {
        currentUser,
        selectedTopic,
        setSelectedTopic,
        selectedTopicIdRef,
        socketRef,
        selectedProject,
        setTopicFiles,
        selectedParentForFiles,
        setSelectedParentForFiles,
        setSelectedRefTypeForFiles,
        topicFiles
    } = useContext(GlobalContext);

    const {
        extended,
        setExtended,
        setEditTopic,
        setMoreClicked,
        setDeleteClicked
    } = useContext(ProjectDetailsContext);

    useEffect(() => {
        let totalFilesNo = topic.files.length
        for (let i = 0; i < topic.subtopics.length; i++) {
            console.log('tut', topic.subtopics[i])
            totalFilesNo += topic.subtopics[i]?.files.length
        }
        setTotalFiles(totalFilesNo)
    }, [topic])

    const handleDeleteTopic = (e) => {
        e.stopPropagation();
        const rect = deleteRef.current.getBoundingClientRect(); // Get element position dynamically
        setDeleteClicked({
            shown: true,
            funct: confirmDeleteTopic,
            name: "Folder", // Provide any additional data needed
            x: rect.x,
            y: rect.y,
            onClose: () => setDeleteClicked(), // Provide a default close handler
        });
    };


    // Deletes the topic when confirmed in the DeleteNotificationComponent
    const confirmDeleteTopic = () => {
        socketRef.current.emit("PROJECT_DELETE_FOLDER", topic._id, selectedProject._id, () => {
            setMoreClicked({}); // Reset the moreClicked state
            setDeleteClicked() // Closes the delete dialog
        });
    };

    // Selects a topic to view its attached files
    const selectTopicForViewFiles = (e, parent, refType) => {
        e.stopPropagation();

        selectedTopicIdRef.current = parent._id;
        setSelectedParentForFiles(parent);
        setSelectedRefTypeForFiles(refType);

        setSelectedTopic(topic._id);

        socketRef.current.emit("PROJECT_GET_FOLDER_ATTACHED_DOCUMENTS", { parentId: parent._id }, (docs) => {
            setTopicFiles(docs);
        });
    };

    const handleClickExtention = (id) => {
        if (extended === id) {
            setExtended(null);
        } else {
            setExtended(id);
        }
    };

    const handleEditTopic = (e) => {
        e.stopPropagation();
        setEditTopic(topic);
    };

    return (
        <div
            className={styles.element}
            onClick={(e) => {
                handleClickExtention(topic._id);
                selectTopicForViewFiles(e, topic, 'Topic');
            }}
            style={{
                paddingBottom: extended === topic._id && topic.subtopics.length > 0 ?  10 : 50,
                background: selectedTopic === topic._id || selectedParentForFiles?._id === topic._id ? "#231A3E 0% 0% no-repeat padding-box" : ""
            }}>
            <div className={styles.notExtended}>
                <div
                    className={styles.scrollTopicName}
                    style={{ color: selectedParentForFiles?._id === topic._id ? "#33FFC8" : "", maxWidth: 830, overflow: "scroll" }}
                    onClick={(e) => { selectTopicForViewFiles(e, topic, 'Topic'); handleClickExtention(topic._id) }}>

                    {topic.name}
                </div>
                {selectedParentForFiles?._id === topic._id && <>
                    <FontAwesomeIcon
                        className={styles.iconButton}
                        style={{ right: currentUser?.role.toLowerCase() === "administrator" ? 140 : 60 }}
                        icon={byPrefixAndName.fal['pen']}
                        onClick={(e) => handleEditTopic(e)}
                    />
                    {currentUser?.role.toLowerCase() === "administrator" &&
                        <FontAwesomeIcon
                            ref={deleteRef}
                            className={styles.iconButton}
                            style={{ right: 60 }}
                            icon={byPrefixAndName.fal['trash']}
                            onClick={(e) => handleDeleteTopic(e)} // Triggers the delete confirmation dialog
                        />}
                </>}
                <FontAwesomeIcon
                    className={styles.iconButton}
                    style={{ color: selectedParentForFiles?._id === topic._id ? "#33FFC8" : "" }}
                    icon={byPrefixAndName.fas['chevron-right']}
                    onClick={(e) => selectTopicForViewFiles(e, topic, 'Topic')}
                />
                <div style={{ display: "flex", flexDirection: "row", marginLeft: -10, marginTop: 10, color: "#CDCCD2" }}>
                    <DetailCountComponent
                        project={{ nrSubfolders: topic.subtopics.length }}
                        type={"nrSubfolders"}
                        style={{ font: "normal normal normal 28px/42px 'Bio Sans'" }}
                    />
                    <DetailCountComponent
                        project={{ nrFiles: totalFiles }}
                        type={"nrFiles"}
                        style={{ font: "normal normal normal 28px/42px 'Bio Sans'" }}
                    />
                </div>
            </div>
            {extended === topic._id && topic.subtopics.length > 0 && <div className={styles.extended}>
                <div className={styles.subtopics}>
                    {/* <div className={styles.subtopicHeader}>
                        Subfolders */}
                        {/* Add Subfolder button (commented out for future use) */}
                        {/* <div className={styles.addSubtopic}>
                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['plus']} />Add subfolders
                        </div> */}
                    {/* </div> */}
                    {topic.subtopics.length > 0 && topic.subtopics.map((subtopic) =>
                        <div
                            key={subtopic._id}
                            className={styles.subtopic}
                            onClick={(e) => selectTopicForViewFiles(e, subtopic, 'Subtopic')}>
                            {/* Icon for opening subfolders */}
                            <FontAwesomeIcon
                                className={styles.iconOpenFilesSubtopic}
                                style={{ color: selectedParentForFiles?._id === subtopic._id ? "#33FFC8" : "" }}
                                icon={byPrefixAndName.fas['chevron-right']}
                            />
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <FontAwesomeIcon
                                    className={styles.iconSubtopic}
                                    style={{ color: selectedParentForFiles?._id === subtopic._id ? "#33FFC8" : "" }}
                                    icon={byPrefixAndName.fal['folder']}
                                />
                                <div className={styles.subtopicName} style={{ color: selectedParentForFiles?._id === subtopic._id ? "#33FFC8" : "" }}>{subtopic.name}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>}
            {/* Delete Notification Component */}
            {isDelete &&
                <DeleteNotificationComponent
                    name={"Topic"}
                    type={'participant'}
                    funct={confirmDeleteTopic} // Deletes the topic when confirmed
                    onClose={() => setIsDelete(false)} // Closes the delete dialog
                />}
        </div>
    );
};

export default PD_TopicComponent;
