import { useRef, useEffect, useContext } from 'react';

import { GlobalContext } from "./contexts/globalContext";

import styles from './app.module.css';
import LoginComponent from './components/LoginComponent';
import ProjectsListComponent from './components/ProjectsListComponent';
import { jwtDecode } from 'jwt-decode';
import RegisterDeviceComponent from './components/registerDevice/RegisterDeviceComponent';
import SocketManager from './utils/SocketManager';

import ConferenceScreenComponent from "./components/conferenceScreen/ConferenceScreenComponent"

import AlertComponent from "./components/commons/AlertComponent";
import io from "socket.io-client";

function App() {

    const windowRef = useRef();
    const { setAlertMessage, socketRef, localSocketRef, roomDataForRegister, setRoomDataForRegister, setDeskData, setDeviceId, currentUser, setGuestLogin, setUserLogin, appHeight, setAppHeight, appWidth, setAppWidth, currentConference } = useContext(GlobalContext);

    useEffect(() => {
        document.documentElement.style.setProperty('--overflowY', "hidden");

        //Calculate app heigt and width
        var ratioApp = process.env.REACT_APP_WIDTH / process.env.REACT_APP_HEIGHT;
        var ratioWindow = window.innerWidth / window.innerHeight;
        var newAppWidth = process.env.REACT_APP_WIDTH;
        var newAppHeight = process.env.REACT_APP_HEIGHT;

        if (ratioApp > ratioWindow) {
            var ratio = window.innerHeight * process.env.REACT_APP_WIDTH / (window.innerWidth * process.env.REACT_APP_HEIGHT);
            newAppHeight = process.env.REACT_APP_HEIGHT * ratio;
        } else {
            var ratio = window.innerWidth * process.env.REACT_APP_HEIGHT / (window.innerHeight * process.env.REACT_APP_WIDTH);
            newAppWidth = process.env.REACT_APP_WIDTH * ratio;
        }

        setAppHeight(newAppHeight);
        setAppWidth(newAppWidth);

        //End- Calculate app heigt and width

        // const handler = e => e.preventDefault()
        // const handlerDrop = e => {
        //     e.preventDefault()
        //     e.dataTransfer.effectAllowed = "none";
        //     e.dataTransfer.dropEffect = "none";
        // }

        // document.addEventListener('gesturestart', handler)
        // document.addEventListener('gesturechange', handler)
        // document.addEventListener('gestureend', handler)
        // window.addEventListener('dragover', handlerDrop)
        // window.addEventListener('drop', handlerDrop)
        // window.addEventListener('dragenter', handlerDrop)

        windowRef.current.style.transformOrigin = 'top left';
        windowRef.current.style.transform = `scale(${window.innerWidth / newAppWidth}, ${window.innerHeight / newAppHeight})`;


        const urlParams = new URLSearchParams(window.location.search);

        const token = urlParams.get('token')
        const deviceIdParam = urlParams.get('deviceId')

        if (token) {
            let tokenData = getUserDataFromToken(token)
            if (tokenData.role === "GUEST") {
                setGuestLogin(tokenData)
            } else {
                setUserLogin(tokenData)
            }
        }

        if (deviceIdParam) {
            setDeviceId(deviceIdParam)

            console.log("GET_REGISTER_STATUS 1");
            socketRef.current.emit("GET_REGISTER_STATUS", deviceIdParam, (data) => {
                console.log("GET_REGISTER_STATUS 2");
                if (data.error) {
                    setAlertMessage({ type: "ERROR", message: data.message });
                } else {
                    if (data.registered) {
                        setDeskData(data.desk);
                        localSocketRef.current = io.connect("http://localhost:3800", {
                            secure: true,
                            rejectUnauthorized: true,
                            reconnection: false
                        })

                        localSocketRef.current.on('connect', function () {
                            localSocketRef.current?.emit("lower_panel");
                        });
                    } else {
                        setRoomDataForRegister(data.room)
                    }
                }
            });
        }
    }, [])

    const getUserDataFromToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const { id, name, email, role } = decodedToken;
            return { id, name, email, role }
        } catch (error) {
            console.error('Invalid token', error);
            return null;
        }
    };

    return (
        <div ref={windowRef} className={styles.app_body} style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>
            <SocketManager />

            {!currentUser && !roomDataForRegister && !currentConference && <LoginComponent />}
            {currentUser && <ProjectsListComponent />}
            {roomDataForRegister && <RegisterDeviceComponent />}

            {currentConference && <ConferenceScreenComponent />}

            <AlertComponent />
        </div>
    );
}

export default App;
