import { useRef, useContext, useState, useEffect, createRef } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import styles from './AddTopicsComponent.module.css'
import uuid from 'react-uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'

import topicsAdd from '../../graphics/Popups/Icon Folders Add.png'
import LightButtonComponent from '../commons/LightButtonComponent';
import LeftBodyComponent from './common/LeftBodyComponent';
import RightBodyComponent from './common/RightBodyComponent';
import FooterButtonsComponent from './common/FooterButtonsComponent';

import New_InputTextComponent from '../keyboard/New_InputTextComponent';
import New_KeyboardComponent from '../keyboard/New_KeyboardComponent';


const AddTopicsComponent = () => {

    const nameRef = useRef()
    const keyboardRef = useRef()
    const subtopicRef = useRef()
    const [topicIsOnFocus, setTopicIsOnFocus] = useState(false)
    const [indexSubtopic, setIndexSubtopic] = useState(-1)
    const [subtopicIsOnFocus, setSubtopicIsOnFocus] = useState(false)
    const [subtopicMapIsOnFocus, setSubtopicMapIsOnFocus] = useState(false)
    const [showAddSubtopic, setShowAddSubtopic] = useState(false)
    const [showAddButton, setShowAddButton] = useState(false)
    
    const { language, appHeight, setTopicFiles, setSelectedTopic, setSelectedRefTypeForFiles, setSelectedParentForFiles, selectedTopicIdRef,
        selectedProject, setSelectedProject, socketRef, appWidth } = useContext(GlobalContext);
    const { setAddTopic, editTopic, setEditTopic } = useContext(ProjectDetailsContext);

    const [currentSubtopicList, setCurrentSubtopicList] = useState(editTopic?.subtopics || []);


    const fileRefs = useRef(currentSubtopicList.map(() => createRef()));


    function handleTopicAdd() {
        let subtopicsList = []
        subtopicsList = currentSubtopicList.map(subtopic => subtopic.name)
        socketRef.current.emit("PROJECT_UPDATE_FOLDER",
            {
                projectId: selectedProject._id,
                topicId: editTopic ? editTopic._id : null,
                newData: {
                    name: nameRef.current?.value ? nameRef.current?.value : "Default Title",
                    subtopics: currentSubtopicList
                }
            }, (callback) => {
                if (!callback.error) {
                    const id = callback.response

                    selectedTopicIdRef.current = id;
                    setSelectedParentForFiles({ _id: id, name: nameRef.current?.value ? nameRef.current?.value : "Default Title" });
                    setSelectedRefTypeForFiles("Topic");
                    setSelectedTopic(id)

                    socketRef.current.emit("PROJECT_GET_FOLDER_ATTACHED_DOCUMENTS", { parentId: id }, (docs) => {
                        setTopicFiles(docs);
                        setAddTopic()
                        setEditTopic()
                    });
                }
            }
        );
    }

    function handleSubtopicAdd() {
        setCurrentSubtopicList((prev) => [...prev, { name: subtopicRef.current?.value || "Default Title", _id: uuid(), isNew: true }]);
        fileRefs.current.push(createRef());
        subtopicRef.current.value = "";
    }

    // function  handleDeleteSubtopic(id) {
    //     if (editTopic) {
    //         socketRef.current.emit("PROJECT_DELETE_SUBFOLDER", id, editTopic._id, selectedProject._id)
    //     }
    //     let subtopicsList = currentSubtopicList
    //     let filteredSubtopicsList = subtopicsList.filter((subtopic) => subtopic._id !== id);
    //     setCurrentSubtopicList(filteredSubtopicsList)
    // }

    function handleDeleteSubtopic(subtopic) {
        if (editTopic && !subtopic.isNew) {
            socketRef.current.emit(
                "PROJECT_DELETE_SUBFOLDER",
                { subtopicId: subtopic._id, topicId: editTopic._id, projectId: selectedProject._id },
                (callback) => {
                    if (!callback.error) {
                        setSelectedProject(prev => ({
                            ...prev,
                            topics: prev.topics.map(topic => topic._id === editTopic._id ? callback.response : topic)
                        }));                        
                        setTopicFiles(callback.response);
                    }
                }
            );
        }
    
        // Optimize state update
        setCurrentSubtopicList((prev) => prev.filter(st => st._id !== subtopic._id));
    }
    
    useEffect(() => {
        if (indexSubtopic !== -1 && !subtopicMapIsOnFocus) {
            setCurrentSubtopicList((prev) => prev.map((subtopic, i) =>
                i === indexSubtopic ? { ...subtopic, name: fileRefs.current[i].current?.value || "Default Title" } : subtopic
            ));
        }
    }, [subtopicMapIsOnFocus, indexSubtopic]);

    useEffect(() => {
        if (editTopic) {
            setShowAddSubtopic(true);
        }
    }, [])

    function closePopup() {
        setAddTopic()
        setEditTopic()
    }

    const topicNameSave_handler = () => {
        if (nameRef.current?.value) {
            setShowAddSubtopic(true);
        } else {
            setShowAddSubtopic(false);
            setShowAddButton(false);
        }
    }

    const subtopicNameSave_handler = () => {
        if (subtopicRef.current?.value) {
            setShowAddButton(true);
        } else {
            setShowAddButton(false);
        }
    }

    return (
        <PopupBaseComponent>
            <New_KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.66} width="2140px" height="610px" s="1" />
            <PopupBodyComponent style={{ width: 2760, height: 1040 }}>
                <ClosePageButtonComponent setClose={closePopup} />
                <LeftBodyComponent image={topicsAdd} title="New folder and subfolders" description="Enter a folder name to organize your files. You can also add multiple optional subfolders at any time to build or expand your file structure as needed." />
                <RightBodyComponent>
                    <div className={styles.body_header} >
                        Folder Name
                        {/* {editTopic && 
                            <ButtonComponent text={language.add_topics.Delete} style={{height: 75, width: 300}} onClick={() => deleteTopic()}/>
                        } */}
                    </div>

                    <New_InputTextComponent ref={nameRef} height="80px" keyboardRef={keyboardRef} value={editTopic ? editTopic.name : ""} placeHolder="Enter topic name here" styleType='POPUP' fontSize="40px" width="1600px"
                        iconPosition="right" icon={byPrefixAndName.fas["pen"]} withDelete={true} onChange={() => topicNameSave_handler()}
                    />
                    <div className={styles.subtopics}>
                        <div className={styles.subtopicsHeader}>
                            Subfolders
                            <div className={styles.subtopicsOptional}>(Optional)</div>
                        </div>

                        <div className={styles.subtopics_map}>

                            {currentSubtopicList.length > 0 && currentSubtopicList.map((subtopic, index) =>
                                <div key={index} className={styles.subtopicsAdd}>
                                    <New_InputTextComponent ref={fileRefs.current[index]} height="80px" keyboardRef={keyboardRef}
                                        value={subtopic.name} placeHolder="Enter subtopic name here" styleType={'POPUP'}
                                        fontSize="40px" width={indexSubtopic === index && subtopicMapIsOnFocus ? "921px" : "1560px"} />
                                    <LightButtonComponent onClick={() => handleDeleteSubtopic(subtopic)} style={{ width: 300 }}>
                                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['trash']} />
                                        Delete
                                    </LightButtonComponent>

                                </div>)}
                            <div className={styles.subtopicsAdd}>

                                {showAddSubtopic &&
                                    <>
                                        <New_InputTextComponent ref={subtopicRef} keyboardRef={keyboardRef} onChange={() => subtopicNameSave_handler()} height="80px" value="" placeHolder="Enter subtopic name here" styleType={'POPUP'} fontSize="40px"
                                            width={subtopicIsOnFocus ? "921px" : "1560px"}
                                            iconPosition="right" icon={byPrefixAndName.fas["pen"]} withDelete={true}
                                        />
                                        {showAddButton &&
                                            <LightButtonComponent onClick={handleSubtopicAdd} style={{ width: 300 }}>
                                                Add
                                            </LightButtonComponent>
                                        }
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                    <FooterButtonsComponent
                        darkClick={closePopup}
                        lightText={editTopic ? "Update" : "Create"}
                        lightClick={(nameRef.current?.value === "" || !nameRef.current?.value) && !topicIsOnFocus ? () => { } : handleTopicAdd}
                        isOpaque={(nameRef.current?.value === "" || !nameRef.current?.value) && !topicIsOnFocus}
                    />

                </RightBodyComponent>
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default AddTopicsComponent;