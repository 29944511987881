import { useContext, useEffect } from 'react';
import styles from './NewDatePickerComponent.module.css'

import { generateDateList, compareDate } from '../../../utils/UtilitaryFunctions';

import highlight from '../../../graphics/conference add/highlight-data.svg'

import { GlobalContext } from '../../../contexts/globalContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';


const NewDatePickerComponent = ({selectedDate, setSelectedDate}) => {

    const currentDate = new Date();

    const {appWidth} = useContext(GlobalContext)
    const {calendarRef} = useContext(AddConferenceContext)

    const dayWidth = 148.8

    const dayOffset = parseInt(appWidth / dayWidth / 2) // 148.8 este latimea unei casute de data
    const margin = appWidth / 2 % dayWidth - dayWidth / 2 

    const dateList = generateDateList(currentDate, dayOffset);

    useEffect(() => {
        calendarRef.current.style.marginLeft = `${margin}px`

        const selectedDateStartOfDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        const currentDateStartOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

        const diffInMilliseconds = Math.abs(selectedDateStartOfDay.getTime() - currentDateStartOfDay.getTime())
        
        calendarRef.current.scrollLeft = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) * dayWidth
    }, [])


    const handleScrollDay = () => {

        if (calendarRef.current) {
            const calendarDays = calendarRef.current.children;

            for (let i = 0; i < calendarDays.length; i++) {
                const rect = calendarDays[i].getBoundingClientRect();

                if (rect.left >= -15) {
                    const date = new Date(currentDate);
                    date.setDate(currentDate.getDate() + i);

                    const d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                    const d2 = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());

                    if (d1.getTime() !== d2.getTime()){
                        setSelectedDate(date)
                    } 

                    break;
                }
            }
        }
    };

    function handleClickDay(day){
        let clickedDate = day.date
        setSelectedDate(clickedDate)
        positionScroll(clickedDate)
    }

    function positionScroll(date){
        const selectedDateStartOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const currentDateStartOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

        const diffInMilliseconds = Math.abs(selectedDateStartOfDay.getTime() - currentDateStartOfDay.getTime())
        
        calendarRef.current.scrollLeft = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) * dayWidth
    }


    return (
        <div style={{position: "relative"}}>
            <div className={styles.selectedCalendarDay} style={{height: 265, overflow: "hidden"}}>
                <img src={highlight} className={styles.selectedCalendarDay} style={{top: 40}}/>
            </div>

            {selectedDate !== undefined && <div className={styles.calendar} ref={calendarRef} onScroll={handleScrollDay}>
                {dateList.map((item, index) => (
                    <div key={index} className={styles.calendarDay} onClick={() => handleClickDay(item)} style={{ color: compareDate( selectedDate, item.date) ? "#050020" : "" }}>
                        <div className={styles.calendarMonth}>{item.dayNumber === "01" ? item.month : ""}</div>
                        <div className={styles.calendarDayNumber}>{item.dayNumber}</div>
                        <div className={styles.calendarDayOfWeek}>{item.dayOfWeek}</div>
                    </div>
                ))}
            </div> }
        </div>
    );

}

export default NewDatePickerComponent;