import { useContext, useState, useRef, useEffect } from 'react';
import { GlobalConfigContext } from "../../contexts/globalConfigContext";

import CornerImage from "../../graphics/config/colt.png"
import DeskHImage from "../../graphics/config/masa-h.png"
import DeskVImage from "../../graphics/config/masa-v.png"
import LegImage from "../../graphics/config/picior.png"

import "./pages.css";

const CFG_RoomsPage = () => {
    const { configSocketRef, setCurrentError } = useContext(GlobalConfigContext);

    const [entities, setEntities] = useState([])
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectedRoomToEdit, setSelectedRoomToEdit] = useState(null);
    const entityName = "Room";

    const inputNameRef = useRef();
    const inputLocationRef = useRef();

    const inputEditNameRef = useRef();
    const inputEditLocationRef = useRef();

    const [mainDoorPosition, setMainDoorPosition] = useState("S");

    const [northValue, setNorthValue] = useState(0);
    const [westValue, setWestValue] = useState(0);
    const [eastValue, setEastValue] = useState(0);
    const [southValue, setSouthValue] = useState(0);

    const [northDesks, setNorthDesks] = useState([]);
    const [southDesks, setSouthDesks] = useState([]);
    const [eastDesks, setEastDesks] = useState([]);
    const [westDesks, setWestDesks] = useState([]);

    const [deskHeight, setDeskHeight] = useState(80);
    const [deskWidth, setDeskWidth] = useState(80);

    const [isRoomConfigurationEditable, setIsRoomConfigurationEditable] = useState(true);

    const selectedEntityRef = useRef();

    useEffect(() => {
        configSocketRef.current.emit("CONFIG_ALL_LOCATIONS", (locations) => {
            setEntities(locations)
        });

        configSocketRef.current.on('CONFIG_REFRESH_ROOM', async (room) => {
            if (room._id == selectedEntityRef.current._id) {
                setSelectedRoomToEdit(room);
            }
        })

        return () => (
            configSocketRef.current.off('CONFIG_REFRESH_ROOM')
        )
    }, []);

    useEffect(() => {
        if (selectedRoomToEdit) {
            if (selectedRoomToEdit.edges) {
                setNorthValue(selectedRoomToEdit.edges[0]);
                setEastValue(selectedRoomToEdit.edges[1]);
                setSouthValue(selectedRoomToEdit.edges[2]);
                setWestValue(selectedRoomToEdit.edges[3]);
            }
            setMainDoorPosition(selectedRoomToEdit.doorPosition);

            if (selectedRoomToEdit.registeredDesks > 0) {
                setIsRoomConfigurationEditable(false);
                setNorthDesks(selectedRoomToEdit.desks_north);
                setEastDesks(selectedRoomToEdit.desks_east);
                setSouthDesks(selectedRoomToEdit.desks_south);
                setWestDesks(selectedRoomToEdit.desks_west);
            } else {
                setIsRoomConfigurationEditable(true);
            }

            if (selectedRoomToEdit.totalDesks > 0) {
                setNorthDesks(selectedRoomToEdit.desks_north);
                setEastDesks(selectedRoomToEdit.desks_east);
                setSouthDesks(selectedRoomToEdit.desks_south);
                setWestDesks(selectedRoomToEdit.desks_west);
            }
        }
    }, [selectedRoomToEdit]);

    useEffect(() => {
        selectedEntityRef.current = selectedEntity;
        if (selectedEntity) {
            inputEditNameRef.current.value = selectedEntity.name;
            inputEditLocationRef.current.value = selectedEntity.location;

            configSocketRef.current.emit("CONFIG_GET_ROOM", selectedEntity._id, (room) => {
                setSelectedRoomToEdit(room);
            });

        } else {
            inputNameRef.current.value = null;
            inputLocationRef.current.value = "";
        }
    }, [selectedEntity]);


    const unregisterDevice = (deskId) => {
        //console.log("unreg", deskId);
        configSocketRef.current.emit("CONFIG_UNREGISTER_DEVICE", deskId, (room) => {
            //console.log(room);
            setSelectedRoomToEdit(room);
        });
    }

    const addEntity = () => {
        //console.log("CONFIG_ADD_ROOM");
        //console.log({ name: inputNameRef.current.value, locationId: inputLocationRef.current.value });
        if (inputNameRef.current.value && inputLocationRef.current.value) {
            configSocketRef.current.emit("CONFIG_ADD_ROOM", { name: inputNameRef.current.value, locationId: inputLocationRef.current.value }, (locations) => {
                setEntities(locations)

                inputNameRef.current.value = null;
                inputLocationRef.current.value = "";
            });
        }

        setSelectedEntity(null);
    }

    const updateEntity = () => {
        //console.log("CONFIG_UPDATE_ROOM");

        if (inputEditNameRef.current.value && inputEditLocationRef.current.value) {
            var edges = [northValue, eastValue, southValue, westValue];
            //var doorPosition = mainDoorNorthPositionRef.current.checked ? "N" : mainDoorSouthPositionRef.current.checked ? "S" : mainDoorEastPositionRef.current.checked ? "E" : mainDoorWestPositionRef.current.checked ? "W" : "S";
            //console.log({ id: selectedEntity?._id, name: inputEditNameRef.current.value, locationId: inputEditLocationRef.current.value, edges, doorPosition: mainDoorPosition });
            configSocketRef.current.emit("CONFIG_UPDATE_ROOM", {
                id: selectedEntity?._id, name: inputEditNameRef.current.value, locationId: inputEditLocationRef.current.value, edges,
                doorPosition: mainDoorPosition, desks_north: northDesks, desks_south: southDesks, desks_east: eastDesks, desks_west: westDesks, isRoomConfigurationEditable
            }, (locations) => {
                setEntities(locations)

                inputEditNameRef.current.value = null;
                inputEditLocationRef.current.value = "";
            });
        }

        setSelectedEntity(null);
    }

    const deleteEntity = () => {
        document.getElementsByClassName("modal-backdrop")[0].classList.remove("show");
        document.getElementById("deleteEntityModal").style.display = "none";
        document.getElementById("deleteEntityModal").classList.remove("show");


        configSocketRef.current.emit("CONFIG_DELETE_ROOM", selectedEntity._id, (rooms) => {
            if (typeof rooms === 'string') {
                setCurrentError(rooms);
            } else {
                setEntities(rooms);
            }
        });
        setSelectedEntity(null);
    }

    useEffect(() => {

        var newDskHeight = 70;
            var mxx = Math.max(eastValue, westValue);

            if (mxx > 0) {
                var discac = Math.trunc(450 / (mxx + 1.5));

                if (discac > 0) {
                    newDskHeight = discac;
                }
            }

            setDeskHeight(newDskHeight);
            setDeskWidth(newDskHeight * 1.2);

        if (!selectedRoomToEdit || selectedRoomToEdit.registeredDesks == 0) {

            var m_southDesks = [];
            var m_northDesks = [];
            var m_eastDesks = [];
            var m_westDesks = [];

            if (mainDoorPosition == "S") {
                var currenIndex = 0;

                for (let index = currenIndex; index < currenIndex + southValue; index++) {
                    m_southDesks.push({ number: index + 1 });
                }
                currenIndex += southValue

                for (let index = currenIndex + eastValue; index > currenIndex; index--) {
                    m_eastDesks.push({ number: index });
                }
                currenIndex += eastValue

                for (let index = currenIndex + northValue; index > currenIndex; index--) {
                    m_northDesks.push({ number: index });
                }
                currenIndex += northValue

                for (let index = currenIndex; index < currenIndex + westValue; index++) {
                    m_westDesks.push({ number: index + 1 });
                }
                currenIndex += westValue
            } else if (mainDoorPosition == "E") {
                var currenIndex = 0;

                for (let index = currenIndex + eastValue; index > currenIndex; index--) {
                    m_eastDesks.push({ number: index });
                }
                currenIndex += eastValue

                for (let index = currenIndex + northValue; index > currenIndex; index--) {
                    m_northDesks.push({ number: index });
                }
                currenIndex += northValue

                for (let index = currenIndex; index < currenIndex + westValue; index++) {
                    m_westDesks.push({ number: index + 1 });
                }
                currenIndex += westValue

                for (let index = currenIndex; index < currenIndex + southValue; index++) {
                    m_southDesks.push({ number: index + 1 });
                }
                currenIndex += southValue

            } else if (mainDoorPosition == "N") {
                var currenIndex = 0;




                for (let index = currenIndex + northValue; index > currenIndex; index--) {
                    m_northDesks.push({ number: index });
                }
                currenIndex += northValue


                for (let index = currenIndex; index < currenIndex + westValue; index++) {
                    m_westDesks.push({ number: index + 1 });
                }
                currenIndex += westValue


                for (let index = currenIndex; index < currenIndex + southValue; index++) {
                    m_southDesks.push({ number: index + 1 });
                }
                currenIndex += southValue


                for (let index = currenIndex + eastValue; index > currenIndex; index--) {
                    m_eastDesks.push({ number: index });
                }
                currenIndex += eastValue

            } else if (mainDoorPosition == "W") {
                var currenIndex = 0;

                for (let index = currenIndex; index < currenIndex + westValue; index++) {
                    m_westDesks.push({ number: index + 1 });
                }
                currenIndex += westValue


                for (let index = currenIndex; index < currenIndex + southValue; index++) {
                    m_southDesks.push({ number: index + 1 });
                }
                currenIndex += southValue


                for (let index = currenIndex + eastValue; index > currenIndex; index--) {
                    m_eastDesks.push({ number: index });
                }
                currenIndex += eastValue


                for (let index = currenIndex + northValue; index > currenIndex; index--) {
                    m_northDesks.push({ number: index });
                }
                currenIndex += northValue

            }

            setNorthDesks(m_northDesks);
            setSouthDesks(m_southDesks);
            setEastDesks(m_eastDesks);
            setWestDesks(m_westDesks);
        }
    }, [southValue, northValue, eastValue, westValue, mainDoorPosition]);

    const northValueChanged = (ev) => {
        var targetValue = Number(ev.target.value);
        setNorthValue(targetValue);
        if (southValue != 0 && targetValue != 0) {
            setNorthValue(targetValue);
            setSouthValue(targetValue);
        }
    }

    const southValueChanged = (ev) => {
        var targetValue = Number(ev.target.value);
        setSouthValue(targetValue);
        if (northValue != 0 && targetValue != 0) {
            setNorthValue(targetValue);
            setSouthValue(targetValue);
        }
    }

    const eastValueChanged = (ev) => {
        var targetValue = Number(ev.target.value);
        setEastValue(targetValue);
        if (westValue != 0 && targetValue != 0) {
            setEastValue(targetValue);
            setWestValue(targetValue);
        }
    }

    const westValueChanged = (ev) => {
        var targetValue = Number(ev.target.value);
        setWestValue(targetValue);
        if (eastValue != 0 && targetValue != 0) {
            setEastValue(targetValue);
            setWestValue(targetValue);
        }
    }

    return (
        <>
            <div className="pagetitle">
                <h1>{entityName}s</h1>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">

                                <div className="accordion" id="accordionExample">
                                    {entities.map((entity) => (


                                        <div className="accordion-item" key={entity._id}>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + entity.name} aria-expanded="true" aria-controls="collapseOne">
                                                    {entity.name}
                                                </button>
                                            </h2>
                                            <div id={"collapseOne" + entity.name} className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    {entity.rooms.length == 0 && <span>No rooms defined.</span>}
                                                    {entity.rooms.length != 0 && <table className="table datatable" id="MyTable">
                                                        <thead>
                                                            <tr>
                                                                <th>{entityName} Name</th>
                                                                <th>Total desks</th>
                                                                <th>Registered desks</th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {entity.rooms.map((room) => (
                                                                <tr key={room._id} className='trx'>
                                                                    <td>{room.name}</td>
                                                                    <td>{room.totalDesks}</td>
                                                                    <td>{room.registeredDesks}</td>
                                                                    <td style={{ width: "40px" }}>
                                                                        <a id="editButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#editEntityModal" onClick={() => setSelectedEntity(room)}><i className="bi bi-vector-pen"></i></a>
                                                                    </td>
                                                                    <td style={{ width: "40px" }}>
                                                                        <a id="deleteButton" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteEntityModal" onClick={() => setSelectedEntity(room)} ><i className="bi bi-trash"></i></a>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div style={{ position: 'fixed', bottom: "20px", right: "20px", width: "40px", height: "40px", fontSize: "30px", borderRadius: "40px", boxShadow: "0px 2px 10px #00000080" }} >
                <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addEntityModal">
                    <i className="bi bi-plus" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}></i>
                </a>
            </div>

            <div className="modal fade" id="addEntityModal" tabIndex="-2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add {entityName}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedEntity(null)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-floating mb-3">
                                <input ref={inputNameRef} type="text" className="form-control" placeholder="Name" />
                                <label>Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <select ref={inputLocationRef} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                    <option value="">Select location</option>
                                    {entities.map((entity) => (
                                        <option key={entity._id} value={entity._id}>{entity.name}</option>
                                    ))}
                                </select>
                                <label>Location</label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedEntity(null)}>Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => addEntity()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteEntityModal" tabIndex="-2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete {entityName}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedEntity(null)}></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete <b>{selectedEntity?.name}</b> ?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedEntity(null)}>Close</button>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => deleteEntity()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="editEntityModal" tabIndex="-2">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit {entityName}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedEntity(null)}></button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-xl-6 col-xs-12'>
                                    <div className="form-floating mb-3">
                                        <input ref={inputEditNameRef} type="text" className="form-control" placeholder="Name" />
                                        <label>Name</label>
                                    </div>
                                </div>
                                <div className='col-xl-6 col-xs-12'>
                                    <div className="form-floating mb-3">
                                        <select ref={inputEditLocationRef} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                            <option value="">Select location</option>
                                            {entities.map((entity) => (
                                                <option key={entity._id} value={entity._id}>{entity.name}</option>
                                            ))}
                                        </select>
                                        <label>Location</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row' style={{ height: "100%" }}>
                                <div className='col-xl-12' style={{ height: "calc(100% - 60px)", margin: "0px", padding: "0px" }}>
                                    <table style={{ width: "100%", height: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ textAlign: "center" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                        <input value={northValue} onChange={(e) => northValueChanged(e)} type='number' max={10} min={0} style={{ width: "40px", textAlign: "center" }} disabled={!isRoomConfigurationEditable}></input>

                                                        <input onChange={() => setMainDoorPosition("N")} type='checkbox' checked={mainDoorPosition == "N"} style={{ width: "40px", textAlign: "center", marginLeft: "-40px", marginTop: "-10px" }} disabled={!isRoomConfigurationEditable} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ height: "100%" }}>
                                                    <div style={{ display: "flex", margin: "0px", padding: "0px", flexDirection: "column", justifyContent: "start", alignItems: "start" }}>
                                                        <input value={westValue} onChange={(e) => westValueChanged(e)} type='number' max={10} min={0} style={{ width: "40px", textAlign: "center" }} disabled={!isRoomConfigurationEditable}></input>
                                                        <input onChange={() => setMainDoorPosition("W")} checked={mainDoorPosition == "W"} type='checkbox' style={{ width: "20px", textAlign: "center", marginLeft: "-10px", marginTop: "-10px" }} disabled={!isRoomConfigurationEditable} />
                                                    </div>
                                                </td>
                                                <td style={{ height: "100%", width: "100%" }}>
                                                    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <div >
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {
                                                                    northValue > 0 && westValue > 0 && <><img src={CornerImage} height={deskHeight} /></>
                                                                }
                                                                {northDesks.map((desk) => (
                                                                    <div key={desk.number} style={{ position: "relative" }}>
                                                                        <div style={{ position: "absolute", width: "94%", height: "46%", bottom: "10%", left: "3%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                                            {desk.deviceId && <div style={{ position: "absolute", backgroundColor: "green", opacity: "0.8", width: "100%", height: "100%" }} onClick={() => unregisterDevice(desk._id)}></div>}

                                                                            <span style={{ position: "relative", color: "white", fontSize: "20px", fontWeight: "bold", pointerEvents: "none" }}>{desk.number}</span>
                                                                            <span style={{ position: "relative", color: "white", fontSize: "14px", marginTop: "-5px", pointerEvents: "none" }}>{desk.deviceId}</span>
                                                                        </div>

                                                                        <img src={DeskHImage} height={deskHeight} />
                                                                    </div>
                                                                ))}
                                                                {
                                                                    northValue > 0 && eastValue > 0 && <img src={CornerImage} height={deskHeight} style={{ transform: "rotate(90deg)" }} />
                                                                }
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ width: "50%", display: "flex", justifyContent: "start" }}>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        {westDesks.map((desk) => (
                                                                            <div key={desk.number} style={{ height: `${deskWidth}px`, position: "relative", transformOrigin: "100% 0px", transform: `translate(-100%, 0px)  rotate(-90deg)` }}>
                                                                                <div style={{ position: "absolute", width: "94%", height: "39%", bottom: "25%", left: "3.5%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                                                    {desk.deviceId && <div style={{ position: "absolute", backgroundColor: "green", opacity: "0.8", width: "100%", height: "100%" }} onClick={() => unregisterDevice(desk._id)}></div>}

                                                                                    <span style={{ position: "relative", color: "white", fontSize: "20px", fontWeight: "bold", pointerEvents: "none" }}>{desk.number}</span>
                                                                                    <span style={{ position: "relative", color: "white", fontSize: "14px", marginTop: "-5px", pointerEvents: "none" }}>{desk.deviceId}</span>
                                                                                </div>

                                                                                <img src={DeskHImage} height={deskHeight} />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: "50%", display: "flex", justifyContent: "end" }}>
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        {eastDesks.map((desk) => (
                                                                            <div key={desk.number} style={{ height: `${deskWidth}px`, position: "relative", transformOrigin: "0px 0px", transform: `translate(100%, 0px) rotate(90deg)` }}>
                                                                                <div style={{ position: "absolute", width: "94%", height: "39%", bottom: "25%", left: "3.5%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                                                    {desk.deviceId && <div style={{ position: "absolute", backgroundColor: "green", opacity: "0.8", width: "100%", height: "100%" }} onClick={() => unregisterDevice(desk._id)}></div>}

                                                                                    <span style={{ position: "relative", color: "white", fontSize: "20px", fontWeight: "bold", pointerEvents: "none" }}>{desk.number}</span>
                                                                                    <span style={{ position: "relative", color: "white", fontSize: "14px", marginTop: "-5px", pointerEvents: "none" }}>{desk.deviceId}</span>
                                                                                </div>

                                                                                <img src={DeskHImage} height={deskHeight} />
                                                                            </div>

                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex" }}>
                                                                {
                                                                    southValue > 0 && westValue > 0 && <img src={CornerImage} height={deskHeight} style={{ transform: "rotate(-90deg)" }} />
                                                                }
                                                                {southDesks.map((desk) => (
                                                                    <div key={desk.number} style={{ position: "relative", transform: "rotate(180deg)" }}>
                                                                        <div style={{ position: "absolute", width: "94%", height: "46%", bottom: "10%", left: "3%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                                            {desk.deviceId && <div style={{ position: "absolute", backgroundColor: "green", opacity: "0.8", width: "100%", height: "100%" }} onClick={() => unregisterDevice(desk._id)}></div>}

                                                                            <span style={{ position: "relative", color: "white", fontSize: "20px", fontWeight: "bold", pointerEvents: "none" }}>{desk.number}</span>
                                                                            <span style={{ position: "relative", color: "white", fontSize: "14px", marginTop: "-5px", pointerEvents: "none" }}>{desk.deviceId}</span>
                                                                        </div>

                                                                        <img src={DeskHImage} height={deskHeight} />
                                                                    </div>
                                                                ))}
                                                                {
                                                                    southValue > 0 && eastValue > 0 && <img src={CornerImage} height={deskHeight} style={{ transform: "rotate(180deg)" }} />
                                                                }
                                                            </div>



                                                        </div>


                                                    </div>
                                                </td>
                                                <td style={{ height: "100%" }}>

                                                    <div style={{ display: "flex", margin: "0px", padding: "0px", flexDirection: "column", justifyContent: "start", alignItems: "start" }}>
                                                        <input value={eastValue} onChange={(e) => eastValueChanged(e)} type='number' max={10} min={0} style={{ width: "40px", textAlign: "center" }} disabled={!isRoomConfigurationEditable}></input>
                                                        <input onChange={() => setMainDoorPosition("E")} checked={mainDoorPosition == "E"} type='checkbox' name="mainDoorPosition" style={{ width: "20px", textAlign: "center", marginLeft: "-10px", marginTop: "-10px" }} disabled={!isRoomConfigurationEditable} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{ textAlign: "center" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                        <input value={southValue} onChange={(ev) => southValueChanged(ev)} type='number' max={10} min={0} style={{ width: "40px", textAlign: "center" }} disabled={!isRoomConfigurationEditable}></input>
                                                        <input onChange={() => setMainDoorPosition("S")} checked={mainDoorPosition == "S"} type='checkbox' name="mainDoorPosition" style={{ width: "40px", textAlign: "center", marginLeft: "-40px", marginTop: "-10px" }} disabled={!isRoomConfigurationEditable} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedEntity(null)}>Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => updateEntity()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default CFG_RoomsPage;
