import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";

import styles from './HeaderComponent.module.css'


import InitialsCircleComponent from './InitialsCircleComponent';

import imageBack from '../../graphics/menu-culori.png'

import HeaderDetailsComponent from '../projectDetails/PD_HeaderComponent';
import PL_HeaderComponent from '../projectList/PL_HeaderComponent';
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';
import HeaderConferenceDetailsComponent from '../popup/conferenceDetails/HeaderConferenceDetailsComponent';
import UserDetailsComponent from '../UserDetailsComponent';
import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';



const HeaderComponent = ({ currentProjectList, setCurrentProjectList, setSeeNotification, seeNotification }) => {


    var [date, setDate] = useState(new Date());

    const { currentUser, userDetailsComponentTimer, setUserDetailsComponentTimer } = useContext(GlobalContext);
    const { editConference } = useContext(ProjectDetailsContext)
    const [seeUserDetails, setSeeUserDetails] = useState(false);



    useEffect(() => {
        //console.log("intru in header");
        var timer = setInterval(() => setDate(new Date()), 1000)
        return function cleanup() {
            clearInterval(timer)
        }
    }, []);

    useEffect(() => {
        if (userDetailsComponentTimer) {
            setTimeout(() => {
                setUserDetailsComponentTimer(false)
            }, 200);
        }
    }, [userDetailsComponentTimer]);


    return (
        <>
            <div className={styles.header} >

                <img src={imageBack} className={styles.backgroundImg} alt={"fundal"} />

                <InitialsCircleComponent name={currentUser?.name} onClick={() => {
                    //console.log("click");
                    //console.log("userDetailsComponentTimer", userDetailsComponentTimer);
                    //console.log("seeUserDetails", seeUserDetails);
                    if (!userDetailsComponentTimer) {
                        //console.log("da");
                        setSeeUserDetails(!seeUserDetails)
                    }
                }} />

                {currentProjectList && <PL_HeaderComponent currentProjectList={currentProjectList} setCurrentProjectList={setCurrentProjectList} setSeeNotification={setSeeNotification} seeNotification={seeNotification} />}

                {!currentProjectList && !editConference && <HeaderDetailsComponent setSeeNotification={setSeeNotification} seeNotification={seeNotification} />}

                {editConference && <HeaderConferenceDetailsComponent />}

                <div className={styles.date}>
                    {date.toLocaleDateString('en-RO', { weekday: 'short' })}. {date.toLocaleDateString('en-RO', { day: '2-digit' })} {date.toLocaleDateString('en-RO', { month: 'short' })}. {date.toLocaleDateString('en-RO', { year: 'numeric' })}
                </div>
                <div className={styles.time}>
                    {date.toLocaleTimeString('en-RO', { hour12: false, hour: '2-digit', minute: '2-digit' })}
                </div>

                {/* <div className={styles.line} style={{zIndex: GetMaxZIndex() + 1}}></div> */}
            </div>
            {seeUserDetails && <UserDetailsComponent setSeeUserDetails={setSeeUserDetails} />}
        </>

    );

}

export default HeaderComponent;