export function GetMaxZIndex() {
    return Math.max(
        ...Array.from(document.querySelectorAll('body *'), el =>
            parseFloat(window.getComputedStyle(el).zIndex),
        ).filter(zIndex => !Number.isNaN(zIndex)),
        0,
    );
}

export function  sortProjects(value, list, upcomingConferences){
    if(value === "1"){
        let alphabeticalProjectsList = list.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
        return alphabeticalProjectsList
    } else if(value === "2"){
        let alphabeticalProjectsList = list.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) return 1;
            if (nameA > nameB) return -1;
            return 0;
        });
        return alphabeticalProjectsList
    } else if(value === "3"){
        let sortedUpcomingConf = upcomingConferences.sort((a, b) => new Date(a.scheduleDate) - new Date(b.scheduleDate));
        let projectsWithUpcomingConference = []
        let projectsWithoutUpcomingConference = []
        for(let i = 0; i < list.length; i++){
            if(upcomingConferences.some(conference => conference.project === list[i]._id)){
                projectsWithUpcomingConference.push(list[i])
            } else {
                projectsWithoutUpcomingConference.push(list[i])
            }
        }
        const conferenceIndexMap = new Map();
        sortedUpcomingConf.forEach((conference, index) => {
            conferenceIndexMap.set(conference.project, index);
        });
        projectsWithUpcomingConference.sort((a, b) => {
            const indexA = conferenceIndexMap.get(a._id);
            const indexB = conferenceIndexMap.get(b._id);
            return indexA - indexB;
        })
        let upcomingProjects = [...projectsWithUpcomingConference, ...projectsWithoutUpcomingConference]
        return upcomingProjects
    } else if(value === "4"){
        let sortedUpcomingConf = upcomingConferences.sort((a, b) => new Date(a.scheduleDate) - new Date(b.scheduleDate));
        let projectsWithUpcomingConference = []
        let projectsWithoutUpcomingConference = []
        for(let i = 0; i < list.length; i++){
            if(upcomingConferences.some(conference => conference.project === list[i]._id)){
                projectsWithUpcomingConference.push(list[i])
            } else {
                projectsWithoutUpcomingConference.push(list[i])
            }
        }
        const conferenceIndexMap = new Map();
        sortedUpcomingConf.forEach((conference, index) => {
            conferenceIndexMap.set(conference.project, index);
        });
        projectsWithUpcomingConference.sort((a, b) => {
            const indexA = conferenceIndexMap.get(a._id);
            const indexB = conferenceIndexMap.get(b._id);
            return indexB - indexA;
        })
        let upcomingProjects = [...projectsWithUpcomingConference, ...projectsWithoutUpcomingConference]
        return upcomingProjects
    } else if(value === "5"){
        let createdProjectList = list.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate))
        return createdProjectList
    } else if(value === "6"){
        let createdProjectList = list.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        return createdProjectList
    }

}

export function formatDate(date){
    const dayNumber = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleDateString('en-US', { month: 'long'}); 
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }); 
    return { dayNumber, month, dayOfWeek };
};

export function generateDateList(currentDate, dayOffset){
    // const dayOffset = 17; 
    let dateList = [];
  
    for (let i = -dayOffset; i <= 2 * dayOffset; i++) {
        const date = new Date(currentDate);
        date.setDate(currentDate.getDate() + i); 
        const { dayNumber, month, dayOfWeek } = formatDate(date); 
        dateList.push({ dayNumber, month, dayOfWeek, date });
    }
  
    return dateList;
};

export function generateHourList(nr){
    let hourList = [];
    
    for (let i = 0; i < nr; i++) {
        const formattedHour = String(i).padStart(2, '0') + ':00'; 
        hourList.push(formattedHour);
    }

    for (let hour = 0; hour <= 23; hour++) {
        const formattedHour = String(hour).padStart(2, '0') + ':00'; 
        hourList.push(formattedHour);
    }

    for (let i = 0; i <= nr; i++) {
        const formattedHour = String(i).padStart(2, '0') + ':00'; 
        hourList.push(formattedHour);
    }
  
    return hourList;
};

export function newGenerateHourList(){
    let hourList = [];

    for (let hour = 0; hour <= 23; hour++) {
        const formattedHour = String(hour).padStart(2, '0') + ':00'; 
        hourList.push(formattedHour);
    }

    for (let i = 0; i <= 7; i++) {
        const formattedHour = String(i).padStart(2, '0') + ':00'; 
        hourList.push(formattedHour);
    }
  
    return hourList;
};

export function compareDate(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export function getUpcomingConferences(socketRef, userId, setNotificationUpcomingConferences) {
    if (!socketRef || !socketRef.current) {
        console.error("Socket reference is not valid.");
        return;
    }

    socketRef.current.emit("GET_UPCOMING_CONFERENCES", userId, (conferences) => {
        //console.log('Fetched upcoming conferences:', conferences);
        setNotificationUpcomingConferences(conferences);
    });
}