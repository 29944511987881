import { useContext, useEffect } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import styles from './DurationComponent.module.css'


const DurationComponent = ({duration, setDuration, startHour, endHour}) => {

    const { } = useContext(ProjectDetailsContext);
    const { } = useContext(GlobalContext);
    const { } = useContext(AddConferenceContext)

    useEffect(() => {
        let [startHours, startMinutes] = startHour.split(':')
        let [endHours, endMinutes] = endHour.split(':')

        startHours = parseInt(startHours)
        startMinutes = parseInt(startMinutes)
        endHours = parseInt(endHours)
        endMinutes = parseInt(endMinutes)

        let hourDiff = 0
        let minDiff = 0

        if(endHours >= startHours){
            hourDiff = endHours - startHours
        } else {
            hourDiff = 24 + endHours - startHours
        }

        if(endMinutes >= startMinutes){
            minDiff = endMinutes - startMinutes
        } else {
            minDiff = 60 + endMinutes - startMinutes
            hourDiff -= 1
        }
        
        setDuration(`${String(hourDiff).padStart(2, '0')}:${String(minDiff).padStart(2, '0')}`)
    },[startHour, endHour])


    return (
        <div className={styles.duration}>
            Duration
            <div className={styles.durationInput}>
                <span style={{ fontWeight: 'bold' }}>{duration.split(':')[0]}</span>:{duration.split(':')[1]}
            </div>
            <div className={styles.hoursMin}>
                <div className={styles.hoursMinLeft}>hours</div>
                min
            </div>
        </div>
    );

}

export default DurationComponent;