import { useRef, useContext, useState, useEffect } from 'react';
import styles from './CS_AttachedParticipantsListComponent.module.css'

import { GlobalContext } from "../../contexts/globalContext";

import InitialsCircleComponent from '../commons/InitialsCircleComponent';
import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';

const CS_AttachedParticipantsListComponent = ({ attachedParticipants, allDisplayedDesks, isRemote, selectedParticipant, setSelectedParticipant }) => {

    const ref = useRef()
    const [spaces, setSpaces] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8])
    const { videoStreams } = useContext(GlobalContext);
    const onlineParticipants = useRef();

    const selectParticipant = (assignedParticipant) => {
        if (onlineParticipants.current.includes(assignedParticipant.participant._id)) {
            if (selectedParticipant?._id == assignedParticipant.participant._id) {
                setSelectedParticipant(null);
            } else {
                setSelectedParticipant(assignedParticipant.participant);
            }            
        }
    }

    useEffect(() => {
        onlineParticipants.current = videoStreams.map(a => a.participant._id);
    }, [])

    return (
        <div ref={ref} className={styles.addedParticipantsList}>
            {!isRemote && allDisplayedDesks.map((desk, index) => {
                const assignedParticipant = attachedParticipants.find(
                    (attached) => attached.desk?._id === desk._id || attached.desk === desk._id
                );

                return (
                    <div className={styles.positionCard} key={desk._id} style={{
                        opacity: assignedParticipant ? 1 : 0.4,
                        backgroundColor: assignedParticipant ? "transparent" : ""
                    }}>
                        {assignedParticipant ? (
                            <div className={!onlineParticipants.current.includes(assignedParticipant.participant._id) ? styles.assignedParticipant_offline : 
                                selectedParticipant?._id==  assignedParticipant.participant._id?styles.assignedParticipant_selected: styles.assignedParticipant_online} onClick={() => selectParticipant(assignedParticipant)}>
                                <InitialsCircleComponent name={assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"}
                                    style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10, marginTop: 20 }} />
                                <div className={styles.participantDetails}>
                                    {assignedParticipant.participant.role}
                                    <div className={styles.participantName}>{assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"} </div>
                                    {assignedParticipant.participant.function?.name} {assignedParticipant.participant.function ? "|" : ""} {assignedParticipant.participant.department?.name}
                                </div>
                                <div className={styles.assignedDeskNumber} style={{ zIndex: GetMaxZIndex() + 1 }}>{desk.number}</div>
                            </div>
                        ) : (<div className={styles.numberedCircle}>{desk.number}</div>)}
                    </div>
                );
            })}
            {isRemote && spaces.map((space) => {
                let assignedParticipant = null

                if (space < attachedParticipants.length) {
                    assignedParticipant = attachedParticipants[space]
                }

                return (
                    <div className={styles.positionCard} key={space} style={{ opacity: assignedParticipant ? 1 : 0.4 }}>
                        {assignedParticipant && <div className={styles.assignedParticipant} onClick={() => selectParticipant(assignedParticipant)}>
                            <InitialsCircleComponent name={assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"}
                                style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10, marginTop: 20 }} />
                            <div className={styles.participantDetails}>
                                {assignedParticipant.participant.role}
                                <div className={styles.participantName}>{assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"} </div>
                                {assignedParticipant.participant.function?.name} {assignedParticipant.participant.function ? "|" : ""} {assignedParticipant.participant.department?.name}
                            </div>
                        </div>}
                    </div>
                );
            })}
        </div>
    );
}

export default CS_AttachedParticipantsListComponent;