import { useContext, useEffect, useRef, useState } from 'react';
import styles from './SymetricalTimePickerComponent.module.css'

import { useGesture } from 'use-gesture-pack';
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { GlobalContext } from '../../../contexts/globalContext';

const SymetricalTimePickerComponent = ({startHour, setStartHour, endHour, setEndHour, originalStartHourRef, originalEndHourRef, endPassedMidnight}) => {

    const { editConference } = useContext(ProjectDetailsContext)
    const { appHeight, appWidth } = useContext(GlobalContext)

    const refLeft = useRef()
    const refRight = useRef()
    const widthRef = useRef(editConference ? editConference.duration * 259 / 60 + 259 : 518)

    const [halfWidth, setHalfWidth] = useState(editConference ? editConference.duration * 259 / 60 + 259 : 518)

    const dragGesture = true
    const bringToFront = true

    useEffect(() => {
        if (editConference) {
            const hourStart = new Date(editConference.scheduleDate)
            const hourEnd = new Date(hourStart.getTime() + editConference.duration * 60000);

            originalStartHourRef.current = [hourStart.getHours(), hourStart.getMinutes()]
            originalEndHourRef.current = [hourEnd.getHours(), hourEnd.getMinutes()];
        }
    }, [])

    useGesture(
        {
            onDrag: ({ dx }) => {

                // verificare daca capatul intervalului se afla in ziua urmatoare, pentru ca in functie de asta stiu cum raportez la ora 00:00

                if (!endPassedMidnight.current) {
                    // cazul 1, in care trebuie sa pastram startHour mai mare sau egal cu ora 00:00 (inceputul zilei)

                    // verificare daca startHour este inainte de ora 0, sau daca este la ora 0 si cateva minute sau daca este la ora 0 fix si 
                    // este trasa spre dreapta (pentru a nu intra cu intervalul orar in ziua precedenta)

                    if (originalStartHourRef.current[0] > 0 || (originalStartHourRef.current[0] === 0 && originalStartHourRef.current[1] > 0) ||
                        (originalStartHourRef.current[0] === 0 && originalStartHourRef.current[1] === 0 && dx > 0)) {

                        // verificare daca latimea este in intervalul stabilit sau daca este la limita dar urmeaza sa fie tras in interior
                        // (pentru a nu depasi limitele stabilite de dimensiuni)

                        if ((widthRef.current - dx > 389 || dx < 0) && (widthRef.current - dx < 2332 || dx > 0)) {

                            setHalfWidth(widthRef.current - dx)
                            widthRef.current = widthRef.current - dx

                        } else {

                            // daca este la limita inferioara de dimensiuni sau este chiar mai mic, atunci va fi setat automat la limita inferioara
                            // idem si pentru limita superioara

                            if (widthRef.current - dx <= 389) {
                                setHalfWidth(389)
                                widthRef.current = 389
                            } else {
                                setHalfWidth(2332)
                                widthRef.current = 2332
                            }
                        }
                    } else if (originalStartHourRef.current[0] < 0) {

                        // daca trece de 0 fix, va fi pozitionat la 0

                        bringToZero()
                    }
                } else {

                    // cazul 2, in care trebuie sa pastram startHour mai mica sau egala cu ora 00:00 (sfarsitul zilei)

                    // verificare daca startHour este dupa ora 16 sau daca este la ora 0 fix si 
                    // este trasa spre stanga (pentru a nu intra cu startHour in ziua urmatoare)

                    if (originalStartHourRef.current[0] >= 16 || (originalStartHourRef.current[0] === 0 && originalStartHourRef.current[1] === 0 && dx < 0)) {

                        // verificare daca latimea este in intervalul stabilit sau daca este la limita dar urmeaza sa fie tras in interior
                        // (pentru a nu depasi limitele stabilite de dimensiuni)

                        if ((widthRef.current - dx > 389 || dx < 0) && (widthRef.current - dx < 2332 || dx > 0)) {
                            setHalfWidth(widthRef.current - dx)
                            widthRef.current = widthRef.current - dx
                        } else {

                            // daca este la limita inferioara de dimensiuni sau este chiar mai mic, atunci va fi setat automat la limita inferioara
                            // idem si pentru limita superioara

                            if (widthRef.current - dx <= 389) {
                                setHalfWidth(389)
                                widthRef.current = 389
                            } else {
                                setHalfWidth(2332)
                                widthRef.current = 2332
                            }
                        }
                    } else if (originalStartHourRef.current[0] < 16) {

                        // daca trece de 16, inseamna ca se incearca trecerea de 00:00 noaptea in urmatoarea zi si trebuie oprita

                        bringToZero()
                    }
                }



                changeStartHour()
            },
            onGestureEnded: () => {
                bringToMultipleOfFiveStart()

            }
        },
        {
            target: refLeft,
            dragGesture,
            appWidth,
            appHeight,
            bringToFront
        }
    );

    useGesture(
        {
            onDrag: ({ dx }) => {

                if ((widthRef.current + dx > 389 || dx > 0) && (widthRef.current + dx < 2332 || dx < 0)) {
                    setHalfWidth(widthRef.current + dx)
                    widthRef.current = widthRef.current + dx
                } else {
                    if (widthRef.current + dx <= 389) {
                        setHalfWidth(389)
                        widthRef.current = 389
                    } else {
                        setHalfWidth(2332)
                        widthRef.current = 2332
                    }
                }

                changeEndHour()
            },
            onGestureEnded: () => {
                bringToMultipleOfFiveEnd()

            }
        },
        {
            target: refRight,
            dragGesture,
            appWidth,
            appHeight,
            bringToFront
        }
    );

    function changeStartHour() {
        let [endHours, endMinutes] = originalEndHourRef.current;

        let minutesAdded = Math.floor(60 * (widthRef.current - 259) / 259)

        endMinutes -= minutesAdded

        if (endMinutes <= 0 && endMinutes % 60 !== 0) {
            endHours -= Math.floor(- endMinutes / 60) + 1
            endMinutes = 60 + endMinutes % 60
        } else {
            endHours -= Math.floor(- endMinutes / 60) + 1
            endMinutes = endMinutes % 60
        }

        if (endHours < 0) {
            if (!endPassedMidnight.current) {
                endHours = 0
                endMinutes = 0
            } else {
                endHours = 24 + endHours
            }
        }

        originalStartHourRef.current = [endHours, endMinutes]

        endMinutes = Math.round(endMinutes / 5) * 5

        if (endMinutes === 60) {
            endMinutes = 0
            endHours += 1

            if (endHours === 24) {
                endHours = 0
            }
        }

        setStartHour(`${String(endHours).padStart(2, "0")}:${String(endMinutes).padStart(2, "0")}`)
    }

    function bringToZero() {
        let [endHours, endMinutes] = originalEndHourRef.current;

        let minutesFromMidnightToEnd = endHours * 60 + endMinutes

        let midnightWidth = 259 * minutesFromMidnightToEnd / 60 + 259

        setHalfWidth(midnightWidth)
        widthRef.current = midnightWidth

        changeStartHour()
    }

    function bringToMultipleOfFiveStart() {
        let [startHours, startMinutes] = originalStartHourRef.current;

        let roundedMinutes = Math.round(startMinutes / 5) * 5

        let diffMinutes = roundedMinutes - startMinutes

        let diffWidth = 259 * diffMinutes / 60

        setHalfWidth(widthRef.current - diffWidth)
        widthRef.current = widthRef.current - diffWidth

        startMinutes = roundedMinutes

        if (startMinutes === 60) {
            startMinutes = 0
            startHours += 1

            if (startHours === 24) {
                startHours = 0
            }
        }

        originalStartHourRef.current = [startHours, startMinutes]
        setStartHour(`${String(startHours).padStart(2, "0")}:${String(startMinutes).padStart(2, "0")}`)

    }

    function changeEndHour() {
        let [startHours, startMinutes] = originalStartHourRef.current;

        let minutesAdded = Math.floor(60 * (widthRef.current - 259) / 259)

        startMinutes += minutesAdded

        if (startMinutes >= 0) {
            startHours += Math.floor(startMinutes / 60)
            startMinutes = startMinutes % 60
        }

        if (startHours > 23) {
            startHours = startHours - 24
            endPassedMidnight.current = true
        } else if (startHours < 0) {
            startHours = 24 - startHours
            endPassedMidnight.current = false
        }

        originalEndHourRef.current = [startHours, startMinutes]

        startMinutes = Math.round(startMinutes / 5) * 5

        if (startMinutes === 60) {
            startMinutes = 0
            startHours += 1

            if (startHours === 24) {
                startHours = 0
            }
        }

        setEndHour(`${String(startHours).padStart(2, "0")}:${String(startMinutes).padStart(2, "0")}`)
    }

    function bringToMultipleOfFiveEnd() {
        let [endHours, endMinutes] = originalEndHourRef.current;

        let roundedMinutes = Math.round(endMinutes / 5) * 5

        let diffMinutes = roundedMinutes - endMinutes

        let diffWidth = 259 * diffMinutes / 60

        setHalfWidth(widthRef.current + diffWidth)
        widthRef.current = widthRef.current + diffWidth

        endMinutes = roundedMinutes

        if (endMinutes === 60) {
            endMinutes = 0
            endHours += 1

            if (endHours === 24) {
                endHours = 0
            }
        }

        originalEndHourRef.current = [endHours, endMinutes]
        setEndHour(`${String(endHours).padStart(2, "0")}:${String(endMinutes).padStart(2, "0")}`)

    }

    return (

        <div className={styles.durationChangeLine}>
            <div className={styles.line}></div>

            <svg width="0" height="0">
                <defs>
                    <clipPath id="set-ora-stang">
                        <path d="M261.565,76h0a92.019,92.019,0,0,1-72.747-35.142,105.5,105.5,0,1,0-.9,130.418A94.249,94.249,0,0,1,261.565,136ZM105.5,181A75.5,75.5,0,1,1,181,105.5v0A75.586,75.586,0,0,1,105.5,181" />
                    </clipPath>
                </defs>
            </svg>

            <div className={styles.leftDiv} style={{ width: halfWidth }}>
                <div className={styles.gradientDivLeft} style={{ clipPath: 'url(#set-ora-stang)' }} />
                <div className={styles.gradientRectangleLeft} />
                <div className={styles.durationStart}><span style={{ fontWeight: 'bold' }}>{startHour.split(':')[0]}</span>:{startHour.split(':')[1]}</div>
                <div ref={refLeft} className={styles.grabbingZoneLeft} style={{ transform: `matrix(1,0,0,1,0,0)` }} />
            </div>

            <div className={styles.rightDiv} style={{ width: halfWidth }}>
                <div className={styles.gradientDivRight} style={{ clipPath: 'url(#set-ora-stang)' }} />
                <div className={styles.gradientRectangleRight} />
                <div className={styles.durationEnd}><span style={{ fontWeight: 'bold' }}>{endHour.split(':')[0]}</span>:{endHour.split(':')[1]}</div>
                <div ref={refRight} className={styles.grabbingZoneRight} style={{ transform: `matrix(1,0,0,1,0,0)` }} />
            </div>


        </div>

    );

}

export default SymetricalTimePickerComponent;