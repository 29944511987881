import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";

import CornerImage from "../../graphics/config/colt.png"
import DeskHImage from "../../graphics/config/masa-h.png"
import DeskVImage from "../../graphics/config/masa-v.png"

import styles from './RegisterDeviceComponent.module.css';

const RegisterDeviceComponent = () => {

    const [deskHeight, setDeskHeight] = useState();
    const { socketRef, setAlertMessage, appHeight, appWidth, deviceId, setDeskData, setRoomDataForRegister, roomDataForRegister } = useContext(GlobalContext);
    const [room, setRoom] = useState();

    useEffect(() => {

        setRoom(roomDataForRegister)

        var newDskHeight = 70;
        var mxx = Math.max(roomDataForRegister.desks_east.length, roomDataForRegister.desks_west.length);

        if (mxx > 0) {
            var discac = Math.trunc(1400 / (mxx + 1.5));

            if (discac > 0) {
                newDskHeight = discac;
            }
        }

        setDeskHeight(newDskHeight);

    }, [])

    function registerDeskData(deskId) {
        var body = {
            deskId,
            deviceId
        };

        socketRef.current.emit("REGISTER_DEVICE", body, (data) => {
            if (data.error) {
                setAlertMessage({ type: "ERROR", message: data.response });
            } else {
                setDeskData(data.desk);
                setRoomDataForRegister()
            }
        });
    }

    return (
        <div className={styles.component} style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", height: "50%", width: `${appHeight / 3}px`, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "60px" }}>
                Tap the corresponding desk from the schema in order to select and register the device.
            </div>
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {
                            room?.desks_north.length > 0 && room?.desks_west.length > 0 && <><img src={CornerImage} height={deskHeight} /></>
                        }
                        {room?.desks_north.map((desk) => (
                            <div key={desk.number} style={{ position: "relative" }} onClick={() => registerDeskData(desk._id)}>
                                <span style={{ position: "absolute", left: "40%", bottom: `${deskHeight / 12}px`, color: "white", fontSize: "60px", fontWeight: "bold" }}>{desk.number}</span>
                                <img src={DeskHImage} height={deskHeight} />
                            </div>
                        ))}
                        {
                            room?.desks_north.length > 0 && room?.desks_east.length > 0 && <img src={CornerImage} height={deskHeight} style={{ transform: "rotate(90deg)" }} />
                        }
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", display: "flex", justifyContent: "start" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {room?.desks_west.map((desk) => (
                                    <div key={desk.number} style={{ position: "relative" }} onClick={() => registerDeskData(desk._id)}>
                                        <span style={{ position: "absolute", top: "40%", right: `${deskHeight / 7}px`, color: "white", fontSize: "60px", fontWeight: "bold" }}>{desk.number}</span>
                                        <img src={DeskVImage} width={deskHeight} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ width: "50%", display: "flex", justifyContent: "end" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {room?.desks_east.map((desk) => (
                                    <div key={desk.number} style={{ position: "relative" }} onClick={() => registerDeskData(desk._id)}>
                                        <img src={DeskVImage} width={deskHeight} style={{ transform: "rotate(180deg)" }} />
                                        <span style={{ position: "absolute", top: "40%", left: `${deskHeight / 7}px`, color: "white", fontSize: "60px", fontWeight: "bold" }}>{desk.number}</span>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        {
                            room?.desks_south.length > 0 && room?.desks_west.length > 0 && <img src={CornerImage} height={deskHeight} style={{ transform: "rotate(-90deg)" }} />
                        }
                        {room?.desks_south.map((desk) => (
                            <div key={desk.number} style={{ position: "relative" }} onClick={() => registerDeskData(desk._id)}>
                                <img src={DeskHImage} height={deskHeight} style={{ transform: "rotate(180deg)" }} />
                                <span style={{ position: "absolute", left: "40%", top: `${deskHeight / 12}px`, color: "white", fontSize: "60px", fontWeight: "bold" }}>{desk.number}</span>
                            </div>
                        ))}
                        {
                            room?.desks_south.length > 0 && room?.desks_east.length > 0 && <img src={CornerImage} height={deskHeight} style={{ transform: "rotate(180deg)" }} />
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}

export default RegisterDeviceComponent;
