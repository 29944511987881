import { useContext, useRef, useState, useEffect } from 'react';

import { GlobalContext } from "../contexts/globalContext";

import styles from './ProjectsListComponent.module.css'

import ProjectDetailsComponent from './ProjectDetailsComponent';
import HeaderComponent from './commons/HeaderComponent';
import ProjectElementComponent from './projectList/ProjectElementComponent';
import SocketManager from '../utils/SocketManager';
import NotificationComponent from './NotificationComponent';
import { ProjectDetailsProvider } from "../contexts/projectDetailsContext"

import video from '../graphics/big wave fundal loop_6.mp4'
import NewProjectPopup from './projectDetails/NewProjectPopup';

const ProjectsListComponent = () => {
    const ref = useRef()
    const selectedProjectRef = useRef();

    const [currentProjectList, setCurrentProjectList] = useState([])

    const { setFunctionList, setDepartmentList, setHistoryConferences, statusProjectList, setProjectsList, setNotificationUpcomingConferences, currentUser, socketRef, selectedProject, appWidth, appHeight, createNewProject, openNotif, seeNotification, setSeeNotification, setOpenNotif } = useContext(GlobalContext);

    
    useEffect(() => {
        selectedProjectRef.current = selectedProject;
    }, [selectedProject])

    useEffect(() => {
        socketRef.current.emit('GET_ALL_PROJECTS', currentUser._id, null, true, (projects) => {
            setProjectsList(projects)
        });

        socketRef.current.emit("GET_UPCOMING_CONFERENCES", currentUser._id, (conferences) => {
            setNotificationUpcomingConferences(conferences)
        });

        socketRef.current.on('REFRESH_UPCOMING', async (projectId) => {
            if (projectId == selectedProjectRef.current?._id) {
                socketRef.current.emit("GET_UPCOMING_CONFERENCES", currentUser._id, (conferences) => {
                    setNotificationUpcomingConferences(conferences)
                });
            }
        });

        socketRef.current.emit("GET_DONE_CONFERENCES", (conferences) => {
            setHistoryConferences(conferences)
        });

        // socketRef.current.emit("GET_ALL_CONFERENCES", (conferences) => {
        //     // //console.log(conferences)
        // });

        socketRef.current.emit("GET_ALL_DEPARTMENTS", (callback) => {
            setDepartmentList(callback)
        });

        socketRef.current.emit("GET_ALL_FUNCTIONS", (callback) => {
            setFunctionList(callback)
        });

        return () => {
            socketRef.current.off('REFRESH_UPCOMING');
        }
    }, [])

    useEffect(() => {
        if (!seeNotification) {
            setTimeout(() => {
                setOpenNotif(false)
            }, 450);
        } else {
            setOpenNotif(true)
        }
    }, [seeNotification])


    return (
        <div>
            {!selectedProject && <div className={styles.component} style={{ height: `${appHeight}px`, width: `${appWidth}px`, color: "green" }} >
                <video id="background-video" autoPlay loop playsInline muted style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>
                    <source src={video} type="video/mp4" />
                </video>

                {/* {!selectedProject && openNotif && !createNewProject && <div className={styles.bottomShadow} />} */}
                <HeaderComponent currentProjectList={currentProjectList} setCurrentProjectList={setCurrentProjectList} setSeeNotification={setSeeNotification} seeNotification={seeNotification} />
                {openNotif && <NotificationComponent />}
                {!selectedProject && <div className={styles.topShadow} />}
                {currentProjectList.length < 5 ?
                    <div className={styles.list}>
                        {currentProjectList.map((project, index) => (
                            <ProjectElementComponent project={project} key={project._id} index={index} seeNotification={seeNotification} currentProjectList={currentProjectList} />
                        ))}
                    </div>
                    :
                    <div ref={ref} className={styles.overflowing_list} style={{ width: appWidth }}>
                        {currentProjectList.map((project, index) => (
                            <ProjectElementComponent project={project} key={project._id} index={index} seeNotification={seeNotification} currentProjectList={currentProjectList} />
                        ))}
                    </div>
                }

            </div>}
            {!selectedProject && createNewProject && <NewProjectPopup />}
            {/* {seeUserDetails && <UserDetailsComponent/>} */}
            {!selectedProject && <div className={styles.leftShadow} style={{ height: appHeight - 198 }} />}
            {!selectedProject && <div className={styles.rightShadow} style={{ height: appHeight - 198 }} />}
            {selectedProject && <ProjectDetailsProvider><ProjectDetailsComponent /></ProjectDetailsProvider>}
        </div>
    );

}

export default ProjectsListComponent;