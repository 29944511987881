import { useRef, useState, useEffect, forwardRef } from 'react';

import 'react-simple-keyboard/build/css/index.css';
import styles from './New_KeyboardComponent.module.css';
import New_KeyboardButton from './New_KeyboardButton'

import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';

const New_KeyboardComponent = forwardRef((props, ref) => {
    const [shifted, setShifted] = useState(false);

    const [shiftPressed, setShiftPressed] = useState(false);
    const [capsPressed, setCapsPressed] = useState(false);


    const [visible, setVisible] = useState(false);
    const inputsListRef = useRef();
    const currentInputForKeyboard = useRef();

    useEffect(() => {
        if (shiftPressed != capsPressed) {
            setShifted(true);
        } else {
            setShifted(false);
        }
    }, [shiftPressed, capsPressed])

    const onTabClicked = () => {
        if (!currentInputForKeyboard.current) {
            if (inputsListRef.current.length > 0) {
                inputsListRef.current[0].focus();
            }
        } else {
            var foundIndex = -1;
            for (let index = 0; index < inputsListRef.current.length; index++) {
                const element = inputsListRef.current[index];
                if (element == currentInputForKeyboard.current) {
                    foundIndex = index;
                }
            }

            foundIndex++;
            if (foundIndex == inputsListRef.current.length) {
                foundIndex = 0;
            }

            while (inputsListRef.current[foundIndex].disabled) {
                foundIndex++;
                if (foundIndex == inputsListRef.current.length) {
                    foundIndex = 0;
                }
            }

            inputsListRef.current[foundIndex].focus();
        }
    }

    const onMouseDown = (key) => {
        currentInputForKeyboard.current?.setKeyboardClicked(true);
    }

    const onKeyPressed = (key) => {
        if (currentInputForKeyboard.current) {
            currentInputForKeyboard.current.addToValue(key);
        }
    }

    const onBackspacePressed = () => {
        if (currentInputForKeyboard.current) {
            currentInputForKeyboard.current.removeOneFromValue();
        }
    }

    useEffect(() => {
        inputsListRef.current = [];
    }, [])

    useEffect(() => {
        ref.current.addInputElement = (element) => {
            inputsListRef.current = [...inputsListRef.current, element];
        }

        ref.current.removeInputElement = (element) => {
            inputsListRef.current = inputsListRef.current.filter(a => a !== element)
        }

        ref.current.setInputElement = (element) => {
            currentInputForKeyboard.current = element;

            if (element) {
                setVisible(true);
                if (currentInputForKeyboard.current.keyboardOpenAt) {
                    let splt = currentInputForKeyboard.current.keyboardOpenAt.split(",");
                    let xx = Number(splt[0]);
                    let yy = Number(splt[1]);
                    ref.current.style.transform = `matrix(1, 0, 0, 1, ${xx},  ${yy})`;
                }
            } else {
                setVisible(false);
            }
        }


        ref.current.hideIt = () => {
            if (props.onClose) {
                props.onClose();
            }
        }

    }, [ref.current])

    return (

        <div ref={ref} className={styles.component} style={{
            zIndex: GetMaxZIndex() + 1, display: visible ? "block" : "none",
            // transform: `matrix(${props.s}, 0, 0, ${props.s}, ${props.x}, ${props.y})`,

        }}>
            <div className={styles.line}>
                <New_KeyboardButton shifted={shifted} normalKey="`" shiftedKey="~" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="1" shiftedKey="!" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="2" shiftedKey="@" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="3" shiftedKey="#" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="4" shiftedKey="$" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="5" shiftedKey="%" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="6" shiftedKey="^" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="7" shiftedKey="&" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="8" shiftedKey="*" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="9" shiftedKey="(" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="0" shiftedKey=")" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="-" shiftedKey="_" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="+" shiftedKey="=" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="backspace" shiftedKey="backspace" length={3} onBackspacePressed={onBackspacePressed} onMouseDown={onMouseDown}></New_KeyboardButton>
            </div>

            <div className={styles.line}>
                <New_KeyboardButton shifted={shifted} normalKey="tab" shiftedKey="tab" length={3} onTabClicked={onTabClicked} onMouseDown={onMouseDown}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="q" shiftedKey="Q" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="w" shiftedKey="W" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="e" shiftedKey="E" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="r" shiftedKey="R" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="t" shiftedKey="T" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="y" shiftedKey="Y" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="u" shiftedKey="U" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="i" shiftedKey="I" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="o" shiftedKey="O" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="p" shiftedKey="P" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="[" shiftedKey="{" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="]" shiftedKey="}" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="\" shiftedKey="|" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
            </div>
            <div className={styles.line}>
                <New_KeyboardButton shifted={shifted} normalKey="caps" shiftedKey="caps" capsPressed={capsPressed} setCapsPressed={setCapsPressed} length={2.5} onMouseDown={onMouseDown}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="a" shiftedKey="A" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="s" shiftedKey="S" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="d" shiftedKey="D" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="f" shiftedKey="F" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="g" shiftedKey="G" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="h" shiftedKey="H" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="j" shiftedKey="J" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="k" shiftedKey="K" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="l" shiftedKey="L" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey=";" shiftedKey=":" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="'" shiftedKey={'"'} onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="enter" shiftedKey="enter" onEnterClicked={props?.onEnterClicked} length={2.6} onMouseDown={onMouseDown}></New_KeyboardButton>
            </div>
            <div className={styles.line}>
                <New_KeyboardButton shifted={shifted} normalKey="shift" shiftedKey="shift" shiftPressed={shiftPressed} setShiftPressed={setShiftPressed} length={3.1} onMouseDown={onMouseDown}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="z" shiftedKey="Z" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="x" shiftedKey="X" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="c" shiftedKey="C" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="v" shiftedKey="V" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="b" shiftedKey="B" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="n" shiftedKey="N" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="m" shiftedKey="M" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="," shiftedKey="<" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="." shiftedKey=">" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="/" shiftedKey="?" onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="shift" shiftedKey="shift" shiftPressed={shiftPressed} length={3.1} onMouseDown={onMouseDown}></New_KeyboardButton>

            </div>
            <div className={styles.line}>
                <New_KeyboardButton shifted={shifted} normalKey=".com" shiftedKey=".com" length={4} onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey=" " shiftedKey=" " length={9.3} onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>
                <New_KeyboardButton shifted={shifted} normalKey="@nextfusion.ro" shiftedKey="@nextfusion.ro" length={4} onKeyPressed={onKeyPressed} onMouseDown={onMouseDown} setShiftPressed={setShiftPressed}></New_KeyboardButton>

            </div>
        </div>

    );
})


export default New_KeyboardComponent;