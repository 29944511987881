import { useContext, useState, useEffect, useRef } from 'react';
import styles from './ConferenceScreenComponent.module.css'
import SocketManagerConference from '../../utils/SocketManagerConference';
import WaitingForJoiningScreen from './WaitingForJoiningScreen';
import MediasoupConferenceComponent from './MediasoupConferenceComponent';
import TopicsComponent from './TopicsComponent';
import SideMenuComponent from './SideMenuComponent';
import { GlobalContext } from "../../contexts/globalContext";
import RegisterNewVisitor from './RegisterNewVisitor';
import PdfFileComponent from '../../files/PdfFileComponent';
import ImageFileComponent from '../../files/ImageFileComponent';
import VideoFileComponent from '../../files/VideoFileComponent';
import videoSource from '../../graphics/big wave fundal loop_6.mp4';
import { AddConferenceProvider } from "../../contexts/addConferenceContext";

const ConferenceScreenComponent = () => {
    const { conferencePaused, localSocketRef, setCurrentTopic, currentTopic, waitingForJoining, setWaitingForJoining, setConferencePaused, currentParticipant, sharedFiles, openedFiles, socketRef, setOpenedFiles, setSharedFiles, currentConference, appHeight, appWidth } = useContext(GlobalContext);
    const [panelIsDown, setPanelIsDown] = useState(true);

    const currentConferenceRef = useRef();
    const currentParticipantRef = useRef();

    useEffect(() => {
        currentConferenceRef.current = currentConference;
        console.log("xxxxxx", currentConference);
    }, [currentConference])

    useEffect(() => {

        console.log("conferencePaused",conferencePaused);
    }, [conferencePaused]);

    useEffect(() => {
        currentParticipantRef.current = currentParticipant;
        console.log("currentParticipant",currentParticipant);
    }, [currentParticipant]);

    useEffect(() => {

        if (panelIsDown) {
            setTimeout(() => {
                //console.log("lift_panel");
                localSocketRef.current?.emit("lift_panel");
                setPanelIsDown(false);
            }, 1000);

        }

        setOpenedFiles([]);
        setSharedFiles([]);

        if (socketRef.current) {
            socketRef.current.on('PAUSE_CONFERENCE', function (conferenceId) {
                console.log("PAUSE_CONFERENCE");
                if (conferenceId == currentConferenceRef.current._id) {
                    console.log("cp1 true ");
                    setConferencePaused(true);
                    setWaitingForJoining(true);
                    currentConferenceRef.current.paused = true;
                }
            });

            socketRef.current.on('PLAY_CONFERENCE', function (conferenceId) {
                console.log("PLAY_CONFERENCE", conferenceId);
                console.log("PLAY_CONFERENCE", currentConferenceRef.current);
                console.log("currentParticipant", currentParticipantRef.current);
                if (conferenceId == currentConferenceRef.current._id && currentParticipantRef.current) {
                    console.log("cp2 false ");
                    setConferencePaused(false);
                    setWaitingForJoining(false);
                    currentConferenceRef.current.paused = false;
                }
            });

            socketRef.current.on('UPDATE_SHARED_DATA', (updatedShareData, conferenceId) => {
                if (conferenceId == currentConferenceRef.current._id) {
                    setSharedFiles([]);
                    setSharedFiles(updatedShareData);

                    currentConferenceRef.current.shared_data = JSON.stringify(updatedShareData);
                }
            })

            var aux_topic = currentConferenceRef.current.topics.find(topic => topic._id == currentConferenceRef.current.currentTopic);
            // //console.log("setCurrentTopic 1");
            setCurrentTopic(aux_topic);

            socketRef.current.on('UPDATE_CURRENT_TOPIC', (topicId, conferenceId) => {
                //console.log('UPDATE_CURRENT_TOPIC');
                if (conferenceId == currentConferenceRef.current._id) {

                    if (currentConferenceRef.current.type != "Presentation") {
                        setSharedFiles([]);
                    }
                    if (topicId) {
                        var aux_topic = currentConferenceRef.current.topics.find(topic => topic._id == topicId);
                        //console.log("setCurrentTopic 2");
                        //console.log(aux_topic);
                        setCurrentTopic(aux_topic);
                        currentConferenceRef.current.currentTopic = aux_topic._id;
                    } else {
                        //setCurrentModeratorStream(null);
                        setCurrentTopic(null);
                        currentConferenceRef.current.currentTopic = null;
                    }
                }
            })
        }

        return () => {
            socketRef.current?.off('UPDATE_SHARED_DATA')
            socketRef.current?.off('PAUSE_CONFERENCE')
            socketRef.current?.off('PLAY_CONFERENCE')
            socketRef.current?.off('UPDATE_CURRENT_TOPIC')
        }
    }, [])

    useEffect(() => {
        if (!waitingForJoining) {
            setSharedFiles([]);

            if (currentConference.shared_data) {
                setSharedFiles(JSON.parse(currentConference.shared_data));
            }

            if (currentConference.paused) {
                console.log("cp3 true ");
                setConferencePaused(true);
                setWaitingForJoining(true);
            } else {
                console.log("cp4 false ");
                setConferencePaused(false);
                setWaitingForJoining(false);
            }

        }


    }, [waitingForJoining])

    const GetFileComponent = (file, shared) => {
        switch (file.type) {
            case "pdf":
                return <PdfFileComponent key={file.uid} shared={shared} file={file}></PdfFileComponent>
            case "image":
                return <ImageFileComponent key={file.uid} shared={shared} file={file}></ImageFileComponent>
            case "video":
                return <VideoFileComponent key={file.uid} shared={shared} file={file}></VideoFileComponent>
            default:
                return "";
        }
    }

    return (
        <div className={styles.component}>
            <AddConferenceProvider>
                <video id="background-video" autoPlay loop playsInline muted style={{ position: "absolute", height: `${appHeight}px`, width: `${appWidth}px` }}>
                    <source src={videoSource} type="video/mp4" />
                </video>

                {waitingForJoining && !currentParticipant && <RegisterNewVisitor></RegisterNewVisitor>}

                {waitingForJoining && currentParticipant && <WaitingForJoiningScreen SetWaitingForJoining={setWaitingForJoining}></WaitingForJoiningScreen>}
                {!waitingForJoining && <MediasoupConferenceComponent SetWaitingForJoining={setWaitingForJoining} />}


                {/* currentConference?.topics[0].moderator._id == currentParticipant._id &&  */}
                {!waitingForJoining && (currentConference?.type == "Conference" || (currentConference?.type == "Presentation" && currentConference?.topics[0].moderator._id == currentParticipant._id)) && <TopicsComponent SetWaitingForJoining={setWaitingForJoining} />}

                {!waitingForJoining && <SideMenuComponent SetWaitingForJoining={setWaitingForJoining} />}

                {/* {!waitingForJoining && currentParticipant._id != currentTopic?.moderator._id && sharedFiles.map((file) => ( */}
                {!waitingForJoining && sharedFiles.map((file) => (
                    GetFileComponent(file, true)
                ))}

                {!waitingForJoining && openedFiles.map((file) => (
                    GetFileComponent(file, false)
                ))}

                {!waitingForJoining && currentTopic && <div style={{ width: "100%", position: "absolute", display: "flex", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "#292148", padding: "30px 60px", borderRadius: "0px 0px 10px 10px", font: "normal normal 600 50px/44px Bio Sans", color: "#C3C2CA" }}>{currentTopic.name}</div>
                </div>}

            </AddConferenceProvider>

        </div>
    );
}

export default ConferenceScreenComponent;