
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './PD_ConferenceComponent.module.css'
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";
import TagComponent from '../../projectList/TagComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import LightButtonComponent from '../../commons/LightButtonComponent';

import DetailCountComponent from '../../projectList/DetailCountComponent';

const PD_ConferenceComponent = ({ conference }) => {
    const deleteRef = useRef()

    const [showStartPresentation, setShowStartPresentation] = useState(false);
    const [showJoinButton, setShowJoinButton] = useState(false);

    const { setConferencePaused, setWaitingForJoining, socketRef, currentUser, selectedProject, language, detailsUpcomingConference, setConferenceScreen, setCurrentConference, setCurrentParticipant, deskData } = useContext(GlobalContext);

    const { setEditConference, moreClicked, setMoreClicked, setDeleteClicked, moreMenuTimer, editConference } = useContext(ProjectDetailsContext);

    const date = new Date(conference.scheduleDate)

    function deleteConference() {
        socketRef.current.emit("DELETE_CONFERENCE", conference._id, selectedProject._id,
            () => {
                setMoreClicked({});
                setDeleteClicked()
            })
    }

    function selectConference() {
        //console.log("selectConference");
        socketRef.current.emit("GET_CONFERENCE_BY_ID", conference._id, (callback) => {
            //console.log(callback.response);
            setEditConference(callback.response)
        })
    }



    const handleDelete = () => {
        const rect = deleteRef.current.getBoundingClientRect(); // Get element position dynamically
        setDeleteClicked({
            shown: true,
            funct: deleteConference,
            name: "Conference", // Provide any additional data needed
            x: rect.x,
            y: rect.y,
            onClose: () => setDeleteClicked(), // Provide a default close handler
        });
    };

    const StartPresentation = () => {
        //console.log("conference.code", conference.code);
        socketRef.current.emit("START_CONFERENCE", conference._id, conference.code, (data) => {
            if (data.error) {
                //console.log(data.response);
            } else {
                socketRef.current.emit("PRESENTATION_STARTED", conference);

                socketRef.current.emit("GET_NEXT_OR_ACTIVE_CONFERENCE", deskData._id, (data) => {
                    console.log("raspuns GET_NEXT_OR_ACTIVE_CONFERENCE 3");
                    if (!data.error && data.conference) {
                        setWaitingForJoining(true);
                        setCurrentParticipant(data.participant);
                        setCurrentConference(data.conference);
                        setConferencePaused(data.conference.paused);
                    }
                });
                
            }
        });
    };

    useEffect(() => {
        var firstModerator = false;
        var onCorrectDesk = false;

        if (conference.topics?.length > 0 && conference.topics[0].moderator._id == currentUser.id) {
            firstModerator = true;
        }

        if (conference.attachedParticipants && deskData) {
            var ap_found = conference.attachedParticipants.find(element => element.participant == currentUser._id && element.desk == deskData._id);
            if (ap_found) {
                onCorrectDesk = true;
            }
        }

        if ((conference.status === "New" || conference.status === "Done") && conference.type === "Presentation" && firstModerator && onCorrectDesk) {
            setShowStartPresentation(true);
        }

        if (conference.status === "Active" && (conference.type === "Conference" || conference.type === "Presentation")) {
            setShowJoinButton(true)
        }

    }, [])

    return (
        <div className={new Date(conference.scheduleDate) < new Date() ? styles.pastElement : styles.element}
            style={{
                background: conference.type === "Conference" ? "" : (new Date(conference.scheduleDate) >= new Date() ? "#2D2748 0% 0% no-repeat padding-box" : "#231A3E80 0% 0% no-repeat padding-box"),
                borderColor: conference.type === "Conference" ? "#6CEA7F" : "#E56ADB",
                borderWidth: detailsUpcomingConference?._id === conference._id ? "2px" : "0px",
            }}>
            <div className={styles.info}>
                <div>
                    <div className={styles.title_tags}>
                        <TagComponent status={conference.type} text={conference.type} />
                        {new Date(conference.scheduleDate) > new Date() && detailsUpcomingConference?._id !== conference._id &&
                            <TagComponent status="active" text="Future" />}
                        {new Date(conference.scheduleDate) < new Date() &&
                            <TagComponent status="inactive" text="Past" />}
                        {detailsUpcomingConference?._id === conference._id &&
                            <TagComponent status="live" text="Upcoming" />}
                    </div>
                    <div style={{ marginTop: 10 }}>{conference.name}</div>
                </div>
                {!showJoinButton && <>
                    <FontAwesomeIcon
                        className={styles.iconButton}
                        style={{ right: currentUser?.role.toLowerCase() === "administrator" ? 140 : 60 }}
                        icon={byPrefixAndName.fal['pen']}
                        onClick={(e) => selectConference()}
                    />
                    {currentUser?.role.toLowerCase() === "administrator" &&
                        <FontAwesomeIcon
                            ref={deleteRef}
                            className={styles.iconButton}
                            style={{ right: 50 }}
                            icon={byPrefixAndName.fal['trash']}
                            onClick={(e) => handleDelete(e)}
                        />}
                </>}
            </div>
            {conference.type != "Presentation" && <div className={styles.details}>
                <div className={styles.half_detail} style={{ borderRight: "2px solid #FFFFFF33" }}>
                    <div style={{ color: "#CDCCD2" }}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['calendar-days']} />
                        Date
                    </div>
                    <div className={styles.detail_accent}>
                        {date.toLocaleDateString('en-RO', { weekday: 'short' })} {date.toLocaleDateString('en-RO', { day: '2-digit' })} {date.toLocaleDateString('en-RO', { month: 'short' })}
                    </div>
                </div>
                <div className={styles.half_detail} >
                    <div style={{ color: "#CDCCD2" }}>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['clock']} />
                        Starting at
                    </div>
                    <div className={styles.detail_accent}>
                        {date.toLocaleTimeString('en-RO', { hour12: false, hour: '2-digit', minute: '2-digit' })}
                    </div>
                </div>
            </div>
            }
            <div className={styles.line}>

            </div>

            <div className={styles.moreInfo} style={{ color: "#CDCCD2" }}>
                <DetailCountComponent project={conference} type={"nrTopics"} style={{ font: "normal normal normal 28px/42px 'Bio Sans'", opacity: new Date(conference.scheduleDate) < new Date() ? 0.5 : 1 }} />
                <DetailCountComponent project={conference} type={"nrAttachedParticipants"} style={{ font: "normal normal normal 28px/42px 'Bio Sans'", opacity: new Date(conference.scheduleDate) < new Date() ? 0.5 : 1 }} />
                <DetailCountComponent project={conference} type={"nrModerators"} style={{ font: "normal normal normal 28px/42px 'Bio Sans'", opacity: new Date(conference.scheduleDate) < new Date() ? 0.5 : 1 }} />
            </div>
            {/* {new Date(conference.scheduleDate) > new Date() && detailsUpcomingConference?._id === conference._id && <div className={styles.buttons}>
                {detailsUpcomingConference?._id === conference._id && <LightButtonComponent onClick={() => { setConferenceScreen(true); setCurrentConference(conference._id) }}
                    style={{ font: "normal normal 600 40px/80px 'Bio Sans'", marginLeft: 0 }}>
                    <FontAwesomeIcon className={styles.iconArrow} icon={byPrefixAndName.fas['arrow-right']} />
                    {currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator" ? "START" : "JOIN"}
                </LightButtonComponent>}
            </div>} */}
            {
                showJoinButton &&
                <div className={styles.buttons}>
                    <LightButtonComponent onClick={() => {
                        setCurrentParticipant(currentUser);
                        setCurrentConference(conference);
                    }}
                        style={{ font: "normal normal 600 40px/80px 'Bio Sans'", marginLeft: 0 }}>
                        <FontAwesomeIcon className={styles.iconArrow} icon={byPrefixAndName.fas['arrow-right']} />
                        JOIN
                    </LightButtonComponent>
                </div>
            }

            {
                showStartPresentation &&
                <div className={styles.buttons}>
                    <LightButtonComponent onClick={() => StartPresentation()}
                        style={{ font: "normal normal 600 40px/80px 'Bio Sans'", marginLeft: 0 }}>
                        <FontAwesomeIcon className={styles.iconArrow} icon={byPrefixAndName.fas['arrow-right']} />
                        START
                    </LightButtonComponent>
                </div>
            }
            {/* { isDelete && <DeleteNotificationComponent name={'Conference'} funct={deleteConference} onClose={() => setIsDelete(false)} /> } */}
        </div>
    );

}



export default PD_ConferenceComponent;