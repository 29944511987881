import { useRef, useContext, useState, useEffect } from 'react';
import styles from './ImageFileComponent.module.css'
import { GlobalContext } from "../contexts/globalContext"
import CustomMultitouchComponent from "./CustomMultitouchComponent"

import ShareIcon from "../graphics/conference/share.svg"
import HandoverIcon from "../graphics/conference/handover.svg"
import HandoverOffIcon from "../graphics/conference/handover-off.svg";
import ParticipantsToShareComponent from "../components/conferenceScreen/ParticipantsToShareComponent";


const ImageFileComponent = ({ file, shared }) => {

    const ref = useRef(null);
    const imgRef = useRef(null);
    const mtRef = useRef(null);
    const { setSharedFiles, setOpenedFiles, appWidth, appHeight, currentParticipant, currentTopic, sharedFiles, socketRef, currentConference } = useContext(GlobalContext);
    const [isVisible, setIsVisible] = useState(false);

    const mustBeDeletedRef = useRef();
    const updateRequiredRef = useRef();
    const sharedFilesRef = useRef();

    const [buttonsTop, setButtonsTop] = useState(-120);
    const [showParticipantsToShare, setShowParticipantsToShare] = useState(false);

    const startingHeight = 600;
    const fileRef = useRef(null);

    const options = {
        appWidth,
        appHeight,
        maxScale: appHeight / startingHeight,
        minScale: 0.5
    }

    const calculateWidth = () => {
        if (imgRef.current) {
            const containerHeight = startingHeight;
            const image = imgRef.current;
            const imageAspectRatio = image.height / image.width;
            const containerWidth = containerHeight / imageAspectRatio;

            return containerWidth;
        }

        return 0;
    };

    const containerStyle = {
        width: `${isVisible ? calculateWidth() : 60}px`,
        height: `${isVisible ? startingHeight : 75}px`,
        // opacity: `${isVisible ? 1 : 0}`,
        overflow: 'hidden',
        transition: `width 0.5s ease, height 0.5s ease, transform 0.5s ease`,
        borderRadius: '0px',
        padding: '0px',
        transformOrigin: 'center center',
        transform: `translate(-50%, -50%) matrix(1, 0, 0, 1, 0,0)`,
        backdropFilter: `${isVisible ? 'blur(10px)' : ''}`,
        backgroundColor: `${isVisible ? 'rgba(0, 0, 0, 0.3)' : 'transparent'}`
    };

    useEffect(() => {
        mustBeDeletedRef.current = false;
        updateRequiredRef.current = true;
    }, [])

    const onMinScaleReached = () => {
        mustBeDeletedRef.current = true;

        setOpenedFiles(files => files.filter(a => a.uid !== file.uid));

        if (shared) {
            var filtrat = sharedFilesRef.current.filter(a => a.uid !== file.uid);
            setSharedFiles(filtrat);
            currentConference.shared_data = JSON.stringify(filtrat);
            socketRef.current.emit("UPDATE_SHARE", currentConference._id, filtrat);
        }
    }

    const onGestureEnded = () => {
        if (shared) {
            if (!mustBeDeletedRef.current) {
                ShareDocument();
            }
        } else {
            SaveOpenedFilesState();
        }
    }

    const SaveOpenedFilesState = () => {
        updateRequiredRef.current = false;
        file.transform = mtRef.current?.style.transform;

        setOpenedFiles(objs =>
            objs.map(item =>
                item.uid === file.uid ? file : item
            )
        );
    }

    const ShareDocument = () => {
        updateRequiredRef.current = false;
        sharedFilesRef.current = sharedFilesRef.current.filter(a => a.uid !== fileRef.current.uid);

        fileRef.current.transform = mtRef.current?.style.transform;

        if (!fileRef.current.sharedBy) {
            fileRef.current.sharedBy = currentParticipant._id;
            fileRef.current.sharedByName = currentParticipant.name;
        }

        if (!fileRef.current.moderatedBy) {
            fileRef.current.moderatedBy = currentParticipant._id;
            fileRef.current.moderatedByName = currentParticipant.name;
        }


        sharedFilesRef.current = [...sharedFilesRef.current, fileRef.current];

        setOpenedFiles(devices => devices.filter(a => a.uid !== fileRef.current.uid));

        currentConference.shared_data = JSON.stringify(sharedFilesRef.current);
        socketRef.current.emit("UPDATE_SHARE", currentConference._id, sharedFilesRef.current);
    }

    useEffect(() => {
        fileRef.current = file;
        if (shared) {
            if (currentParticipant._id == file.moderatedBy || currentParticipant._id == file.sharedBy) {
                mtRef.current.style.msTouchAction = "auto";
                mtRef.current.style.pointerEvents = "auto";
                mtRef.current.style.touchAction = "auto";

                ref.current.style.msTouchAction = "auto";
                ref.current.style.pointerEvents = "auto";
                ref.current.style.touchAction = "auto";

            } else {
                mtRef.current.style.msTouchAction = "none";
                mtRef.current.style.pointerEvents = "none";
                mtRef.current.style.touchAction = "none";

                ref.current.style.msTouchAction = "none";
                ref.current.style.pointerEvents = "none";
                ref.current.style.touchAction = "none";
            }
        }


        if (updateRequiredRef.current) {
            if (file.transform) {
                mtRef.current.style.transform = file.transform;
            }

        } else {
            updateRequiredRef.current = true;
        }
    }, [file])

    useEffect(() => {
        sharedFilesRef.current = sharedFiles;
    }, [sharedFiles])

    const ChangeModerator = (participant) => {
        updateRequiredRef.current = false;
        sharedFilesRef.current = sharedFilesRef.current.filter(a => a.uid !== file.uid);


        file.moderatedBy = participant._id;
        file.moderatedByName = participant.name;


        sharedFilesRef.current = [...sharedFilesRef.current, file];

        setOpenedFiles(devices => devices.filter(a => a.uid !== file.uid));

        currentConference.shared_data = JSON.stringify(sharedFilesRef.current);
        socketRef.current.emit("UPDATE_SHARE", currentConference._id, sharedFilesRef.current);
    }

    const touchClicked_handler = () => {
        if (buttonsTop < 0) {
            setButtonsTop(10);
        } else {
            setButtonsTop(-120);
        }
    }

    const GetStyle = () => {
        return !shared ? styles.touch_component :
            (currentParticipant._id == file.moderatedBy || currentParticipant._id == file.sharedBy) ? styles.touch_component_moderator : styles.touch_component_shared;
    }

    return (
        <>
            <CustomMultitouchComponent ref={mtRef} dragGesture={false} x={file.x + 75} y={file.y + 40} onMinScaleReached={onMinScaleReached} onGestureEnded={onGestureEnded} options={options}>
                <div className={GetStyle(file)} ref={ref} style={containerStyle} onClick={() => touchClicked_handler()} >
                    <img alt="imagine" ref={imgRef} style={{ borderRadius: "0px", width: "100%" }} onLoad={() => { setIsVisible(true) }} draggable="false" src={process.env.REACT_APP_CMS_URL + file.path} />

                    {!shared && (!currentTopic || currentParticipant._id == currentTopic?.moderator._id) &&
                        <img src={ShareIcon} style={{ transition: "all 0.3s", position: "absolute", right: "10px", top: `${buttonsTop}px`, height: "60px" }} onClick={(e) => {e.preventDefault(); e.stopPropagation(); ShareDocument();}} />
                    }
                    {shared && file.sharedBy == currentParticipant._id && file.moderatedBy == currentParticipant._id &&
                        <img src={HandoverIcon} style={{ transition: "all 0.3s", position: "absolute", right: "10px", top: `${buttonsTop}px`, height: "50px" }} onClick={(e) => {e.preventDefault(); e.stopPropagation(); setShowParticipantsToShare(true); setButtonsTop(-120);}} />
                    }
                    {shared && file.sharedBy == currentParticipant._id && file.moderatedBy != currentParticipant._id &&
                        <img src={HandoverOffIcon} style={{ transition: "all 0.3s", position: "absolute", right: "10px", top: `${buttonsTop}px`, height: "50px" }} onClick={(e) => {e.preventDefault(); e.stopPropagation(); ChangeModerator(currentParticipant); setButtonsTop(-120);}} />
                    }
                </div>
            </CustomMultitouchComponent>
            {showParticipantsToShare &&
                <ParticipantsToShareComponent setShowParticipantsToShare={setShowParticipantsToShare} ChangeModerator={ChangeModerator}></ParticipantsToShareComponent>

            }
        </>
    );

}

export default ImageFileComponent;