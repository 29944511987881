import { useState, useEffect } from 'react';
import styles from './New_KeyboardButton.module.css'

const New_KeyboardButton = ({ shifted, capsPressed, setCapsPressed, shiftPressed, setShiftPressed, setShifted, normalKey, shiftedKey, length = 1, onEnterClicked, onKeyPressed, onBackspacePressed, onTabClicked, onMouseDown }) => {

    const [value, setValue] = useState(normalKey);
    const [buttonStyle, setButtonStyle] = useState(styles.button);

    useEffect(() => {
        setValue(shifted ? shiftedKey : normalKey);
    }, [shifted])

    useEffect(() => {
        if (shiftPressed || capsPressed) {
            setButtonStyle(styles.button_selected);
        } else {
            setButtonStyle(styles.button);
        }
    }, [shiftPressed, capsPressed])

    const onMouseDown_handler = () => {
        onMouseDown?.();
    }

    const onMouseUp_handler = () => {
        switch (value) {
            case "shift":
                setShiftPressed(!shiftPressed);
                break;
            case "backspace":
                onBackspacePressed?.();
                break;
            case "caps":
                setCapsPressed(!capsPressed);
                break;
            case "enter":
                onEnterClicked?.();
                break;
            case "tab":
                onTabClicked?.();
                break;
            default:
                setShiftPressed(false);
                onKeyPressed?.(value);
                break;
        }
    }

    return (
        <div className={styles.component} onTouchStart={() => onMouseDown_handler()} onMouseDown={() => onMouseDown_handler()} onClick={() => onMouseUp_handler()}>
            <div className={buttonStyle} style={{ width: length * 120 }}>
                {value}
            </div>
        </div>
    );
}

export default New_KeyboardButton;