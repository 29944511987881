

import { useContext, useEffect, useRef } from 'react';
import { GlobalContext } from "../contexts/globalContext"
import io from "socket.io-client"


const SocketManager = () => {
    const { setConferencePaused, currentConference, deskData, setCurrentConference, setCurrentParticipant, setWaitingForJoining, setSharedFiles, setOpenedFiles, selectedTopicIdRef, setTopicFiles, devices, socketRef, setAllUsers, setDetailsUpcomingConference, currentUser, setNotificationUpcomingConferences, setHistoryConferences, selectedProject, setSelectedProject, setDepartmentList, setFunctionList } = useContext(GlobalContext);
    const devicesRef = useRef();
    const selectedProjectIDRef = useRef();

    const currentConferenceRef = useRef();
    const deskDataRef = useRef();

    useEffect(() => {
        currentConferenceRef.current = currentConference;
        deskDataRef.current = deskData;
    }, [currentConference, deskData])

    useEffect(() => {
        devicesRef.current = devices;
    }, [devices])

    useEffect(() => {
        selectedProjectIDRef.current = selectedProject?._id;
    }, [selectedProject])

    useEffect(() => {
        socketRef.current = io.connect(process.env.REACT_APP_CMS_URL, {
            secure: true,
            reconnect: true,
            rejectUnauthorized: true,
            query: { token: "login_token_u89wdwdjjjkdf8923eu" }
        })

        socketRef.current.on('connect', function () {

            socketRef.current.emit("GET_ALL_USERS", (users) => {
                setAllUsers(users)
            });

            socketRef.current.on('REFRESH_PROJECT', (projectId) => {
                if (selectedProjectIDRef.current && selectedProjectIDRef.current == projectId) {
                    socketRef.current.emit("GET_PROJECT_BY_ID", { projectId: selectedProjectIDRef.current, selectedTopicId: selectedTopicIdRef.current }, (project, conference, topicFiles) => {
                        setSelectedProject(project)
                        setDetailsUpcomingConference(conference)
                        //console.log(topicFiles)
                        setTopicFiles(topicFiles)

                        if (project.topics.length > 0 && !selectedTopicIdRef.current)
                            selectedTopicIdRef.current = project.topics[0]._id;

                    })
                }
            });

            socketRef.current.on('DESK_PARTICIPANT_CHANGED', function (deskId) {
                console.log("DESK_PARTICIPANT_CHANGED");
                console.log("deskId", deskId);
                console.log("deskData", deskDataRef.current);

                if (deskDataRef.current && deskDataRef.current._id == deskId) {
                    console.log("ok");
                    socketRef.current.emit("GET_NEXT_OR_ACTIVE_CONFERENCE", deskDataRef.current._id, (data) => {
                        console.log("raspuns GET_NEXT_OR_ACTIVE_CONFERENCE 4");
                        console.log(data);
                        if (!data.error && data.conference) {

                            setWaitingForJoining(true);
                            setCurrentParticipant(data.participant);
                            setCurrentConference(data.conference);
                            setConferencePaused(data.conference.paused);
                        } else {
                            setCurrentParticipant(null);
                            setCurrentConference(null);
                        }
                    });
                }
            });

            socketRef.current.on('CONFERENCE_STOPED', function (conference) {
                console.log("CONFERENCE_STOPED");
                console.log(conference);
                
                if (
                    (currentConferenceRef.current && currentConferenceRef.current._id == conference._id) ||
                    (deskDataRef.current && conference.rooms.includes(deskDataRef.current._id))) {
                    setSharedFiles([]);
                    setOpenedFiles([]);
                    setCurrentConference(null);
                    setCurrentParticipant(null);
                }
            });
        });

        return () => {
            socketRef.current.disconnect()
            socketRef.current = null
        }
    }, [])

   

    return (
        <> </>
    );
}

export default SocketManager;