import { useRef, useContext, useState, useEffect } from 'react';
import styles from './AttachedParticipantsListComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';


import InitialsCircleComponent from '../../commons/InitialsCircleComponent';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import { getUpcomingConferences } from '../../../utils/UtilitaryFunctions'
import DeleteNotificationComponent from '../common/DeleteNotificationComponent';



const AttachedParticipantsListComponent = ({ participantsFilter, allParticipantsRef, setParticipantsFilter, attachedParticipants, setAttachedParticipants, allDisplayedDesks,
    isRemote, setAttachedParticipantsRect, setDisplayedParticipants, displayedRoom }) => {

    const ref = useRef()
    const currentTargetRef = useRef()
    const deleteRef = useRef()

    const [isDragging, setIsDragging] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState()
    const [deleteClicked, setDeleteClicked] = useState(false);


    const [spaces, setSpaces] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8])

    const { socketRef, setAlertMessage, appWidth, appHeight, currentConference, videoStreams, deskData } = useContext(GlobalContext);
    const { uniqueModerators, conferenceDetailsForParticipants } = useContext(AddConferenceContext);

    const oxDivider = window.innerWidth / appWidth;
    const oyDivider = window.innerHeight / appHeight;

    const currentConferenceRef = useRef();

    useEffect(() => {
        if (conferenceDetailsForParticipants) {
            currentConferenceRef.current = conferenceDetailsForParticipants;
        } else {
            currentConferenceRef.current = currentConference;
        }


        


    }, [])

    useEffect(() => {
        if (attachedParticipants.length === spaces.length) {
            setSpaces((arr) => [...arr, attachedParticipants.length])
        }

        setAttachedParticipantsRect(ref.current.getBoundingClientRect())
    }, [attachedParticipants])


    const removeParticipant = (assignedParticipant, desk) => {
        // console.log(assignedParticipant)

        if (uniqueModerators.some(moderator => moderator._id === assignedParticipant.participant._id)) {
            setAlertMessage({ type: "ERROR", message: "You can't remove a participant that is a moderator of the conference." })
        } else if (assignedParticipant.isOnline) {
            // console.log("online")
            handleDelete(assignedParticipant, desk)
        } else {
            socketRef.current.emit("CONFERENCE_REMOVE_PARTICIPANT_FROM_DESK", {
                attachedParticipantId: assignedParticipant._id
            }, callback => {
                if (!callback.error) {
                    if (desk) {
                        socketRef.current.emit("DESK_PARTICIPANT_CHANGED", desk._id);
                    }
                    setAttachedParticipants(callback.response)
                    allParticipantsRef.current = [...allParticipantsRef.current, assignedParticipant.participant]
                    if (participantsFilter === "ALL") {
                        setDisplayedParticipants((prevParticipants) => [
                            ...prevParticipants,
                            assignedParticipant.participant
                        ]);
                    }
                    setParticipantsFilter('ALL')
                }
            })
        }
    }

    function deleteParticipant(assignedParticipant, desk) {
        socketRef.current.emit("CONFERENCE_REMOVE_PARTICIPANT_FROM_DESK", {
            attachedParticipantId: assignedParticipant._id
        }, callback => {
            if (!callback.error) {
                if (desk) {
                    socketRef.current.emit("DESK_PARTICIPANT_CHANGED", desk._id);
                }
                setAttachedParticipants(callback.response)
                allParticipantsRef.current = [...allParticipantsRef.current, assignedParticipant.participant]
                if (participantsFilter === "ALL") {
                    setDisplayedParticipants((prevParticipants) => [
                        ...prevParticipants,
                        assignedParticipant.participant
                    ]);
                }
                setParticipantsFilter('ALL')
                setDeleteClicked()
            }
        })

    }

    useEffect(() => {
        if (isDragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
            window.addEventListener("touchmove", handleMouseMove);
            window.addEventListener("touchend", handleMouseUp);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("touchmove", handleMouseMove);
            window.removeEventListener("touchend", handleMouseUp);
        };
    }, [isDragging]);

    const handleMouseDown = (e) => {

        if (currentTargetRef.current !== e.currentTarget) {
            currentTargetRef.current = e.currentTarget.parentNode
        }

        setIsDragging(true);

        if (e.touches) {
            currentTargetRef.current.startX = e.touches[0].clientX;
            currentTargetRef.current.startY = e.touches[0].clientY;

            currentTargetRef.current.lastX = e.touches[0].clientX;
            currentTargetRef.current.lastY = e.touches[0].clientY;
        } else {
            currentTargetRef.current.startX = e.clientX;
            currentTargetRef.current.startY = e.clientY;

            currentTargetRef.current.lastX = e.clientX;
            currentTargetRef.current.lastY = e.clientY;
        }


        currentTargetRef.current.style.zIndex = GetMaxZIndex() + 1

        let boundingBox = currentTargetRef.current.getBoundingClientRect();
        currentTargetRef.current.style.position = 'fixed';


        currentTargetRef.current.style.left = String(boundingBox.x / oxDivider) + "px";
        currentTargetRef.current.style.top = String(boundingBox.y / oyDivider) + "px";


    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        let newX = 0
        let newY = 0

        if (e.touches) {
            newX = (e.touches[0].clientX - currentTargetRef.current.startX) / oxDivider;
            newY = (e.touches[0].clientY - currentTargetRef.current.startY) / oyDivider;

            currentTargetRef.current.lastX = e.touches[0].clientX;
            currentTargetRef.current.lastY = e.touches[0].clientY;
        } else {
            newX = (e.clientX - currentTargetRef.current.startX) / oxDivider;
            newY = (e.clientY - currentTargetRef.current.startY) / oyDivider;

            currentTargetRef.current.lastX = e.clientX;
            currentTargetRef.current.lastY = e.clientY;
        }

        currentTargetRef.current.style.transform = `translate(${newX}px, ${newY}px)`;
    };

    const handleMouseUp = (e) => {
        setIsDragging(false);

        let mouseX = currentTargetRef.current.lastX;
        let mouseY = currentTargetRef.current.lastY;


        const targetRect = ref.current.getBoundingClientRect();

        if (isOverlapping(mouseX, mouseY, targetRect)) {
            if (isRemote) {
                return;
            }

            const boxHeight = targetRect.height / displayedRoom.totalDesks;

            const relativeY = mouseY - targetRect.top;

            const boxNumber = Math.floor(relativeY / boxHeight) + 1;

            if (!attachedParticipants.some((participant) => participant.desk?.number === boxNumber)) {
                deskChanged(boxNumber)
                return;
            }
        }

        currentTargetRef.current.style.transform = `translate(${0}px, ${0}px)`;

        currentTargetRef.current.style.position = 'relative';
        currentTargetRef.current.style.left = "0px";
        currentTargetRef.current.style.top = "0px";

        currentTargetRef.current.style.zIndex = 0

    };

    const isOverlapping = (mouseX, mouseY, rect) => {
        return (
            mouseX >= rect.left &&
            mouseX <= rect.right &&
            mouseY >= rect.top &&
            mouseY <= rect.bottom
        );
    };

    const deskChanged = (boxNumber) => {
        const availableDesk = allDisplayedDesks.find(
            (desk) => desk.number === boxNumber
        );

        // cautam daca unul dintre participanti este online
        let switchParticipant = attachedParticipants.find(
            (attached) => attached.desk?._id === availableDesk._id || attached.desk === availableDesk._id
        );

        if (switchParticipant) {
            switchParticipant.isOnline = switchParticipant?.desk._id === deskData?._id && currentConference?.status === "Active" ? true : false;

            const videos = videoStreams.find((stream) => stream.participant._id === switchParticipant?.participant._id)

            if (videos) {
                switchParticipant.isOnline = true
            }
        }

        if (switchParticipant?.isOnline || selectedParticipant.isOnline) {
            setAlertMessage({ type: "ERROR", message: "One or both of the participants are already in the conference." });
            currentTargetRef.current.style.transform = `translate(${0}px, ${0}px)`;

            currentTargetRef.current.style.position = 'relative';
            currentTargetRef.current.style.left = "0px";
            currentTargetRef.current.style.top = "0px";

            currentTargetRef.current.style.zIndex = 0
            return;
        }

        socketRef.current.emit("CONFERENCE_SWITCH_PARTICIPANTS_ON_DESKS", {
            deskId1: selectedParticipant.desk._id,
            deskId2: availableDesk._id,
            conferenceId: currentConferenceRef.current._id
        }, callback => {
            if (!callback.error) {
                setAttachedParticipants(callback.response)

                if (selectedParticipant.desk._id) {
                    socketRef.current.emit("DESK_PARTICIPANT_CHANGED", selectedParticipant.desk._id);
                }

                if (availableDesk._id) {
                    socketRef.current.emit("DESK_PARTICIPANT_CHANGED", availableDesk._id);
                }

                currentTargetRef.current.style.transform = `translate(${0}px, ${0}px)`;

                currentTargetRef.current.style.position = 'relative';
                currentTargetRef.current.style.left = "0px";
                currentTargetRef.current.style.top = "0px";

                currentTargetRef.current.style.zIndex = 0
            }
        })

    }

    const handleDelete = (assignedParticipant, desk) => {
        if (!deleteRef.current) return;

        const rect = deleteRef.current.getBoundingClientRect();

        // console.log("afisare")

        setDeleteClicked({
            shown: true,
            funct: () => deleteParticipant(assignedParticipant, desk),
            name: "Participant",
            x: rect.x + 30,
            y: rect.y + 20,
            onClose: () => setDeleteClicked(),
        });
    };



    return (
        <div ref={ref} className={styles.addedParticipantsList}>
            {!isRemote && allDisplayedDesks.map((desk) => {
                let assignedParticipant = attachedParticipants.find(
                    (attached) => attached.desk?._id === desk._id || attached.desk === desk._id
                );

                if (assignedParticipant) {
                    assignedParticipant.isOnline = assignedParticipant?.desk._id === deskData?._id && currentConference?.status === "Active" ? true : false;

                    const videos = videoStreams.find((stream) => stream.participant._id === assignedParticipant?.participant._id)

                    if (videos) {
                        assignedParticipant.isOnline = true
                    }
                }

                // console.log(assignedParticipant)

                return (
                    <div className={styles.positionCard} key={desk._id} style={{
                        opacity: assignedParticipant ? 1 : 0.4,
                        backgroundColor: assignedParticipant ? "transparent" : ""
                    }}>
                        {assignedParticipant ? (<div className={styles.assignedParticipant} style={{
                            width: appWidth * 0.26 * 0.595,
                            backgroundColor: assignedParticipant.isOnline ? "#992502" : ""
                        }}>
                            <div
                                onMouseDown={(e) => {
                                    handleMouseDown(e)
                                    setSelectedParticipant(assignedParticipant)
                                }}
                                onTouchStart={(e) => {
                                    handleMouseDown(e)
                                    setSelectedParticipant(assignedParticipant)
                                }}
                            >
                                <InitialsCircleComponent name={assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"}
                                    style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10, marginTop: 20 }} />
                            </div>

                            <div className={styles.participantDetails}>
                                {assignedParticipant.participant.role}
                                <div className={styles.participantName}>{assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"} </div>
                                {assignedParticipant.participant.function?.name} {assignedParticipant.participant.function ? "|" : ""} {assignedParticipant.participant.department?.name}
                            </div>
                            <div className={styles.assignedDeskNumber} style={{ zIndex: 10 }}>{desk.number}</div>
                            <FontAwesomeIcon ref={deleteRef} className={styles.iconRemove} icon={byPrefixAndName.fas['xmark']} onClick={() => removeParticipant(assignedParticipant, desk)} />
                        </div>
                        ) : (<div className={styles.numberedCircle}>{desk.number}</div>)}
                    </div>
                );
            })}
            {isRemote && spaces.map((space) => {
                let assignedParticipant = null

                if (space < attachedParticipants.length) {
                    assignedParticipant = attachedParticipants[space]
                }

                return (
                    <div className={styles.positionCard} key={space} style={{ opacity: assignedParticipant ? 1 : 0.4 }}>
                        {assignedParticipant && <div className={styles.assignedParticipant} >
                            <InitialsCircleComponent name={assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"}
                                style={{ marginLeft: 25, height: 121, width: 121, border: "6px solid #0D0032", marginBottom: 10, marginTop: 20 }} />
                            <div className={styles.participantDetails}>
                                {assignedParticipant.participant.role}
                                <div className={styles.participantName}>{assignedParticipant.participant.name ? assignedParticipant.participant.name : "Test test"} </div>
                                {assignedParticipant.participant.function?.name} {assignedParticipant.participant.function ? "|" : ""} {assignedParticipant.participant.department?.name}
                            </div>
                            <FontAwesomeIcon className={styles.iconRemove} icon={byPrefixAndName.fas['xmark']} onClick={() => removeParticipant(assignedParticipant)} />
                        </div>}
                    </div>
                );
            })}
            {deleteClicked?.shown == true && <DeleteNotificationComponent deleteClicked={deleteClicked} setDeleteClicked={setDeleteClicked} />}

        </div>

    );

}

export default AttachedParticipantsListComponent;