import { useRef, useContext, useState } from 'react';
import styles from './NewPresentationComponentPopup.module.css'

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from "../../contexts/projectDetailsContext";
import { AddConferenceContext } from "../../contexts/addConferenceContext";

import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import PopupBodyComponent from '../popup/common/PopupBodyComponent';
import PopupBaseComponent from '../popup/common/PopupBaseComponent';
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import RightBodyComponent from '../popup/common/RightBodyComponent';
import LeftBodyComponent from '../popup/common/LeftBodyComponent';

import addPresentationIcon from '../../graphics/Popups/Icon_Presentation_ADD.png'
import FooterButtonsComponent from '../popup/common/FooterButtonsComponent';

import New_KeyboardComponent from '../../components/keyboard/New_KeyboardComponent';
import New_InputTextComponent from '../../components/keyboard/New_InputTextComponent';

const NewPresentationComponentPopup = () => {

    const inputRef = useRef()
    const keyboardRef = useRef()
    const [isOpaque, setIsOpaque] = useState(true)

    const { setCreateNewPresentation, editConference, setEditConference } = useContext(ProjectDetailsContext);

    const { socketRef, selectedProject, appWidth, appHeight } = useContext(GlobalContext);

    const { setSeeDateTime, setUnsaved, setSave } = useContext(AddConferenceContext)

    function addPresentation() {
        socketRef.current.emit("UPDATE_CONFERENCE", {
            projectId: selectedProject._id,
            conferenceId: null,
            newData: {
                name: inputRef.current.value,
                scheduleDate: new Date(),
                duration: 15,
                type: 'Presentation',
            }
        }, (data) => {
            socketRef.current.emit("GET_CONFERENCE_BY_ID", editConference ? editConference._id : data.response._id, (callback) => {
                setEditConference(callback.response)
                setCreateNewPresentation()
                setSeeDateTime(false)
                setUnsaved(false)
                setSave(false)
            });
        })
    }

    return (
        <PopupBaseComponent>
            <New_KeyboardComponent ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 2 / 3} width="2140px" height="610px" s="1" />

            <PopupBodyComponent style={{ width: 2300, height: 942, top: "12%" }}>
                <ClosePageButtonComponent setClose={() => setCreateNewPresentation(false)} />
                <LeftBodyComponent image={addPresentationIcon} title="New Presentation" description="Create a new presentation to organize and share insights, data, or ideas with your audience, using visual media." />
                <RightBodyComponent>
                    <div className={styles.title}>Presentation title</div>
                    <New_InputTextComponent ref={inputRef} keyboardRef={keyboardRef} value="" placeHolder="Enter presentation title here" styleType='POPUP' width="1260px" fontSize="40px" height="80px"
                        iconPosition="right" icon={byPrefixAndName.fas["pen"]} withDelete={true}
                        onChange={() => {
                            if (inputRef.current?.value === "") {
                                setIsOpaque(true)
                            } else {
                                setIsOpaque(false)
                            }
                        }}
                    />
                    <FooterButtonsComponent
                        lightText={"Create"}
                        lightClick={isOpaque ? () => { } : addPresentation}
                        darkClick={setCreateNewPresentation}
                        isOpaque={isOpaque}
                        style={{ bottom: 388 }} />
                </RightBodyComponent>
            </PopupBodyComponent>

        </PopupBaseComponent>
    );
}

export default NewPresentationComponentPopup;