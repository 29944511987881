import { useContext, useEffect, useRef, useState } from 'react';

import styles from './NewHourPickerComponent.module.css'

import { newGenerateHourList, GetMaxZIndex } from '../../../utils/UtilitaryFunctions';

import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { GlobalContext } from '../../../contexts/globalContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import DarkButtonComponent from '../../commons/DarkButtonComponent';


const NewHourPickerComponent = ({ selectedDate, startHour, setStartHour, endHour, setEndHour, duration, originalStartHourRef, originalEndHourRef, endPassedMidnight }) => {

    const ref = useRef()
    const selectedHourRef = useRef()
    const movingHoursForSelectionRef = useRef()
    const conferencesDisplayRef = useRef()

    const [isDragging, setIsDragging] = useState(false)

    const { editConference } = useContext(ProjectDetailsContext);
    const { appWidth, setAlertMessage } = useContext(GlobalContext)
    const { dataChanged, selectedRooms, setSelectedRooms, dayConferences } = useContext(AddConferenceContext)

    const hourWidth = 204 // 204 este latimea unui dreptunghi cu ora

    const numberHoursOnScreen = parseInt(appWidth / 2 / hourWidth)

    const [selectedHourStartPos, setSelectedHourStartPos] = useState(appWidth / 2 - hourWidth / 2)
    const [selectedHourEndPos, setSelectedHourEndPos] = useState(appWidth / 2 + hourWidth / 2)

    const oxDivider = window.innerWidth / appWidth;

    const hourList = newGenerateHourList();

    useEffect(() => {
        if (isDragging) return

        let startMinutes = toMinutes(startHour);
        let endMinutes = toMinutes(endHour);

        if (endPassedMidnight.current) {
            endMinutes += 24 * 60;
            if(startMinutes === 0){
                startMinutes = 24 * 60
            }
        }

        const middleMinutes = (startMinutes + endMinutes) / 2;

        translateHours(middleMinutes)

        cutSelectedHours(startMinutes, endMinutes)

    }, [startHour, endHour]);

    useEffect(() => {
        let rooms = []

        for (let i = 0; i < dayConferences.length; i++) {
            let element = dayConferences[i]
            let id = ""
            if (element.roomId !== undefined) {
                id = element.roomId
            } else {
                id = element.room._id
            }
            rooms.push(id)
        }

        setSelectedRooms(rooms)
    }, [dayConferences]);

    useEffect(() => {

        let durationInMinutes = toMinutes(duration);

        if (selectedHourRef.current)
            selectedHourRef.current.style.width = `${durationInMinutes * hourWidth / 60}px`

        setSelectedHourStartPos(appWidth / 2 - durationInMinutes * hourWidth / 120)
        setSelectedHourEndPos(appWidth / 2 + durationInMinutes * hourWidth / 120)

    }, [duration]);

    useEffect(() => {
        if (isDragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
            window.addEventListener("touchmove", handleMouseMove);
            window.addEventListener("touchend", handleMouseUp);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("touchmove", handleMouseMove);
            window.removeEventListener("touchend", handleMouseUp);
        };
    }, [isDragging]);

    function translateHours(middleMinutes) {

        const numberMinutesOnScreen = numberHoursOnScreen * 60
        const marginLeft = appWidth / 2 - numberHoursOnScreen * hourWidth

        const diffMinutes = middleMinutes - numberMinutesOnScreen
        const diffMargin = diffMinutes / 60 * hourWidth

        translateRefs(marginLeft - diffMargin)

        ref.current.lastX = (marginLeft - diffMargin) * oxDivider
    }

    const toMinutes = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    };

    const handleStartDragging = (e) => {

        setIsDragging(true);

        if (e.touches) {
            ref.current.startX = e.touches[0].clientX - ref.current.lastX;
        } else {
            ref.current.startX = e.clientX - ref.current.lastX;
        }


    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        let newX = 0

        if (e.touches) {
            newX = (e.touches[0].clientX - ref.current.startX) / oxDivider;
            ref.current.lastX = e.touches[0].clientX - ref.current.startX;
        } else {
            newX = (e.clientX - ref.current.startX) / oxDivider;
            ref.current.lastX = e.clientX - ref.current.startX;
        }

        if (selectedHourStartPos < newX) newX = selectedHourStartPos
        if (hourWidth * 32 + newX < selectedHourEndPos) newX = selectedHourEndPos - hourWidth * 32
        if (hourWidth * 24 + newX < selectedHourStartPos) newX = selectedHourStartPos - hourWidth * 24

        translateRefs(newX)

        changeInterval(newX)
    };

    const handleMouseUp = () => {
        setIsDragging(false);

        if (originalStartHourRef.current[1] % 5 !== 0) {
            let [startHours, startMin] = originalStartHourRef.current;
            let [endHours, endMin] = originalEndHourRef.current;

            let startMinutes = Math.round(startMin / 5) * 5

            let diffMinFromRounding = startMin - startMinutes
            let posDiffFromRounding = diffMinFromRounding / 60 * hourWidth

            if (startMinutes === 60) {
                startMinutes = 0
                startHours += 1

                if (startHours === 24) {
                    startHours = 0
                }
            }

            let endMinutes = Math.round(endMin / 5) * 5

            if (endMinutes === 60) {
                endMinutes = 0
                endHours += 1

                if (endHours === 24) {
                    endHours = 0
                }
            }

            let newX = ref.current.lastX / oxDivider - posDiffFromRounding

            translateRefs(newX)

            originalStartHourRef.current = [startHours, startMinutes]
            setStartHour(`${String(startHours).padStart(2, "0")}:${String(startMinutes).padStart(2, "0")}`)

            originalEndHourRef.current = [endHours, endMinutes]
            setEndHour(`${String(endHours).padStart(2, "0")}:${String(endMinutes).padStart(2, "0")}`)
        }
    };

    function changeInterval(newX) {
        const marginLeft = appWidth / 2 - numberHoursOnScreen * hourWidth
        const diffWidth = newX - marginLeft

        const diffMin = - diffWidth / hourWidth * 60

        const currentMin = 12 * 60 + diffMin

        let durationInMin = toMinutes(duration);

        const startInMin = currentMin - durationInMin / 2

        let startHours = parseInt(startInMin / 60)
        let startMin = parseInt(startInMin % 60)

        const endInMin = currentMin + durationInMin / 2

        let endHours = parseInt(endInMin / 60)
        let endMin = parseInt(endInMin % 60)

        if (endHours > 24) {
            endHours = endHours - 24
        }

        if (startHours === 24) {
            startHours = 0
        }

        originalStartHourRef.current = [startHours, startMin]
        setStartHour(`${String(startHours).padStart(2, "0")}:${String(startMin).padStart(2, "0")}`)

        originalEndHourRef.current = [endHours, endMin]
        setEndHour(`${String(endHours).padStart(2, "0")}:${String(endMin).padStart(2, "0")}`)

        cutSelectedHours(startInMin, endInMin)
    }

    function cutSelectedHours(startMinutes, endMinutes) {
        const startPos = startMinutes * hourWidth / 60
        const endPos = endMinutes * hourWidth / 60

        movingHoursForSelectionRef.current.style.clipPath = `polygon(${startPos}px 0%, ${endPos}px 0%, ${endPos}px 100%, ${startPos}px 100%)`
    }

    function selectRoom(element) {
        if (isRoomWithParticipants(element.roomId)) {
            setAlertMessage({ type: "ERROR", message: "There are partcipants attached to this room. Please remove them before deselecting the room." });
        } else {
            dataChanged.current = true
            let id = ""
            if (element.roomId !== undefined) {
                id = element.roomId
            } else {
                id = element.room._id
            }
            setSelectedRooms((selectedRooms) => {
                if (!selectedRooms.includes(id)) {
                    return [...selectedRooms, id]
                }
                return selectedRooms.filter((roomId) => roomId !== id);
            })
        }

    }

    const getNextDayFormatted = () => {
        const currentDay = new Date(selectedDate);

        const nextDay = new Date(currentDay);
        nextDay.setDate(currentDay.getDate() + 1);

        const day = nextDay.getDate();
        const month = nextDay.toLocaleString('en-US', { month: 'short' });
        const year = nextDay.getFullYear();

        return `${day}.${month}.${year}`;
    };

    function isRoomWithParticipants(roomId) {
        if (editConference) {
            let roomWithParticipants = editConference.rooms.find((room) => room.withParticipants === true && room._id === roomId)

            if (roomWithParticipants) return true
        }
        return false
    }

    function translateRefs(x){
        ref.current.style.transform = `translate(${x}px, 0px)`
        movingHoursForSelectionRef.current.style.transform = `translate(${x}px, 0px)`
        conferencesDisplayRef.current.style.transform = `translate(${x}px, 0px)`
    }

    return (
        <div style={{ position: "relative", userSelect: "none" }}>
            {dayConferences.map((element, index) => <div key={index} className={styles.detailsLine} style={{ marginTop: index > 0 ? 50 : 30, }}>
                <div className={styles.roomDetails} style={{ zIndex: GetMaxZIndex() + 1 }}>
                    <div className={styles.roomLocation}>
                        {element.room.location.name}
                    </div>
                    <div className={styles.roomName}>
                        {element.roomName}
                    </div>
                </div>

                <DarkButtonComponent onClick={() => selectRoom(element)} style={{
                    position: "absolute", right: 25, top: 10, width: 238,
                    backgroundColor: selectedRooms.includes(element.roomId) ? "" : "#33FFC8",
                    color: selectedRooms.includes(element.roomId) ? "" : "#060020",
                    opacity: isRoomWithParticipants(element.roomId) ? 0.5 : 1,
                    zIndex: GetMaxZIndex() + 1
                }}>
                    {selectedRooms.includes(element.roomId) ? "Deselect" : "Select"}
                </DarkButtonComponent>
            </div>)}

            <div ref={ref} className={styles.movingHours} style={{ transform: "translate(0px,0px)" }} onMouseDown={handleStartDragging} onTouchStart={handleStartDragging}>
                {dayConferences.map((element, index) => <div key={index} className={styles.hours}>
                    {hourList.map((time, index) => {
                        const [hours, minutes] = time.split(':');
                        return (
                            <div className={styles.time} key={index} style={{
                                opacity: selectedRooms.includes(element.roomId) ? (index >= 24 ? 0.7 : 1) : (index >= 24 ? 0.2 : 0.4)
                            }}>
                                {index === 24 && <div className={styles.nextDay}>{getNextDayFormatted()}</div>}
                                {hours}<div className={styles.minutes}>:{minutes}</div>
                            </div>
                        )
                    })}
                </div>)}
            </div>

            <div ref={selectedHourRef} className={styles.selectedHourBigBox}>
                {dayConferences.map((element, index) => <div className={styles.selectedHour} key={index} style={{
                    opacity: selectedRooms.includes(element.roomId) ? 1 : 0
                }} />)}
            </div>

            <div ref={movingHoursForSelectionRef} className={styles.movingHoursForSelection} style={{ transform: "translate(0px,0px)" }}>
                {dayConferences.map((element, index) => <div key={index} className={styles.hours}>
                    {hourList.map((time, index) => {
                        const [hours, minutes] = time.split(':');
                        return (
                            <div className={styles.time} key={index} style={{
                                background: 'transparent',
                                color: "#050020",
                                borderColor: "#050020",
                                opacity: selectedRooms.includes(element.roomId) ? 1 : 0
                            }}>
                                {index === 24 && <div className={styles.nextDay}>{getNextDayFormatted()}</div>}
                                {hours}<div className={styles.minutes}>:{minutes}</div>
                            </div>
                        )
                    })}
                </div>)}
            </div>
            <div ref={conferencesDisplayRef} className={styles.conferencesDisplay} style={{ transform: "translate(0px,0px)" }}>
                {dayConferences.map((element, index) => <div key={index} className={styles.conferencesPerRoom}>
                    {element.conferences.map((conference) => {
                        if (conference.type !== "Presentation") {
                            const widthConf = conference.duration / 60 * hourWidth

                            const confDate = new Date(conference.scheduleDate)
                            const posConf = (confDate.getHours() + confDate.getMinutes() / 60) * hourWidth

                            return (
                                <div key={conference._id} className={styles.existingConference} style={{ left: posConf, width: widthConf }} />
                            )
                        }
                    })}
                </div>)}
            </div>
        </div>
    );

}

export default NewHourPickerComponent;