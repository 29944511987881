import { useRef, useContext, useState, useEffect } from 'react';
import styles from './AddGuestsComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/Icon Members Add.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import New_KeyboardComponent from '../../keyboard/New_KeyboardComponent';
import New_InputTextComponent from '../../keyboard/New_InputTextComponent';

import New_DropDownComponent from '../../keyboard/New_DropDownComponent';
import CountriesJson from '../../../graphics/country.json';

const AddGuestComponent = () => {

    const inputRef = useRef()
    const keyboardRef = useRef()
    const inputRefCountry = useRef()

    const [isOpaque, setIsOpaque] = useState(true)

    const { language, selectedProject, socketRef, appWidth, appHeight } = useContext(GlobalContext);
    const { setAddGuest } = useContext(ProjectDetailsContext);


    function handleEmailAdd() {
        socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", {
            projectId: selectedProject._id,
            userData: {
                "emailString": inputRef.current.value,
                "role": 'GUEST',
                "country": inputRefCountry.current.currentChoice.value
            }
        }, (callback) => {
            //console.log("GUEST", callback)
        })
        setAddGuest(false)
    }


    return (
        <PopupBaseComponent>
            <New_KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.6} width="2140px" height="610px" s="1" />
            <PopupBodyComponent style={{ width: 2040, height: 820, top: "13%" }}>
                <ClosePageButtonComponent setClose={() => setAddGuest(false)} />
                <LeftBodyComponent givenWidth="800px" image={membersAdd} title="Add guest to project" description="Guests have limited access to the project and can participate in selected conferences." />
                <RightBodyComponent>
                    <div className={styles.title}>New Guest</div>
                    <div className={styles.subTitle}>Email</div>
                    <New_InputTextComponent ref={inputRef} keyboardRef={keyboardRef} value="" placeHolder="Enter email here" styleType='POPUP' width="1000px" height="80px"
                        fontSize="40px" iconPosition="right" withDelete={true} icon={byPrefixAndName.fas["pen"]} onChange={() => {
                            if (inputRef.current?.value === "") {
                                setIsOpaque(true)
                            } else {
                                setIsOpaque(false)
                            }
                        }} />
                    
                    <div className={styles.subTitle}>Country</div>
                    <New_DropDownComponent options={CountriesJson} ref={inputRefCountry} keyboardRef={keyboardRef} value="" placeHolder="Choose country" iconPosition="right" withDelete={true}
                        styleType='POPUP' width="1000px" height="80px" fontSize="40px" />
                    
                    <div style={{ bottom: 130, position: "absolute" }}>
                        <FooterButtonsComponent
                            lightText={"Create"}
                            lightClick={isOpaque ? () => { } : handleEmailAdd}
                            isOpaque={isOpaque}
                            darkClick={setAddGuest}
                        />
                    </div>
                </RightBodyComponent>
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default AddGuestComponent;