import { useRef, useContext, useEffect, createRef } from 'react';
import { GlobalContext } from "../../../contexts/globalContext";
import { AddConferenceContext } from "../../../contexts/addConferenceContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import styles from './FilesDetailsConferenceDetailsComponent.module.css';
import DetailsCardComponent from './DetailsCardComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent';
import imgAdd from '../../../graphics/Popups/Icon Files Add.png';
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';

const FilesDetailsConferenceDetailsComponent = () => {
    const { socketRef, appHeight, currentConference } = useContext(GlobalContext);
    const { setDeleteClicked, fileList, setFileList, openFiles, setConferenceAddFiles } = useContext(AddConferenceContext);

    const icon = (type) => {
        switch (true) {
            case type.includes("image"): return "file-image";
            case type.includes("video"): return "file-video";
            case type.includes("pdf"): return "file-pdf";
            case type.includes("word"): return "file-word";
            case type.includes("excel"): return "file-excel";
            default: return "file-lines";
        }
    };

    useEffect(() => {
        let type = openFiles.moderator ? "Topic" : "Subtopic";
        socketRef.current.emit("CONFERENCE_GET_TOPIC_ATTACHED_DOCUMENTS", { parentId: openFiles._id, type }, callback => {
            if (!callback.error) setFileList(callback.response);
        });
    }, [openFiles]);

    const reloadFiles = () => {
        let type = openFiles.moderator ? "Topic" : "Subtopic";
        socketRef.current.emit("CONFERENCE_GET_TOPIC_ATTACHED_DOCUMENTS", { parentId: openFiles._id, type }, callback => {
            if (!callback.error) setFileList(callback.response);
        });
    };

    const handleDeleteFile = (fileId) => {
        socketRef.current.emit("CONFERENCE_DELETE_DOCUMENT", {
            parentId: openFiles._id,
            refType: openFiles.moderator ? "Topic" : "Subtopic",
            conferenceFileId: fileId,
            conferenceId: currentConference
        }, () => {
            setDeleteClicked(false);
            reloadFiles();
        });
    };

    const handleDelete = (file) => {
        if (!file.ref.current) return;

        const rect = file.ref.current.getBoundingClientRect();
        setDeleteClicked({
            shown: true,
            funct: () => handleDeleteFile(file._id),
            name: "File",
            x: rect.x,
            y: rect.y,
            onClose: () => setDeleteClicked(),
        });
    };
    
    return (
        <>
            <DarkButtonComponent style={{ width: '98%', position: 'absolute', top: 92, marginBottom: 40, zIndex: GetMaxZIndex() + 1 }} onClick={() => setConferenceAddFiles(true)}>
                <FontAwesomeIcon className={styles.iconPlus} icon={byPrefixAndName.fas['plus']} /> Add files
            </DarkButtonComponent>
            <DetailsCardComponent title="Files" height={appHeight - 83} isList={fileList.length === 0 ? false : true} isBlank={fileList.length === 0 ? true : false}>
                <div style={{ width: "100%" }}>
                    <div className={styles.openFiles} style={{ justifyContent: fileList.length === 0 ? "center" : "" }}>
                        <div className={styles.noFiles} style={{ justifyContent: fileList.length === 0 ? "center" : "", alignItems: fileList.length === 0 ? "center" : "" }}>
                            {fileList.length === 0 ? (
                                <>
                                    <img src={imgAdd} alt="icon add" className={styles.imgAdd} onClick={() => setConferenceAddFiles(true)} />
                                    <div className={styles.description}>Add files to topic</div>
                                    {openFiles.name}
                                </>
                            ) : (
                                fileList.map((file, index) => {
                                    file.ref = createRef();
                                    return <div key={index} className={styles.fileCard}>
                                        <FontAwesomeIcon
                                            ref={file.ref}
                                            className={styles.iconButton}
                                            style={{ right: 10 }}
                                            icon={byPrefixAndName.fal["trash"]}
                                            onClick={(e) => { handleDelete(file) }}
                                        />
                                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas[icon(file.docsFile.type)]} />
                                        <div className={styles.fileDetails}>
                                            {file.docsFile.name}
                                            <div className={styles.fileDescription}>
                                                Added by:
                                                <div className={styles.fileAddedBy}>{file.addedBy?.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            )}
                        </div>
                    </div>
                </div>
            </DetailsCardComponent>
        </>
    );
};

export default FilesDetailsConferenceDetailsComponent;
