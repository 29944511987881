import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../contexts/globalContext";
import en from "../langs/en.json";
import ro from "../langs/ro.json";

import Lottie from 'react-lottie-player';

import styles from './LoginComponent.module.css';

import New_KeyboardComponent from './keyboard/New_KeyboardComponent';
import New_InputTextComponent from './keyboard/New_InputTextComponent';

import video from '../graphics/big wave fundal loop_6.mp4';
import LightButtonComponent from './commons/LightButtonComponent';

import enImg from '../graphics/en.svg';
import roImg from '../graphics/ro.svg';

import arrowLeft from '../graphics/arrow-left-animation.json';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

const LoginComponent = () => {

    const ref = useRef();
    const inputGuestNameRef = useRef();
    const inputVisitorNameRef = useRef();
    const inputUserNameRef = useRef();
    const inputPasswordRef = useRef();
    const keyboardRef = useRef();
    const timerForStartingConference = useRef();
    const loginUserPassFormRef = useRef();

    const deskDataRef = useRef();

    const [panelIsDown, setPanelIsDown] = useState(true);

    const { setConferencePaused, socketRef, localSocketRef, setAlertMessage, deskData, language, setLanguage, setCurrentUser, guestLogin, userLogin, appHeight, appWidth, setCurrentConference, setCurrentParticipant, setWaitingForJoining } = useContext(GlobalContext);
    
    function setGuestData() {
        if (guestLogin) {
            socketRef.current.emit("LOGIN", guestLogin, (data) => {
                if (!data.error) {
                    CloseTimeoutForStartingConference();
                    setCurrentUser(data.user);
                } else {
                    setAlertMessage({ type: "ERROR", message: "Login error: user data or password wrong" });
                }
            });            
        }
    }

    function setUserData() {
        if (inputVisitorNameRef.current.value) {
            // If visitor name is filled, log in as visitor
            var body = {
                name: inputVisitorNameRef.current.value
            };

            socketRef.current.emit("LOGIN", body, (data) => {
                if (!data.error) {
                    CloseTimeoutForStartingConference();
                    setCurrentUser(data.user);
                } else {
                    setAlertMessage({ type: "ERROR", message: "Login error: user data or password wrong" });
                }
            });            
        } else {
            // If email/password is filled, log in as user
            var body = {
                email: inputUserNameRef.current.value,
                password: inputPasswordRef.current.value
            };

            socketRef.current.emit("LOGIN", body, (data) => {
                if (!data.error) {
                    CloseTimeoutForStartingConference();
                    setCurrentUser(data.user);
                } else {
                    setAlertMessage({ type: "ERROR", message: "Login error: user data or password wrong" });
                }
            });   
        }
    }

    const CloseTimeoutForStartingConference = () => {
        if (timerForStartingConference.current) {
            clearTimeout(timerForStartingConference.current);
            timerForStartingConference.current = null;
        }
    }

    useEffect(() => {
        if (deskData) {
            socketRef.current.emit("GET_NEXT_OR_ACTIVE_CONFERENCE", deskData._id, (data) => {
                console.log("raspuns GET_NEXT_OR_ACTIVE_CONFERENCE 1");
                if (!data.error && data.conference) {
                    CloseTimeoutForStartingConference();
                    timerForStartingConference.current = setTimeout(() => {
                        setCurrentParticipant(data.participant);
                        setCurrentConference(data.conference);
                        setConferencePaused(data.conference.paused);
                    }, data.conference.millisUntilStart);
                }
            });

            socketRef.current.on('PRESENTATION_STARTED', function (conference) {
                if (deskData && conference.rooms.includes(deskData.room)) {
                    socketRef.current.emit("GET_NEXT_OR_ACTIVE_CONFERENCE", deskData._id, (data) => {
                        console.log("raspuns GET_NEXT_OR_ACTIVE_CONFERENCE 2");
                        if (!data.error && data.conference) {
                            setWaitingForJoining(true);
                            setCurrentParticipant(data.participant);
                            setCurrentConference(data.conference);
                            setConferencePaused(data.conference.paused);
                        }
                    });
                }
            });            
        }
        deskDataRef.current = deskData;

    }, [deskData])

    useEffect(() => {
        //console.log("lower_panel");
        localSocketRef.current?.emit("lower_panel");
        inputPasswordRef.current.disabled = true;


        


        return () => {
            CloseTimeoutForStartingConference();

            // socketRef.current.off('DESK_PARTICIPANT_CHANGED');
            socketRef.current.off('PRESENTATION_STARTED');
        }
    }, [])

    const VisitorOnChangeHandler = () => {
        if (inputVisitorNameRef.current.value) {
            inputUserNameRef.current.disabled = true;
            inputPasswordRef.current.disabled = true;
        } else {
            inputUserNameRef.current.disabled = false;
        }
    }

    const onFocusHandler = () => {
        if (panelIsDown) {
            //console.log("lift_panel");
            localSocketRef.current?.emit("lift_panel");
            setPanelIsDown(false);
        }
    }

    const UserOrEmailOnChangeHandler = () => {
        const emailValue = inputUserNameRef.current?.value || "";
        const passwordValue = inputPasswordRef.current?.value || "";

        if (emailValue || passwordValue) {
            inputVisitorNameRef.current.disabled = true;
        } else {
            inputVisitorNameRef.current.disabled = false;
        }

        if (emailValue.trim() === "") {
            inputPasswordRef.current.disabled = true;
            inputVisitorNameRef.current.disabled = false;
            inputPasswordRef.current.value = ""; // Clear password field
        } else {
            inputPasswordRef.current.disabled = false;
        }
    };

    return (
        <div ref={ref} className={styles.component} style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>

            <video id="background-video" autoPlay loop playsInline muted style={{ height: `${appHeight}px`, width: `${appWidth}px` }}>
                <source src={video} type="video/mp4" />
            </video>
            <New_KeyboardComponent visible ref={keyboardRef} width="2140px" height="610px" s="1" position="relative" />
            <div className={styles.inputFields}>
                <div className={styles.languageChoices}>
                    <img src={enImg} className={styles.languageButton} onClick={() => setLanguage(en)} style={{ border: language == en ? "4px solid #FFFFFF" : "" }} />
                    <img src={roImg} className={styles.languageButton} onClick={() => setLanguage(ro)} style={{ border: language == ro ? "4px solid #FFFFFF" : "" }} />
                </div>
                <div >
                    <div className={styles.headerText}>Welcome!</div>
                    <div className={styles.headerDetails}>{language.login_page.Please}</div>
                </div>

                <div className={styles.loginChoices}>
                    {guestLogin && <div className={styles.guestLogin}>
                        <New_InputTextComponent onFocus={() => onFocusHandler()} ref={inputGuestNameRef} keyboardRef={keyboardRef} value={guestLogin.name} fontSize="40px"
                            iconPosition="right" icon={byPrefixAndName.fas["user-tie"]} withDelete={true} placeHolder="Name" styleType='POPUP' width="100%" height="80px" />
                        <div className={styles.lineVisitor} style={{ marginTop: "50px" }}></div>
                        <LightButtonComponent onClick={setGuestData} style={{ width: 281, height: 82, marginTop: 50 }}>
                            <Lottie
                                loop={true}
                                play={true}
                                animationData={arrowLeft}

                            />
                            <div className={styles.loginPos}> Login</div>
                        </LightButtonComponent>
                    </div>}
                    {!guestLogin && <div className={styles.guestLogin} >
                        <div className={styles.visitorDiv} style={{ marginTop: "60px" }}>
                            <div className={styles.lineVisitor}></div>
                            <div className={styles.textChoice}>Login with an email and password</div>
                            <div className={styles.lineVisitor}></div>
                        </div>
                        <div ref={loginUserPassFormRef} style={{ width: "100%", display: "flex", flexDirection: "column" }}>

                            <New_InputTextComponent onFocus={() => onFocusHandler()} ref={inputUserNameRef} keyboardRef={keyboardRef} value={userLogin ? userLogin.email : ""} fontSize="40px"
                                iconPosition="right" icon={byPrefixAndName.fas["pen"]} withDelete={true} placeHolder="Email" styleType='POPUP' width="100%" height="80px" onChange={() => UserOrEmailOnChangeHandler()} />
                            {/* <FontAwesomeIcon className={styles.closeX} onClick={() => {inputUserNameRef.current.value = ""; inputPasswordRef.current.value = ""}} icon={(inputUserNameRef.current?.value !== "") ? byPrefixAndName.fas['close'] : byPrefixAndName.fas['pen']} /> */}

                            <New_InputTextComponent onFocus={() => onFocusHandler()} ref={inputPasswordRef} type="password" keyboardRef={keyboardRef} value="" placeHolder={language.login_page.Password} fontSize="40px"
                                styleType='POPUP' width="100%" height="80px" marginTop="40px" marginBottom="50px" onChange={() => UserOrEmailOnChangeHandler()} />                            
                        </div>

                        <div className={styles.visitorDiv}>
                            <div className={styles.lineVisitor}></div>
                            <div className={styles.textChoice}>Login as visitor</div>
                            <div className={styles.lineVisitor}></div>
                        </div>

                        <New_InputTextComponent onFocus={() => onFocusHandler()} ref={inputVisitorNameRef} keyboardRef={keyboardRef} value="" placeHolder={language.login_page.Name}
                            iconPosition="right" icon={byPrefixAndName.fas["user-tie"]} withDelete={true} fontSize="40px" styleType='POPUP' width="100%" height="80px" onChange={() => VisitorOnChangeHandler()} />


                        <div className={styles.lineVisitor} style={{ marginTop: "50px" }}></div>


                        <LightButtonComponent onClick={setUserData} style={{ width: 281, height: 82, marginTop: "60px" }}>
                            <Lottie
                                loop={true}
                                play={true}
                                animationData={arrowLeft}
                                style={{ position: "absolute", width: 60, height: 35, left: 50 }}
                            />
                            <div className={styles.loginPos}>Login</div>
                        </LightButtonComponent>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;
