import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';
import { AddConferenceContext } from '../../contexts/addConferenceContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './NewConferenceAddComponent.module.css'

import PopupBaseComponent from './common/PopupBaseComponent';
import DarkButtonComponent from '../commons/DarkButtonComponent';

import video from '../../graphics/big wave fundal loop_6.mp4'
import SymetricalTimePickerComponent from './newConfereceComponents/SymetricalTimePickerComponent';
import DurationComponent from './newConfereceComponents/DurationComponent';
import NewDatePickerComponent from './newConfereceComponents/NewDatePickerComponent';
import NewHourPickerComponent from './newConfereceComponents/NewHourPickerComponent';
import LightButtonComponent from '../commons/LightButtonComponent';

import { getUpcomingConferences } from '../../utils/UtilitaryFunctions'

import New_InputTextComponent from '../keyboard/New_InputTextComponent';
import New_KeyboardComponent from '../keyboard/New_KeyboardComponent';

import ManageParticipantsComponent from './conferenceManageParticipants/ManageParticipantsComponent';
import ConferenceSendMessagesComponent from './conferenceAddComponents/ConferenceSendMessagesComponent';


const NewConferenceAddComponent = () => {
    const titleRef = useRef()
    const keyboardRef = useRef()
    const isFirstRender = useRef(0);
    const endPassedMidnight = useRef(false)

    const originalStartHourRef = useRef([12, 0])

    const originalEndHourRef = useRef([13, 0])

    const [change, setChange] = useState(false)

    const currentDate = new Date();

    const { setCreateConference, editConference, setEditConference } = useContext(ProjectDetailsContext);
    const { socketRef, appHeight, appWidth, selectedProject, currentUser, setNotificationUpcomingConferences,  } = useContext(GlobalContext);
    const { 
        setSeeDateTime, setDayConferences, selectedRooms, conferenceDetailsForParticipants, setConferenceDetailsForParticipants,
        unsaved, setUnsaved, save, setSave,
    } = useContext(AddConferenceContext)

    const buttonStyling = { width: 358, height: 82, borderRadius: 16, font: 'normal normal 600 40px/33px Bio Sans', margin: 22, alignItems: 'center' }

    const [duration, setDuration] = useState(() => {
        if (editConference) {
            const hours = Math.floor(editConference.duration / 60);
            const mins = editConference.duration % 60;

            return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
        }
        return '01:00'
    })

    const [startHour, setStartHour] = useState(
        editConference ? new Date(editConference.scheduleDate).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) : "12:00"
    )

    const [endHour, setEndHour] = useState(() => {
        if (editConference) {
            const hourStart = new Date(editConference.scheduleDate)
            const hourEnd = new Date(hourStart.getTime() + editConference.duration * 60000);

            return hourEnd.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
        }
        return "13:00"
    })

    const [selectedDate, setSelectedDate] = useState(editConference ? new Date(editConference.scheduleDate) : currentDate)


    useEffect(() => {
        socketRef.current.emit("GET_CONFERENCES_FOR_ONE_DAY_FOR_ALL_ROOMS", selectedDate.toUTCString(), callback => {
            setDayConferences(callback)
        })
    }, [selectedDate])

    useEffect(() => {
        if (isFirstRender.current < 3) {
            isFirstRender.current += 1;
            return; 
        }
        
        if(editConference) setChange(true)

    }, [selectedDate, startHour, endHour, selectedRooms]);

    const addConference = () => {

        let formattedDate = new Date(selectedDate)
        formattedDate.setHours(startHour.split(":")[0])
        formattedDate.setMinutes(startHour.split(":")[1])

        const [hours, minutes] = duration.split(":");

        socketRef.current.emit("UPDATE_CONFERENCE", {
            projectId: selectedProject._id,
            conferenceId: editConference ? editConference._id : null,
            newData: {
                name: titleRef.current?.value,
                scheduleDate: formattedDate.toUTCString(),
                duration: parseInt(hours) * 60 + parseInt(minutes),
                type: 'Conference',
                rooms: selectedRooms
            }
        }, (data) => {
            if (!conferenceDetailsForParticipants && !editConference) {
                setConferenceDetailsForParticipants(data.response)
            } else {
                if (editConference) {
                    socketRef.current.emit("GET_CONFERENCE_BY_ID", editConference._id, (callback) => {
                        setEditConference(callback.response)
                        setSeeDateTime(false)
                        setUnsaved(false)
                        setSave(false)
                    })
                }
            }

            getUpcomingConferences(socketRef, currentUser._id, setNotificationUpcomingConferences)
        });
    };

    const closePage = () => {
        if (!change) {
            setConferenceDetailsForParticipants()
            if (editConference)
                setSeeDateTime()
            else
                setCreateConference()
        } else {
            setUnsaved(true)
        }

    }

    function closeWithoutSave() {
        setConferenceDetailsForParticipants()
        setSeeDateTime()
        setCreateConference()
        setUnsaved(false)
    }

    return (
        <PopupBaseComponent flexDirection='column'>
            {!conferenceDetailsForParticipants && <>
            <video id="background-video" className={styles.background} autoPlay loop playsInline muted style={{ height: appHeight, width: appWidth }}>
                <source src={video} type="video/mp4" />
            </video>

            <New_KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140) / 2} y={750} width="2140px" height="610px" s="1" />

            <div className={styles.title}>
                Conference title
                <New_InputTextComponent ref={titleRef} keyboardRef={keyboardRef} value={editConference ? editConference.name : ""} onChange={() => setChange(true)}
                    fontSize="160px" placeHolder="New Conference" styleType='CONFERENCE' width="2000px" marginLeft={(appWidth - 2000) / 2}/>
            </div>

            <NewDatePickerComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

            <NewHourPickerComponent selectedDate={selectedDate} startHour={startHour} setStartHour={setStartHour} endHour={endHour} setEndHour={setEndHour} duration={duration}
                originalStartHourRef={originalStartHourRef} originalEndHourRef={originalEndHourRef} endPassedMidnight={endPassedMidnight}/>

            <SymetricalTimePickerComponent startHour={startHour} setStartHour={setStartHour} endHour={endHour} setEndHour={setEndHour}
                originalStartHourRef={originalStartHourRef} originalEndHourRef={originalEndHourRef} endPassedMidnight={endPassedMidnight}/>

            <DurationComponent duration={duration} setDuration={setDuration} startHour={startHour} endHour={endHour} />

            <div className={styles.leftShadow}/>
            <div className={styles.rightShadow}/>

            <div className={styles.buttonDiv}>
                {change && <LightButtonComponent
                    style={{ 
                        width: 432, borderRadius: 8, alignItems: 'center', marginRight: 30, 
                        background: "transparent linear-gradient(90deg, #77EDDC 0%, #DBFD95 97%, #DEFE93 100%) 0% 0% no-repeat padding-box" 
                    }} 
                    onClick={addConference}
                >
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['arrow-right']} />{editConference ? "Save changes" : "Next step"}
                </LightButtonComponent>}

                <DarkButtonComponent style={{ width: 353  }} onClick={closePage}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['xmark']} />Close
                </DarkButtonComponent>
            </div></>}
            {conferenceDetailsForParticipants && <ManageParticipantsComponent/>}
            {unsaved && <ConferenceSendMessagesComponent type="unsaved">
                <LightButtonComponent style={buttonStyling} onClick={() => setSave(true)}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['save']} /> Save
                </LightButtonComponent>
                <DarkButtonComponent style={buttonStyling} onClick={closeWithoutSave}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['close']} /> Don't save
                </DarkButtonComponent>
                <DarkButtonComponent style={buttonStyling} onClick={() => setUnsaved(false)}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['close']} /> Cancel
                </DarkButtonComponent>
                
            </ConferenceSendMessagesComponent>}
            {save && <ConferenceSendMessagesComponent type="saved">
                <DarkButtonComponent style={buttonStyling} onClick={addConference}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['close']} /> Close
                </DarkButtonComponent>
            </ConferenceSendMessagesComponent>}
        </PopupBaseComponent>
    );

}

export default NewConferenceAddComponent;