import { useEffect, useState, forwardRef } from 'react';
import styles from './New_DropDownComponent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'
import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';
import New_InputTextComponent from './New_InputTextComponent';



const New_DropDownComponent = forwardRef((props, ref) => {
    var { onBlur, focused = false, currentChoice, onChange, keyboardRef, placeHolder, width, options, elementsToShowNumber = 3, width } = props;
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState(false)
    const [elementsToShow, setElementsToShow] = useState(false)


    useEffect(() => {
        setSelectedOptions(options);
        setElementsToShow(elementsToShowNumber);
        ref.current.currentChoice = currentChoice;
    }, [])

    useEffect(() => {
        if (selectedOptions.length > 3) {
            setElementsToShow(3);
        } else {
            setElementsToShow(selectedOptions.length);
        }
    }, [selectedOptions])

    const OnTextChanged = () => {
        if (ref.current.value) {
            setSelectedOptions(options.filter(element => element.name.toLowerCase().includes(ref.current.value.toLowerCase())));
        } else {
            setSelectedOptions(options);
        }
        // //console.log(xxx);
        // setSelectedOptions(xxx);
        ////console.log(ref.current.value);
    }

    return (
        <div tabIndex="0" >
            {/* <div className={isOpen ? styles.currentChoiceOpen : styles.currentChoice} onClick={() => setIsOpen(!isOpen)} style={{width}}>
                <div style={{marginLeft: marginLeftText}}>{currentChoice.text}</div>
                <FontAwesomeIcon className={styles.dropdownIcon} icon={byPrefixAndName.fal[isOpen ? "chevron-up" : "chevron-down"]} />
            </div> */}
            <New_InputTextComponent focused={focused} onBlur={() => setIsOpen(false)} ref={ref} keyboardRef={keyboardRef} value={currentChoice ? currentChoice.name : ""} placeHolder={placeHolder} iconPosition="right" withDelete={true}
                onChange={() => OnTextChanged()} onFocus={() => {setIsOpen(true); OnTextChanged();}} styleType='POPUP' width={width} height="80px" fontSize="40px" icon={byPrefixAndName.fal[isOpen ? "chevron-up" : "chevron-down"]} />

            {isOpen &&
                <div className={styles.optionsWindow} style={{ width: width, zIndex: GetMaxZIndex() + 1, height: elementsToShow * 85 }}>
                    <div className={styles.scrollContent} style={{ width: width, zIndex: GetMaxZIndex() + 1 }}>
                        {selectedOptions.map((option) => (
                            <div key={option.value} className={ref.current.currentChoice ?.name === option.name ? styles.optionSelected : styles.option} onMouseDown={() => {
                                ref.current.currentChoice = option;
                                ref.current.value = option.name;
                                // //console.log(option);
                                // //console.log(ref.current.value);
                                onChange?.();
                                
                                //setIsOpen(false);
                            }}>
                                {/* {forConf && <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal[getIcon(option.value)]} />} */}
                                <div style={{ marginLeft: "10px" }}>{option.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            }

        </div>

    );

})

export default New_DropDownComponent;