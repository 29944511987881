import { useRef, useContext, useState } from 'react';
import styles from './AddPartnersComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/Icon Members Add.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import New_InputTextComponent from '../../keyboard/New_InputTextComponent';
import New_KeyboardComponent from '../../keyboard/New_KeyboardComponent';

import New_DropDownComponent from '../../keyboard/New_DropDownComponent';
import CountriesJson from '../../../graphics/country.json';


const AddPartnersComponent = () => {

    const inputRefName = useRef()
    const inputRefEmail = useRef()
    const inputRefPass = useRef()
    const inputRefPassConf = useRef()
    const keyboardRef = useRef()
    const inputRefCountry = useRef()


    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConf, setShowPasswordConf] = useState(false)

    const [checkInputs, setCheckInputs] = useState(false)

    const { language, selectedProject, socketRef, appHeight, appWidth, setAlertMessage } = useContext(GlobalContext);
    const { setAddPartners } = useContext(ProjectDetailsContext);

    const allFieldsCompleted = () => {
        const name = inputRefName.current?.value?.trim() || "";
        const email = inputRefEmail.current?.value?.trim() || "";
        const password = inputRefPass.current?.value?.trim() || "";
        const confirmPassword = inputRefPassConf.current?.value?.trim() || "";

        return (
            name.length > 0 &&
            email.length > 0 &&
            password.length > 0 &&
            confirmPassword.length > 0
        );
    };

    const handleInputChange = () => {
        if(allFieldsCompleted()){
            setCheckInputs(true)
        }
    }

    const handleEmailAdd = () => {
        if (inputRefPass.current.value === inputRefPassConf.current.value) {
            socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", {
                projectId: selectedProject._id,
                userData: {
                    "name": inputRefName.current.value,
                    "email": inputRefEmail.current.value,
                    "password": inputRefPass.current.value,
                    "role": "PARTNER",
                    "country": inputRefCountry.current.currentChoice.value
                }
            }, (callback) => {
                //console.log("PARTNER", callback);
                setAddPartners(false);
            });
        } else {
            setAlertMessage({ type: "ERROR", message: "Passwords do not match. Please ensure password and Confirm Password fields are identical." });
        }
    }

    return (
        <PopupBaseComponent>
            <New_KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.66} width="2140px" height="610px" s="1" />
            <PopupBodyComponent style={{ width: 2688, height: 860, top: "13%" }}>
                <ClosePageButtonComponent setClose={() => setAddPartners(false)} />
                <LeftBodyComponent givenWidth="800px" image={membersAdd} title="Add partner to project" description="Create and add a new partner." />
                <RightBodyComponent>
                    <div className={styles.title}>New Partner</div>

                    <div className={styles.bodyDetails}>
                        <div className={styles.column}>
                            <div className={styles.subTitle}>Name</div>
                            <New_InputTextComponent ref={inputRefName} keyboardRef={keyboardRef} value="" icon={byPrefixAndName.fas["pen"]} iconPosition="right" withDelete={true}
                                onChange={handleInputChange} placeHolder="Enter name here" styleType='POPUP' width="800px" height="80px" fontSize="40px"  />

                            <div className={styles.subTitle}>Password</div>
                            <New_InputTextComponent type="password" ref={inputRefPass} keyboardRef={keyboardRef} value="" placeHolder="Enter password here" iconPosition="right" withDelete={true}
                                onChange={handleInputChange} styleType='POPUP' width="800px" height="80px" fontSize="40px"/>
                            
                            <div className={styles.subTitle}>Country</div>
                            <New_DropDownComponent options={CountriesJson} ref={inputRefCountry} keyboardRef={keyboardRef} value="" placeHolder="Choose country" iconPosition="right" withDelete={true}
                                styleType='POPUP' width="800px" height="80px" fontSize="40px" />
 
                        </div>

                        <div className={styles.column} style={{ paddingLeft: 40, position: "fixed", left: "calc(66%)" }}>
                            <div className={styles.subTitle}>Email</div>
                           <New_InputTextComponent ref={inputRefEmail} keyboardRef={keyboardRef} value="" placeHolder="Enter email here" iconPosition="right" withDelete={true}
                                onChange={handleInputChange} styleType='POPUP' width="800px" height="80px" fontSize="40px" icon={byPrefixAndName.fas["pen"]}/>

                            <div className={styles.subTitle}>Confirm Password</div>
                            <New_InputTextComponent type={showPasswordConf ? "text" : "password"} ref={inputRefPassConf} keyboardRef={keyboardRef} value="" placeHolder="Re-enter password here" iconPosition="right" withDelete={true}
                                onChange={handleInputChange} styleType='POPUP' width="800px" height="80px" fontSize="40px" icon={byPrefixAndName.fas[showPasswordConf ? 'eye-slash' : 'eye']} />

                        </div>
                    </div>
                    <div className={styles.explanation}>⚠️ All fields are mandatory</div>
                    <div style={{ marginLeft: 20 }}>
                        <FooterButtonsComponent 
                            lightText={"Create"} 
                            isOpaque={!checkInputs} 
                            lightClick={checkInputs ? handleEmailAdd : () => {}} 
                            darkClick={setAddPartners} 
                        />
                    </div>

                    
                </RightBodyComponent>
            </PopupBodyComponent>
        </PopupBaseComponent>
    );
}

export default AddPartnersComponent;
