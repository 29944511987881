import { useRef, useEffect } from 'react';
import styles from './UserDetailsComponent.module.css'

import { GlobalContext } from '../contexts/globalContext';
import { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import DarkButtonComponent from './commons/DarkButtonComponent';

import userBoxBig from '../graphics/user-box-1.svg'
import userBoxSmall from '../graphics/user-box-2.svg'


const UserDetailsComponent = ({setSeeUserDetails}) => {
    
    const ref = useRef();

    const { socketRef, currentUser, setCurrentUser, setSelectedProject, setUserDetailsComponentTimer} = useContext(GlobalContext)

    useEffect(() => {
        ref.current.focus();
    }, []);
    
    function handleLogOut() {
        socketRef.current.emit("LOGOUT", currentUser.token, () => {
            setCurrentUser()
            setSelectedProject()
            setSeeUserDetails()
        });        
    }

    function getIcon(value) {
        switch (value) {
            case "ADMINISTRATOR":
                return "user-gear";
            case "MEMBER":
                return "user";
            case "VISITOR":
                return "adress-card";
            case "PARTNER":
                return "user-tie";
            default:
                return "id-card-clip";
        }
    }


    return (
        <div ref={ref} className={styles.component} tabIndex="0" 
            onBlur={() => {
                setSeeUserDetails(false); 
                setUserDetailsComponentTimer(true)
            }}
        >
            <img src={currentUser.function ? userBoxBig : userBoxSmall} className={styles.background} alt="Background"/>
            <div className={styles.title}>
                <div className={styles.iconBackground} style={{borderColor: currentUser.role === 'ADMINISTRATOR' ? "#EA46FF" : "#28CCA0"}}/>
                <div className={styles.iconCircle}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas[getIcon(currentUser.role)]} />
                </div>
                <div className={styles.nameDetails}>
                    {currentUser.name}
                    <div className={styles.role} style={{color: currentUser.role === 'ADMINISTRATOR' ? "#EA46FF" : "#28CCA0"}}>
                        {currentUser.role}
                    </div>
                </div>
            </div>
            <div className={styles.details} style={{padding: currentUser.function ? "" : 0, borderBottom: currentUser.function ? "" : "0px solid"}}>
                <div className={styles.function}>
                    {currentUser.function?.name}
                </div>
                {currentUser.department?.name}
            </div>
            
            <DarkButtonComponent style={{position: "relative", marginTop: currentUser.function ? 30 : 35, marginLeft: 125, marginBottom: 23, height: 71, 
                font: "normal normal 600 32px/70px Bio Sans"}}  onClick={() => {}}>
                <FontAwesomeIcon className={styles.iconLogout} icon={byPrefixAndName.fas['arrow-right-from-bracket']} />
                SIGN OUT
            </DarkButtonComponent>
            <div style={{position: "absolute", marginTop: currentUser.function ? 30 : 35, left: 125, bottom: 23, height: 77, width: 575, backgroundColor: "transparent", cursor: "pointer"}}  
                onClick={() => handleLogOut()}/>
        </div>

        
    );

}

export default UserDetailsComponent;