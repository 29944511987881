import { useRef, useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../../../contexts/globalContext"
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { AddConferenceContext } from "../../../contexts/addConferenceContext"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import styles from './DateTimeDetailsComponent.module.css'

import DetailsCardComponent from './DetailsCardComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent'





const DateTimeDetailsComponent = () => {
    const { currentUser } = useContext(GlobalContext)
    const { editConference } = useContext(ProjectDetailsContext)
    const { seeDateTime, setSeeDateTime } = useContext(AddConferenceContext)

    const scheduledDate = new Date(editConference.scheduleDate)
    const formattedDate = scheduledDate.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "2-digit",
        month: "short"
    });

    const formatStartHour = scheduledDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })

    const endDate = new Date(scheduledDate.getTime() + editConference.duration * 60000);

    const formatEndHour = endDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });


    const formatDuration = (() => {
        const hours = Math.floor(editConference.duration / 60);
        const remainingMinutes = editConference.duration % 60;

        if (remainingMinutes !== 0)
            return `${hours}h ${remainingMinutes}m`;
        return `${hours}h`;

    })()


    return (
        <DetailsCardComponent title={editConference.type != "Presentation" ? "Date and time" : "Locations"} isList={false}>

            <div style={{ width: "100%" }}>

                {editConference.type != "Presentation" &&
                    <>
                        <div className={styles.line}>
                            <div className={styles.infoBox} style={{ width: "50%", color: "#33FFC8" }}>
                                <div className={styles.infoDescription}><FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fal['calendar']} />Date</div>
                                {formattedDate} - {scheduledDate.getFullYear()}
                            </div>
                            <div className={styles.infoBox} style={{ width: "calc(50% - 65px)", color: "#33FFC8", borderRight: "0px", paddingLeft: 65 }}>
                                <div className={styles.infoDescription}><FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fal['calendar-clock']} />Duration</div>
                                {formatDuration}
                            </div>
                        </div>
                        <div className={styles.line}>
                            <div className={styles.infoDescription} style={{ position: "absolute", top: 40 }}>
                                <FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fal['calendar-clock']} />Locations
                            </div>
                            {editConference.rooms.length > 0 && editConference.rooms.map((room, index) =>
                                <div className={styles.infoBox} key={room._id} style={{ marginTop: 90, paddingRight: 150, paddingLeft: index === 0 ? 0 : 65 }}>
                                    <div className={styles.location}>{room.location.name}</div>
                                    {room.name}

                                    <div className={styles.scheduleTimeBox}>
                                        <div className={styles.scheduleTime} style={{ borderRight: "1px solid #5E547C" }}>
                                            <div className={styles.infoDescription} style={{ fontSize: 28 }}>
                                                <FontAwesomeIcon className={styles.iconSmall} icon={byPrefixAndName.fal['calendar-clock']} />Starting
                                            </div>
                                            <div className={styles.hour}>{formatStartHour}</div>
                                        </div>
                                        <div className={styles.scheduleTime}>
                                            <div className={styles.infoDescription} style={{ fontSize: 28 }}>
                                                <FontAwesomeIcon className={styles.iconSmall} icon={byPrefixAndName.fal['calendar-clock']} />Ending
                                            </div>
                                            <div className={styles.hour}>{formatEndHour}</div>
                                        </div>
                                    </div>
                                    <div className={styles.localTime}>Local Time</div>

                                </div>
                            )}
                            {editConference.rooms.length === 0 &&
                                <div className={styles.infoBox} style={{ marginTop: 90, paddingRight: 150, paddingLeft: 0 }}>
                                    No room

                                    <div className={styles.scheduleTimeBox}>
                                        <div className={styles.scheduleTime} style={{ borderRight: "1px solid #5E547C" }}>
                                            <div className={styles.infoDescription} style={{ fontSize: 28 }}>
                                                <FontAwesomeIcon className={styles.iconSmall} icon={byPrefixAndName.fal['calendar-clock']} />Starting
                                            </div>
                                            <div className={styles.hour}>{formatStartHour}</div>
                                        </div>
                                        <div className={styles.scheduleTime}>
                                            <div className={styles.infoDescription} style={{ fontSize: 28 }}>
                                                <FontAwesomeIcon className={styles.iconSmall} icon={byPrefixAndName.fal['calendar-clock']} />Ending
                                            </div>
                                            <div className={styles.hour}>{formatEndHour}</div>
                                        </div>
                                    </div>
                                    <div className={styles.localTime}>Local Time</div>

                                </div>
                            }

                        </div>
                        {currentUser?.role.toLowerCase() === "administrator" &&
                            <DarkButtonComponent style={{ backgroundColor: "transparent", position: "relative", width: 337, marginLeft: 67, marginTop: 40 }} onClick={() => {
                                setSeeDateTime(true)
                            }}>
                                <FontAwesomeIcon className={styles.iconPen} icon={byPrefixAndName.fas['pen']} /> Edit
                            </DarkButtonComponent>
                        }
                    </>
                }

                {editConference.type == "Presentation" &&
                    <>
                        <div className={styles.line_pres}>
                            {editConference.rooms.length > 0 && editConference.rooms.map((room, index) =>
                                <div className={styles.infoBox} key={room._id} style={{ marginTop: 90, paddingRight: 150, paddingLeft: index === 0 ? 0 : 65 }}>
                                    <div className={styles.location}>{room.location.name}</div>
                                    {room.name}
                                </div>
                            )}
                            {editConference.rooms.length === 0 &&
                                <div className={styles.infoBox} style={{ marginTop: 90, paddingRight: 150, paddingLeft: 0 }}>
                                    No room selected.
                                </div>
                            }

                        </div>
                    </>
                }
            </div>






        </DetailsCardComponent>
    );

}

export default DateTimeDetailsComponent;