import { useContext, useEffect, useRef, useState } from 'react';
import styles from './WaitingForJoiningScreen.module.css';
import { GlobalContext } from "../../contexts/globalContext";
import arrowLeft from '../../graphics/arrow-left-animation.json';
import Lottie from 'react-lottie-player';

import New_KeyboardComponent from '../keyboard/New_KeyboardComponent';
import New_InputTextComponent from '../keyboard/New_InputTextComponent';
import New_DropDownComponent from '../keyboard/New_DropDownComponent';
import CountriesJson from '../../../src/graphics/country.json';


const RegisterNewVisitor = () => {
    const { setWaitingForJoining, socketRef, conferenceLogin, currentConference, deskData, setCurrentParticipant, setCurrentConference } = useContext(GlobalContext);
    const [localStream, setLocalStream] = useState(null);
    const [isNameCompleted, setIsNameCompleted] = useState(false);

    const inputVisitorNameRef = useRef();
    const inputRefCountry = useRef();
    const inputEntitleNameRef = useRef();
    const videoRef = useRef();
    const keyboardRef = useRef();

    const handleNameChange = () => {
        //console.log('here ajungi?')
        setIsNameCompleted(inputVisitorNameRef.current.value?.trim().length > 0);
    };

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({
            video: {
                width: {
                    min: 640,
                    max: 960,
                },
                height: {
                    min: 400,
                    max: 540,
                }
            }, audio: false
        })
            .then(stream => {
                setLocalStream(stream);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            })
            .catch(error => {
                console.error("Error accessing primary media devices.", error);
            });

        return () => {
            if (localStream) {
                localStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    const RegisterNewVisitor = () => {
        var body = {
            conferenceId: currentConference._id,
            name: inputVisitorNameRef.current.value,
            entitle: inputEntitleNameRef.current.value ? inputEntitleNameRef.current.value : null,
            deskId: deskData._id,           
            country: inputRefCountry.current.currentChoice.value
        };
        
        socketRef.current.emit("CONFERENCE_ATTACH_NEW_VISITOR_TO_DESK", body, (data) => {
            if (!data.error && data.conference) {
                setWaitingForJoining(true);
                setCurrentParticipant(data.participant);
                setCurrentConference(data.conference);
            }
        });
    }

    return (
        <>
            <div className={styles.component} style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignSelf: 'center',
                position: 'absolute',
                left: '50%',
                top: '50px',
                transform: 'translate(-50%, 0%)'
            }}>
                <div style={{ marginTop: 35, display: 'flex', gap: '20px', width: '1100px', height: '618px', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <div style={{ height: 618, overflow: "hidden", borderRadius: 20 }}>
                        <video
                            ref={videoRef}
                            autoPlay
                            muted
                            style={{ width: '100%', height: '1100px', marginTop: -200 }}
                        ></video>
                    </div>
                </div>

                {/* <label className={styles.welcomeText} style={{ marginTop: 122 }}>Welcome!</label> */}
                <label className={styles.nameAndEntitleText}>Name and entitling</label>
                <New_InputTextComponent
                    ref={inputVisitorNameRef}
                    keyboardRef={keyboardRef}
                    value={conferenceLogin ? conferenceLogin.name : ""}
                    fontSize="40px"
                    placeHolder="Name"
                    styleType='POPUP'
                    width="1000px"
                    height="80px"
                    marginBottom="20px"
                    onChange={() => handleNameChange()}
                />
                <New_InputTextComponent
                    ref={inputEntitleNameRef}
                    keyboardRef={keyboardRef}
                    value={conferenceLogin ? conferenceLogin.entitle : ""}
                    fontSize="40px"
                    placeHolder="Position / Role"
                    styleType='POPUP'
                    width="1000px"
                    height="80px"
                    marginBottom="20px"
                />
                <New_DropDownComponent options={CountriesJson} ref={inputRefCountry} keyboardRef={keyboardRef} value="" placeHolder="Choose country" iconPosition="right" withDelete={true}
                    styleType='POPUP' width="1000px" height="80px" fontSize="40px" />

                <div className={styles.confModeratorTextDiv} style={{ marginTop: 55 }}>
                    <label className={styles.confModeratorText}>STARTING SOON</label>
                </div>
                <label className={styles.customTextVisitor} style={{ marginTop: 20, }}> Press the button to confirm </label>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <button
                        className={styles.joinButton}
                        disabled={!isNameCompleted}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignContent: 'center',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            opacity: isNameCompleted ? 1 : 0.5,
                            cursor: isNameCompleted ? 'pointer' : 'not-allowed',
                        }}
                        onClick={RegisterNewVisitor}>
                        <Lottie
                            loop={true}
                            play={true}
                            animationData={arrowLeft}
                            style={{ position: "relative", width: 60, height: 35 }}
                        />
                        <label>Request to join</label>
                    </button>
                </div>

            </div>
            <New_KeyboardComponent visible ref={keyboardRef} x={0} y={1660} width="2140px" height="610px" s="1" position="relative" />
        </>
    );
}

export default RegisterNewVisitor;
