import { useRef, useContext, useState, useEffect } from 'react';
import styles from './ManageParticipantsComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';
import { AddConferenceContext } from '../../../contexts/addConferenceContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions';
import DarkButtonComponent from '../../commons/DarkButtonComponent';
import LightButtonComponent from '../../commons/LightButtonComponent';

import NotAttachedParticipantsComponent from './NotAttachedParticipantsComponent';
import AttachedParticipantsListComponent from './AttachedParticipantsListComponent';
import DisplayRoomComponent from './DisplayRoomComponent';
import ConferenceAddVisitorComponent from './ConferenceAddVisitorComponent';


const ManageParticipantsComponent = () => {
    const allParticipantsRef = useRef()
    const firstRender = useRef(true)

    const [displayedParticipants, setDisplayedParticipants] = useState([])
    const [attachedParticipants, setAttachedParticipants] = useState([])
    const [allDisplayedDesks, setAllDisplayedDesks] = useState([])
    const [participantsFilter, setParticipantsFilter] = useState()

    const [displayedRoom, setDisplayedRoom] = useState()
    const [deskHeight, setDeskHeight] = useState()

    const [allRooms, setAllRooms] = useState([])
    const [isRemote, setIsRemote] = useState(false)

    const [attachedParticipantsRect, setAttachedParticipantsRect] = useState()


    const { socketRef, selectedProject, currentConference } = useContext(GlobalContext);
    const { setEditConference, setCreateConference } = useContext(ProjectDetailsContext)
    const { conferenceDetailsForParticipants, setConferenceDetailsForParticipants, setOpenParticipants, addVisitor, setUniqueModerators } = useContext(AddConferenceContext);

    const currentConferenceRef = useRef();

    useEffect(() => {
        if (conferenceDetailsForParticipants) {
            currentConferenceRef.current = conferenceDetailsForParticipants;
        } else {
            currentConferenceRef.current = currentConference;
        }

        if (!socketRef.current) {
            setUniqueModerators(currentConference.moderators.filter((moderator, index, self) =>
                index === self.findIndex((mod) => mod._id === moderator?._id)
            ))
        }

        socketRef.current.emit("GET_CONFERENCE_PARTICIPANTS_TO_ADD", selectedProject ? selectedProject._id : currentConference.project, currentConferenceRef.current._id, callback => {
            if (!callback.error) {
                allParticipantsRef.current = callback.response
                setDisplayedParticipants(callback.response)
            } 
        })

        socketRef.current.emit('GET_CONFERENCE_ROOMS_BY_ID', (currentConferenceRef.current.type == "Presentation") ? null : currentConferenceRef.current.rooms, callback => {
            setAllRooms(callback)
            if (callback && callback.length > 0) {
                setDisplayedRoom(callback[0])

                var newDskHeight = 70;
                var mxx = Math.max(callback[0].desks_east?.length, callback[0].desks_west?.length);

                if (mxx > 0) {
                    var discac = Math.trunc(900 / (mxx + 1.5));

                    if (discac > 0) {
                        newDskHeight = discac;
                    }
                }
                setDeskHeight(newDskHeight);
            } else {
                setIsRemote(true)
            }
        })

        return () => {
            if (conferenceDetailsForParticipants) {
                socketRef.current.emit("GET_CONFERENCE_BY_ID", currentConferenceRef.current._id, (callback) => {
                    setEditConference?.(callback.response)
                    setConferenceDetailsForParticipants?.(false)
                })
            }
        }

    }, [])

    useEffect(() => {
        if (displayedRoom?._id && !isRemote && firstRender.current) {
            socketRef.current.emit("CONFERENCE_GET_ATTACHED_PARTICIPANTS_FROM_ROOM", {
                conferenceId: currentConferenceRef.current._id,
                roomId: displayedRoom._id
            },
                callback => {

                    if (!callback.error) {
                        setAttachedParticipants(callback.response)
                        setAllDisplayedDesks(getAllDesks(displayedRoom))
                    } else {
                        //console.log(callback.response)
                    }

                })
            firstRender.current = false
        }

    }, [displayedRoom])

    useEffect(() => {
        if (isRemote) {
            socketRef.current.emit("CONFERENCE_GET_REMOTE_PARTICIPANTS",
                currentConferenceRef.current._id,
                callback => {
                    // //console.log(callback)
                    if (!callback.error) {
                        setAttachedParticipants(callback.response)
                    } else {
                        //console.log(callback.response)
                    }

                })
            // setAllDisplayedDesks(getAllDesks())
        }

    }, [isRemote])

    const getAllDesks = (room) => {
        const { desks_east, desks_north, desks_south, desks_west } = room;

        const allDesks = [
            ...desks_east.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_east',
            })),
            ...desks_north.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_north',
            })),
            ...desks_south.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_south',
            })),
            ...desks_west.map((desk) => ({
                _id: desk._id,
                number: parseInt(desk.number, 10),
                partOf: 'desks_west',
            })),
        ];

        allDesks.sort((a, b) => a.number - b.number);
        return allDesks;
    };

    const closePage = () => {
            setConferenceDetailsForParticipants?.()            
            setCreateConference?.()
            setOpenParticipants(false)


    }

    const changeRooms = (room) => {
        setDisplayedRoom(room)
        setIsRemote(false)
        // //console.log(room)

        socketRef.current.emit("CONFERENCE_GET_ATTACHED_PARTICIPANTS_FROM_ROOM", {
            conferenceId: currentConferenceRef.current._id,
            roomId: room._id
        },
            callback => {
                if (!callback.error) {
                    setAttachedParticipants(callback.response)
                    setAllDisplayedDesks(getAllDesks(room))
                } else {
                    //console.log(callback.response)
                }

            })

    }



    return (
        <div className={styles.component} style={{ zIndex: GetMaxZIndex() + 1 }}>
            <NotAttachedParticipantsComponent setParticipantsFilter={setParticipantsFilter} participantsFilter={participantsFilter} displayedRoom={displayedRoom} isRemote={isRemote} allDisplayedDesks={allDisplayedDesks} displayedParticipants={displayedParticipants}
                setDisplayedParticipants={setDisplayedParticipants} attachedParticipants={attachedParticipants} setAttachedParticipants={setAttachedParticipants}
                allParticipantsRef={allParticipantsRef} attachedParticipantsRect={attachedParticipantsRect} />
            <div className={styles.rightBody}>
                <div className={styles.nrParticipants}>Participants </div>
                <AttachedParticipantsListComponent setParticipantsFilter={setParticipantsFilter} setDisplayedParticipants={setDisplayedParticipants} attachedParticipants={attachedParticipants}
                    setAttachedParticipants={setAttachedParticipants} allDisplayedDesks={allDisplayedDesks} isRemote={isRemote} allParticipantsRef={allParticipantsRef}
                    setAttachedParticipantsRect={setAttachedParticipantsRect} participantsFilter={participantsFilter} displayedRoom={displayedRoom} />

                <DisplayRoomComponent displayedRoom={displayedRoom} setDisplayedRoom={setDisplayedRoom} deskHeight={deskHeight} allRooms={allRooms}
                    isRemote={isRemote} setIsRemote={setIsRemote} changeRooms={changeRooms} />


            </div>

            <div style={{
                height: 80,
                borderRadius: 8,
                font: 'normal normal 600 40px/33px Bio Sans',
                position: "absolute",
                bottom: 30,
                left: "50%",
                transform: "translate(-50%,0)",
                display: 'flex',
                flexDirection: 'row'
            }}>

                <LightButtonComponent
                    style={{
                        width: 432,
                        height: 82,
                        borderRadius: 8,
                        alignItems: 'center',
                        font: 'normal normal 600 40px/33px Bio Sans',
                        marginRight: 30,
                        background: "transparent linear-gradient(90deg, #77EDDC 0%, #DBFD95 97%, #DEFE93 100%) 0% 0% no-repeat padding-box"
                    }} onClick={closePage}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['arrow-right']} />Save changes
                </LightButtonComponent>

                <DarkButtonComponent style={{ width: 353}} onClick={closePage}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['xmark']} />Back
                </DarkButtonComponent>

            </div>


            {addVisitor && <ConferenceAddVisitorComponent setDisplayedParticipants={setDisplayedParticipants} allParticipantsRef={allParticipantsRef} 
                participantsFilter={participantsFilter} setParticipantsFilter={setParticipantsFilter}/>}
        </div>
    );

}

export default ManageParticipantsComponent;