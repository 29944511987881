import { useRef, useEffect, forwardRef, useState } from 'react';
import styles from './New_InputTextComponent.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

const New_InputTextComponent = forwardRef((props, ref) => {
    var { onFocus, onBlur, type, focused = false, iconPosition = "left", icon, withDelete = false, value, keyboardOpenAt, onChange, keyboardRef, type, placeHolder, styleType, fontSize, width, height, marginTop, marginBottom, marginLeft } = props;
    const caretPosition = useRef();
    const currentKeyboard = useRef();
    const lastValue = useRef();
    const [selected, setSelected] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const withRightIcon = useRef();
    const withLeftIcon = useRef();
    const [rightIcon, setRightIcon] = useState();

    const [keyboardClicked, setKeyboardClicked] = useState(false);

    const [currentType, setCurrentType] = useState(type);

    useEffect(() => {
        ref.current.value = value;
        lastValue.current = "";
        withRightIcon.current = false;
        withLeftIcon.current = false;

        ref.current.keyboardOpenAt = keyboardOpenAt;
        ref.current.setKeyboardClicked = () => {
            setKeyboardClicked(true);
        };

        ref.current.addToValue = (newValue) => {
            ref.current.value = ref.current.value.substring(0, caretPosition.current) + newValue + ref.current.value.substring(caretPosition.current);
            caretPosition.current = caretPosition.current + newValue.length;

            setKeyboardClicked(true);
            ref.current.selectionStart = caretPosition.current;
            ref.current.selectionEnd = caretPosition.current;
            onTextChanged();
            //ref.current.focus();
        };

        ref.current.removeOneFromValue = () => {
            if (caretPosition.current > 0) {
                ref.current.value = ref.current.value.substring(0, caretPosition.current - 1) + ref.current.value.substring(caretPosition.current);
            }
            caretPosition.current = caretPosition.current - 1;

            setKeyboardClicked(true);
            ref.current.selectionStart = caretPosition.current;
            ref.current.selectionEnd = caretPosition.current;
            onTextChanged();
            //ref.current.focus();
        };

        if (icon) {
            if (iconPosition == "right") {
                withRightIcon.current = true;
                setRightIcon(icon);
            } else {
                withLeftIcon.current = true;
                if (withDelete) {
                    withRightIcon.current = true;
                }
            }
        }

        if (type == "password") {
            withRightIcon.current = true;
            setRightIcon(byPrefixAndName.fas["eye"]);
        }

        if (focused) {
            ref.current.focus();
        }

        currentKeyboard.current = keyboardRef.current;

        setTimeout(() => {
            keyboardRef.current?.addInputElement(ref.current);
        }, 100)

        return () => {
            currentKeyboard.current?.removeInputElement(ref.current);
        }
    }, [])

    useEffect(() => {
        if (icon) {
            if (iconPosition == "right") {
                withRightIcon.current = true;
                setRightIcon(icon);
            } else {
                withLeftIcon.current = true;
                if (withDelete) {
                    withRightIcon.current = true;
                }
            }
        }
    }, [icon])

    const onTextChanged = () => {
        if (type != "password") {
            if (withRightIcon.current) {
                if (withLeftIcon.current) {
                    if (ref.current.value) {
                        setRightIcon(byPrefixAndName.fas["close"]);
                        setCanDelete(true);
                    } else {
                        setRightIcon(null);
                        setCanDelete(false);
                    }
                } else {
                    if (ref.current.value) {
                        setRightIcon(byPrefixAndName.fas["close"]);
                        setCanDelete(true);
                    } else {
                        setRightIcon(icon);
                        setCanDelete(false);
                    }
                }
            }
        }

        if (onChange) {
            if (lastValue.current != ref.current.value) {
                onChange?.(ref.current?.value);
                lastValue.current = ref.current.value;
            }
        }
    }

    const getClassName = (styleType) => {
        switch (styleType) {
            case 'SRCH':
                return styles.search_input;
            case 'EDT':
                return styles.editName;
            case 'ADD':
                return styles.inputAddBox;
            case 'POPUP':
                return styles.inputPopup;
            case 'SEARCHPOPUP':
                return styles.searchPopup;
            case 'CONFERENCE':
                return styles.conference;
            case 'FILES':
                return styles.files;
            default:
                return styles.input;
        }
    };

    const ClickDeleteButton_Handler = () => {
        if (canDelete) {
            if (ref.current.value) {
                ref.current.value = "";
                onTextChanged();
            }
            ref.current.focus();
        }
        
    }

    const ShowHidePassword_handler = () => {
        if (currentType == "text") {
            setCurrentType("password");
            setRightIcon(byPrefixAndName.fas["eye"]);
        } else {
            setCurrentType("text");
            setRightIcon(byPrefixAndName.fas["eye-slash"]);
        }
        // ref.current.selectionStart = ref.current.value.length-1;
        // ref.current.selectionEnd = ref.current.value.length-1;
        // caretPosition.current = ref.current.value.length-1;
        // //console.log("zzzzz", caretPosition.current);

    }

    return (
        <div style={{ padding: "0px", width: width, position: "relative", height: height, marginTop: marginTop, marginBottom: marginBottom, display: "flex", justifyContent: "start", flexDirection: "column", marginLeft: marginLeft}}>
            {withLeftIcon.current && <FontAwesomeIcon className={styles.left_icon} icon={icon} />}

            {withLeftIcon.current && withRightIcon.current && rightIcon && <FontAwesomeIcon className={styles.right_icon} icon={rightIcon} onClick={() => ClickDeleteButton_Handler()} />}

            {!withLeftIcon.current && withRightIcon.current && <FontAwesomeIcon className={styles.right_icon} icon={rightIcon} onClick={() => ClickDeleteButton_Handler()} />}

            {type == "password" && <FontAwesomeIcon className={styles.right_icon} icon={rightIcon} onClick={() => ShowHidePassword_handler()} />}

            <input ref={ref}
                className={getClassName(styleType)}
                placeholder={placeHolder}
                type={currentType}
                onFocus={() => {
                    if (!keyboardClicked) {
                        if (keyboardRef.current)
                            keyboardRef.current.setInputElement(ref.current);
                        setSelected(true);
                    }
                    onFocus?.();
                    setKeyboardClicked(false);
                }}
                onBlur={() => {
                    caretPosition.current = ref.current.selectionStart;
                    if (!keyboardClicked) {
                        setSelected(false);
                        keyboardRef.current.setInputElement(null);
                        onBlur?.();
                    } else {
                        // ref.current.selectionStart = caretPosition.current;
                        // ref.current.selectionEnd = caretPosition.current;
                        ref.current.focus();
                    }

                }}
                onChange={() => onTextChanged()}
                style={{
                    fontSize: fontSize ? fontSize : 40,
                    outline: 0,
                    border: selected ? "1px solid #33FFC8" : "0",
                    boxShadow: selected ? "0 0 10px 2px #33FFC8AA" : "none",
                    width: "100%",
                    boxSizing: "border-box",
                    height: height,
                    paddingLeft: withLeftIcon.current ? "70px" : "40px",
                    paddingRight: withRightIcon.current ? "60px" : "0px",
                }}
            />
        </div>
    );
})

export default New_InputTextComponent;

