import { useEffect, useRef, useContext } from 'react';
import { GlobalContext } from "../../contexts/globalContext"

const AudioStreamComponent = (props) => {
    const { currentConference, socketRef } = useContext(GlobalContext);
    const audioRef = useRef(null);

    useEffect(() => {
        audioRef.current.srcObject = props.audioStream.stream;

        const playVideosListener = function (conferenceId) {
            if (conferenceId == currentConference._id) {
                currentConference.videoPaused = false;
                audioRef.current.play();
            }
        };

        const pauseVideosListener = function (conferenceId) {
            if (conferenceId == currentConference._id) {
                currentConference.videoPaused = true;
                audioRef.current.pause();
                audioRef.current.load();
            }

        };

        socketRef.current.on('PLAY_VIDEOS', playVideosListener);
        socketRef.current.on('PAUSE_VIDEOS', pauseVideosListener);

        return () => {
            socketRef.current?.off('PLAY_VIDEOS', playVideosListener);
            socketRef.current?.off('PAUSE_VIDEOS', pauseVideosListener);
        }
    }, [])

    useEffect(() => {
        if (currentConference.videoPaused) {
            audioRef.current.pause();
            audioRef.current.load();
        } else {
            audioRef.current.play();
        }
    }, [currentConference])

    return (
        <audio ref={audioRef}></audio>
    );
}

export default AudioStreamComponent;