import { useEffect, useRef, useContext } from 'react';
import NoVideo from '../../graphics/no-video.png';
import { GlobalContext } from "../../contexts/globalContext"


const VideoStreamComponent = (props) => {
    const { currentConference, socketRef } = useContext(GlobalContext);
    const videoRef = useRef(null);
    const ref = useRef(null);

    useEffect(() => {
        //console.log("currentConference", currentConference);
        ////console.log("creare video", props.videoStream.producerId);
        videoRef.current.srcObject = props.videoStream.stream;

        const playVideosListener = function (conferenceId) {
            if (conferenceId == currentConference._id) {
                currentConference.videoPaused = false;
                videoRef.current.play();
                ref.current.style.opacity = 1;
                ref.current.style.transform = "translate(0px, 0px)";
            }
        };

        const pauseVideosListener = function (conferenceId) {
            if (conferenceId == currentConference._id) {
                currentConference.videoPaused = true;
                setTimeout(() => {
                    videoRef.current.pause();
                    videoRef.current.load();
                }, 500);

                ref.current.style.opacity = 0;
                ref.current.style.transform = "translate(900px, 0px)";
            }
        };

        socketRef.current.on('PLAY_VIDEOS', playVideosListener);
        socketRef.current.on('PAUSE_VIDEOS', pauseVideosListener);

        return () => {
            socketRef.current?.off('PLAY_VIDEOS', playVideosListener);
            socketRef.current?.off('PAUSE_VIDEOS', pauseVideosListener);
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            //console.log("currentConference.videoPaused", currentConference.videoPaused);
            if (currentConference.videoPaused) {
                videoRef.current.pause();
                videoRef.current.load();
                ref.current.style.opacity = 0;
                ref.current.style.transform = "translate(900px, 0px)";

                //console.log("stop");
            } else {
                //console.log("play", props.videoStream.participant.name);
                videoRef.current.play();
                ref.current.style.opacity = 1;
                ref.current.style.transform = "translate(0px, 0px)";

            }
        }, 300);

    }, [currentConference])

    return (
        <div ref={ref} style={{ position: "relative", marginTop: "10px", transition: "all 0.5s", transform:"translate(900px, 0px)" }}>
            <video ref={videoRef} style={{}} width="auto" height={props.height} poster={NoVideo}></video>

            <div style={{ backgroundColor: "#00000077", width: "100%", position: "absolute", left: "0px", bottom: "0px", fontSize: "30px", color: "white" }}>{props.videoStream.participant.name}</div>
        </div>
    );
}

export default VideoStreamComponent;