import { useRef, useContext, useState, useEffect } from 'react';
import styles from './AddVisitorsComponent.module.css'

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import PopupBodyComponent from '../common/PopupBodyComponent';
import PopupBaseComponent from '../common/PopupBaseComponent';
import ClosePageButtonComponent from '../../commons/ClosePageButtonComponent'
import RightBodyComponent from '../common/RightBodyComponent';
import LeftBodyComponent from '../common/LeftBodyComponent';

import membersAdd from '../../../graphics/Popups/IconMembersAdd.png'
import FooterButtonsComponent from '../common/FooterButtonsComponent';
import New_KeyboardComponent from '../../keyboard/New_KeyboardComponent';
import New_InputTextComponent from '../../keyboard/New_InputTextComponent';

import New_DropDownComponent from '../../keyboard/New_DropDownComponent';
import CountriesJson from '../../../graphics/country.json';


const AddVisitorsComponent = () => {

    const inputRefName = useRef()
    const inputRefEntitling = useRef()
    const keyboardRef = useRef()
    const inputRefCountry = useRef()

    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');

    const { language, selectedProject, socketRef, appHeight, appWidth } = useContext(GlobalContext);
    const { setAddVisitors } = useContext(ProjectDetailsContext);


    function handleEmailAdd() {
        socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", {
            projectId: selectedProject._id,
            userData: {
                "name": inputRefName.current.value,
                "email": output,
                "role": "VISITOR",
                "entitle": inputRefEntitling.current.value,
                "country": inputRefCountry.current.currentChoice.value

            }
        }, (callback) => {
            if (callback) //console.log(callback)
            setAddVisitors(false)
        })
        
    }

    const transformString = (str) => {
        return str.toLowerCase().replace(/\s+/g, '.');
    };

    useEffect(() => {
        setOutput(transformString(input))
    }, [input])


    return (
        <PopupBaseComponent>
            <New_KeyboardComponent visible ref={keyboardRef} x={(appWidth - 2140 - 60) / 2} y={appHeight * 0.6} width="2140px" height="610px" s="1" />
            <PopupBodyComponent style={{ width: 2040, height: 880, top: "12%" }}>
                <ClosePageButtonComponent setClose={() => setAddVisitors(false)} />
                <LeftBodyComponent image={membersAdd} title="Add visitor to project" description="Create and add a new visitor." />
                <RightBodyComponent>
                    <div className={styles.title}>New Visitor</div>

                    <div className={styles.subTitle}>Name</div>
                    <New_InputTextComponent ref={inputRefName} onChange={() => setInput(inputRefName.current.value)} keyboardRef={keyboardRef} value=""
                        placeHolder="Enter name here" styleType='POPUP' width="1000px" fontSize="40px" height="80px" iconPosition="right" withDelete={true} icon={byPrefixAndName.fas["pen"]} />

                    <div className={styles.username}>Username (automatically created): {output}</div>

                    <div className={styles.subTitle}>Entitling</div>
                    <New_InputTextComponent ref={inputRefEntitling} keyboardRef={keyboardRef} value=""
                        placeHolder="Enter entitling here" styleType='POPUP' width="1000px" height="80px" fontSize="40px" iconPosition="right" withDelete={true} icon={byPrefixAndName.fas["pen"]} />

                    <div className={styles.subTitle}>Country</div>
                    <New_DropDownComponent options={CountriesJson} ref={inputRefCountry} keyboardRef={keyboardRef} value="" placeHolder="Choose country" iconPosition="right" withDelete={true}
                        styleType='POPUP' width="1000px" height="80px" fontSize="40px" />

                    <FooterButtonsComponent
                        lightText={"Add"}
                        lightClick={input === "" ? () => { } : handleEmailAdd}
                        isOpaque={input === ""}
                        darkClick={setAddVisitors}
                    />
                </RightBodyComponent>
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default AddVisitorsComponent;