import { useRef, useContext, useEffect } from 'react';
import styles from './AlertComponent.module.css'
import succesAnimation from '../../graphics/mesaj succes-anim.json'
import infoAnimation from '../../graphics/anim-info.json'
import warningAnimation from '../../graphics/anim-warning.json'
import errorAnimation from '../../graphics/mesaj error-anim.json'
import { GlobalContext } from '../../contexts/globalContext';

import Lottie from 'react-lottie-player'
import { GetMaxZIndex } from '../../utils/UtilitaryFunctions';

const AlertComponent = () => {

    const { alertMessage, setAlertMessage } = useContext(GlobalContext);
    const timeoutRef = useRef();

    useEffect(() => {
        if (alertMessage) {
            timeoutRef.current = setTimeout(() => {
                setAlertMessage(null);
            }, 5000)
        } else {
            clearTimeout(timeoutRef.current);
        }
    }, [alertMessage])

    const GetBackground = () => {
        switch (alertMessage.type) {
            case 'ERROR':
                return {
                    background: "#FF1F20 0% 0% no-repeat padding-box",
                    border: '4px solid #FF4918',
                    zIndex: GetMaxZIndex() + 1
                };
            case 'SUCCESS':
                return {
                    background: "#27A745 0% 0% no-repeat padding-box",
                    border: '4px solid #6ACA69',
                    zIndex: GetMaxZIndex() + 1
                };
            case 'WARNING':
                return {
                    background: "#FFF6AD 0% 0% no-repeat padding-box",
                    border: '4px solid #FFC915',
                    zIndex: GetMaxZIndex() + 1
                };
            case 'INFO':
                return {
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: '4px solid #FFFFFF',
                    zIndex: GetMaxZIndex() + 1
                };
            default:
                return {
                    background: "gray 0% 0% no-repeat padding-box",
                    border: '4px solid gray',
                    zIndex: GetMaxZIndex() + 1
                };
        }

    }

    const GetAnimaton = () => {
        switch (alertMessage.type) {
            case 'ERROR':
                return errorAnimation;
            case 'SUCCESS':
                return succesAnimation;
            case 'WARNING':
                return warningAnimation;
            case 'INFO':
                return infoAnimation
            default:
                return errorAnimation;
        }
    }

    return (
        <>
            {
                alertMessage && <div className={styles.main} style={GetBackground()}>
                    <div style={{ fontFamily: "initial", fontWeight: "initial" }}>
                        <Lottie
                            loop={true}
                            play={true}
                            animationData={GetAnimaton()}
                            style={{ width: 80, paddingLeft: 28, position: 'relative', marginTop: -8 }}
                        />
                    </div>
                    <label className={styles.textNotif} style={{ color: (alertMessage.type === 'INFO' || alertMessage.type === 'WARNING') ? '#000000' : '#FFFFFF'}}> {alertMessage.message}
                    </label>
                    <div className={styles.close} style={{ color: (alertMessage.type === 'INFO' || alertMessage.type === 'WARNING') ? '#000000' : '#FFFFFF'}} onClick={() => setAlertMessage(null)}>
                        <i className="fa-thin fa-xmark" style={{ font: "normal normal 300 28px/35px", margin: "10px 20px 40px 0px" }}></i>
                    </div>
                </div>
            }
        </>
    );
}

export default AlertComponent;