import { useContext, useEffect } from 'react'
import styles from './PD_BaseListComponent.module.css'
import { GlobalContext } from '../../contexts/globalContext'
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext'

import conf from '../../graphics/Popups/Icon Conferinta.png'
import confAdd from '../../graphics/Popups/Icon Conferinta Add.png'
import topics from '../../graphics/Popups/Icon Folder.png'
import topicsAdd from '../../graphics/Popups/Icon Folders Add.png'
import filesAdd from '../../graphics/Popups/Icon Files Add.png'
import participantsAdd from '../../graphics/Popups/Icon Members Add.png'
import participants from '../../graphics/Popups/Icon Members.png'
import meetingAdd from '../../graphics/Popups/Icon Meeting Add.png'
import meeting from '../../graphics/Popups/Icon Meeting.png'
import confSmall from '../../graphics/Popups/Icon Conferinta Add.png'




const PD_BaseListComponent = ({ title, list, button, marginLeft = 60 }) => {

    const { currentUser, selectedProject, selectedParentForFiles } = useContext(GlobalContext);
    const { setAddFiles, setAddParticipants, setAddTopic, setCreateConference, setCreateMeeting,setMoreClicked } = useContext(ProjectDetailsContext);


    function openPopup() {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    setCreateConference(true);
                break;
            case 'Folders':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    setAddTopic(true);
                break;
            case 'Participants':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    setAddParticipants(true)
                break;
            default:
                if (selectedProject.topics?.length > 0)
                    setAddFiles(true)
                break;
        }
    }


    const noListImage = (() => {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return confAdd;
                return conf;
            case 'Folders':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return topicsAdd;
                return topics;
            case 'Participants':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return participantsAdd;
                return participants;
            default:
                return filesAdd;
        }
    })();

    const imgTitle = (() => {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Create New Conference";
                return "No Conference Created";
            case "Folders":
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Add Folder"
                return "No Folders & Subfolders";
            case 'Participants':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Add Participants";
                return "No Participants added";
            default:
                if(selectedParentForFiles)
                    return selectedParentForFiles.name;
                return "Files and Documents";
        }
    })();

    const imgDescription = (() => {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "It looks like you don’t have any conferences. Open the main menu below to create your first conference and start organising your events.";
                return "There are currently no conferences available.";
            case 'Folders':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Tap “New Topic” button from the menu below to create topics and structure your discussions.";
                return "No folders have been created. Admins can add folders to organize files and documents.";
            case 'Participants':
                if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Select members to add to the project. They will gain access to resources and receive updates.";
                return "Looks like it’s just you holding down the fort! Don’t worry, the admin can invite others to keep you company.";
            default:
                if(selectedParentForFiles)
                    return "Add files to folder";
                return "Files can only be uploaded once the admin creates folders for organization.";
        }
    })();


    return (
        <div className={styles.component}>
            {/* <img src={image} className={styles.backgroundImage} alt={"fundal"} /> */}
            <div className={styles.title}>
                {title.toUpperCase()} ({list.length > 0 ? list.length : 0}){title === "Conferences" ? " & MEETINGS (0)" : ""}
            </div>
            {button && <div className={styles.button} style={{left:marginLeft}}>{button}</div>}
            {list.length > 0 ?
                <div className={(currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") ? styles.list : styles.listNoAdmin} 
                    onScroll={() => setMoreClicked({})}>
                    {list} 
                </div>
                :
                (title !== "Conferences" ? 
                <div className={ (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") ? styles.borderImg : styles.borderNoAdmin} 
                onClick={openPopup} >
                    <img src={noListImage} className={styles.noListImage}  alt={"noListImage"} />
                    {title==="Files" && selectedParentForFiles ? 
                    <>
                    <div className={styles.imgDescription} style={{font: "normal normal normal 48px/52px 'Bio Sans'", color: "#FFFFFFB3", marginTop: -60}}>{imgDescription}</div>
                    <div className={styles.imgTitle} style={{ marginTop: -60}}>{imgTitle}</div>
                    </>
                    : <>
                    <div className={styles.imgTitle}>{imgTitle}</div>
                    <div className={styles.imgDescription}>{imgDescription}</div>
                    </>}
                </div> 
                :
                // <div className={styles.borderImg} style={{ marginTop: 295, backgroundColor: "transparent", backdropFilter: "none" }}>
                <div className={styles.divConfMeet}>
                    <div className={ (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") ? styles.borderImg : styles.borderNoAdmin} 
                     style={{height: "900px", marginTop: 0}} onClick={(e) => {
                        e.stopPropagation()
                        if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                            setCreateConference(true);
                    }}>
                        <img src={(currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") ? confSmall : conf} className={styles.noListImageSmall} alt={"noListImageSmall"} />
                        <div className={styles.imgTitle}>{(currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") ? "Add Conference" : "Conferences"}</div>
                        <div className={styles.imgDescription} style={{ marginTop: 10 }}>{currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator" ? 'Organize a conference where moderators guide discussions on specific topics.' : "There are currently no conferences available."}</div>
                    </div>
                    <div className={styles.borderImg} style={{height: "900px", marginTop: 60}} onClick={() => {
                        if (currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") {
                            console.log("meeting");
                        }
                            
                    }}>
                        <img src={(currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") ? meetingAdd : meeting} className={styles.noListImageSmall} alt={"noListImageSmall"} />
                        <div className={styles.imgTitle}> {(currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") ? "Add Meeting" : "Meetings"}</div>
                        <div className={styles.imgDescription} style={{ marginTop: 10 }}> {currentUser?.function?.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator" ? 'Schedule a collaborative meeting to discuss and develop projects with team members.' : "There are no meetings scheduled yet."}</div>
                    </div>
                <div/>
                         
                    
                 </div>
                )
            }
            <div className={styles.shadowUp}/>
            
        </div>


    );

}

export default PD_BaseListComponent;